import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, CircularProgress, Collapse, Typography } from '@mui/material';
import { joinContext } from '../context/JoinContext';
import IdVerificationStep from './IdVerificationStep';
import { isProgressComplete } from './helper/isProgressComplete';
import Notice from '../notification/Notice';
import NotificationBar from '../notification/NotificationBar';
import DetectionConfigService from '../../services/DetectionConfigService';
import OnboardService from '../../services/OnboardService';
import { DETECTION_TYPES } from '../../constants/detectionConfiguration';
import { ACTIONS, ID_ANALYSIS_STATUS } from '../../constants/joinSession';
import { NOTICE_TYPES } from '../../constants/noticeTypes';

function IdVerificationAnalysisStep(props) {
  const { flowStepIndex, setRequestMessage } = props;
  const { state, dispatch } = useContext(joinContext);
  const progress = state.onboardProgressSteps[flowStepIndex]?.progress;
  const [detectionSettings, setDetectionSettings] = useState();
  const [analysisPending, setAnalysisPending] = useState(false);
  const [attemptsReached, setAttemptsReached] = useState(false);
  const abortController = useRef(new AbortController());

  const getAttempt = useCallback(() => {
    return progress.progressStatus?.attemptCounter || 0;
  }, [progress.progressStatus]);

  const dispatchPending = useCallback(() => {
    if (progress.progressStatus?.status !== ID_ANALYSIS_STATUS.PENDING && !isProgressComplete(progress)) {
      dispatch({ type: ACTIONS.UPDATE_ONBOARD_PROGRESS, value: { ...progress, progressStatus: {...progress.progressStatus, status: ID_ANALYSIS_STATUS.PENDING } }});    
    }
  }, [dispatch, progress]);

  const dispatchMaxAttempts = useCallback(() => {
    if (progress?.progressStatus?.status !== ID_ANALYSIS_STATUS.MAX_ATTEMPTS_REACHED) {
      dispatch({ type: ACTIONS.UPDATE_ONBOARD_PROGRESS, value: {...progress, progressStatus: {...progress?.progressStatus, status: ID_ANALYSIS_STATUS.MAX_ATTEMPTS_REACHED } }});
    }
  }, [dispatch, progress]);

  useEffect(() => {
    const controller = abortController.current;

    const getMaxRetries = async () => {
      try {
        const response = await DetectionConfigService.getDetectionConfigByType(DETECTION_TYPES.compareFaces.mapper, controller.signal);
        setDetectionSettings(response === null ? {} : response);
      } catch (error) {
        if (!controller.signal.aborted) {
          console.warn('Error retrieving detection settings, applying defaults', error);
          setDetectionSettings({});
        }
      }
    };

    getMaxRetries()

    return () => { controller.abort('Id analysis step unmounting'); }
  }, []);

  useEffect(() => {
    const requestAnalysis = async () => {
      try {
        if (!progress?.id || !state.onboardAssignmentId) {
          throw new Error('Details to request analysis missing');
        }
        await OnboardService.requestAnalysisForProgress(state.onboardAssignmentId, progress.id, abortController.current.signal);
        setAnalysisPending(true);
      } catch (error) {
        if (!abortController.current.signal.aborted) {
          console.error('Unable to request analysis', error);
          setAnalysisPending(false);
          setRequestMessage && setRequestMessage({ type: NOTICE_TYPES.error, message: 'Unable to request analysis' });
        }
      }
    }

    if (progress?.progressStatus?.status === ID_ANALYSIS_STATUS.ERROR) {
      return;
    }

    if (detectionSettings && !analysisPending && !attemptsReached) {
      const maxRetries = detectionSettings?.maxRetries || DETECTION_TYPES.compareFaces.defaultRetries;
      if (getAttempt() < maxRetries) {
        requestAnalysis();
      } else {
        setAttemptsReached(true);
      }
    }
  }, [attemptsReached, analysisPending, detectionSettings, getAttempt, progress?.id, progress?.progressStatus?.status,
    setRequestMessage, state.onboardAssignmentId]);

  useEffect(() => {
    if (attemptsReached) {
      dispatchMaxAttempts();
    };
  }, [attemptsReached, dispatchMaxAttempts, getAttempt]);

  useEffect(() => {
    if (analysisPending) {
      dispatchPending();
    }
  }, [analysisPending, dispatchPending]);

  useEffect(() => {
    const idAnalysisStatus = progress.progressStatus?.status;
    if (!isEmpty(idAnalysisStatus) && idAnalysisStatus !== ID_ANALYSIS_STATUS.PENDING) {
      setAnalysisPending(false);
    }
  }, [progress.progressStatus?.status]);

  const analysisInProgressMessage = (
    <Box display="flex" gap={2} alignItems="center">
      <Box sx={{ minWidth: { xs: '30%', md: '15%' } }}><CircularProgress/></Box>
      <Box>
        <Typography variant="h3" component="h2">Analysing photos</Typography>
        <Typography variant="body1">Please wait while your photos are analysed</Typography>
      </Box>
    </Box>
  );

  const hasIdAnalysisErrored = progress?.progressStatus?.status === ID_ANALYSIS_STATUS.ERROR && !analysisPending;

  return (
    <>
      <NotificationBar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        show={analysisPending}
        message={analysisInProgressMessage}
      />
      <Collapse in={attemptsReached && !hasIdAnalysisErrored}>
        <Notice noticeType={NOTICE_TYPES.warning}>
          <Typography variant="h3" component="h2">ID photo review required</Typography>
          <Typography variant="body1">Please wait for your supervisor to review your ID photo</Typography>
        </Notice>
      </Collapse>
      <Collapse in={hasIdAnalysisErrored} >
        <Notice noticeType={NOTICE_TYPES.error} >
          <Typography variant="body1">Something has gone wrong on our end. Please try refreshing.</Typography>
          <Typography variant="body1">If the problem persists, please contact your supervisor or IT support</Typography>
        </Notice>
      </Collapse>
      <IdVerificationStep />
    </>
  )
}

IdVerificationAnalysisStep.propTypes = {
  flowStepIndex: PropTypes.number,
  setRequestMessage: PropTypes.func,
}

export default IdVerificationAnalysisStep;