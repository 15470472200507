import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Tooltip } from '@mui/material';
import { 
  DesktopAccessDisabledOutlined,
  DesktopWindowsOutlined,
  DoneRounded,
  HourglassEmptyRounded,
  MicNoneOutlined,
  MicOffOutlined,
  ScreenshotMonitor,
  VideocamOffOutlined,
  VideocamOutlined
} from '@mui/icons-material';
import { SHARED_MEDIA_STATES } from '../../constants/mediaStates';

const tooltipWrapper = (tooltipText, buttonContent) => {
  if (tooltipText) {
    return (<Tooltip title={tooltipText}>
      {buttonContent}
    </Tooltip>)
  } else {
    return buttonContent;
  }
};

const renderIconByState = (mediaType, currentState, reconnectFunc, mediaWarning) => {
  let iconColour = null;
  let iconLabel = null;
  let clickable = false;
  let tooltipText = null;

  switch (currentState) {
    case SHARED_MEDIA_STATES.CONNECTED:
      // Active state, cannot be turned off
      if (mediaWarning) {
        iconColour = 'secondary.main';
        tooltipText = mediaWarning;
        clickable = true;
      } else {
        iconColour = 'primary.main';
      }
      iconLabel = `${mediaType} sharing active`;
      break;
    case SHARED_MEDIA_STATES.CONNECTING:
      // Connecting in progress
      iconColour = 'primary.light';
      iconLabel = `${mediaType} connecting`;
      break;
    case SHARED_MEDIA_STATES.DISCONNECTED:
      // Inactive state, can be clicked to turn on
      iconColour = 'secondary.light';
      iconLabel = `click to share ${mediaType}`;
      clickable = true;
      break;
    default:
      // Greyed out disabled icon means non-active button (so no button method)
      iconLabel = `${mediaType} sharing disabled`;
  }

  let displayIcons;
  if(currentState !== SHARED_MEDIA_STATES.CONNECTED) {
    displayIcons = <>
      {mediaType === 'webcam' && <VideocamOffOutlined sx={{ color: iconColour }} />}
      {mediaType === 'audio' && <MicOffOutlined sx={{ color: iconColour }} />}
      {mediaType === 'desktop' && <DesktopAccessDisabledOutlined sx={{ color: iconColour }} />}
    </>
  } else {
    displayIcons = <>
      {mediaType === 'webcam' && <VideocamOutlined sx={{ color: iconColour }} />}
      {mediaType === 'audio' && <MicNoneOutlined sx={{ color: iconColour }} />}
      {mediaType === 'desktop' && !mediaWarning && <DesktopWindowsOutlined sx={{ color: iconColour }} />}
      {mediaType === 'desktop' && !!mediaWarning && <ScreenshotMonitor sx={{ color: iconColour }} />}
    </>
  }
  return tooltipWrapper(tooltipText, 
    <IconButton
      onClick={reconnectFunc}
      aria-label={iconLabel}
      disabled={!clickable}
      size="large">
      {displayIcons}
    </IconButton>
  );
}

const renderButtonByState = (mediaType, currentState, reconnectFunc) => {
  let clickable = false;
  let icon = null;
  let buttonColour = 'primary';
  let buttonText = null;

  switch (currentState) {
    case SHARED_MEDIA_STATES.CONNECTED:
      // Active state, cannot be turned off
      buttonText = `Sharing ${mediaType} active`;
      icon = <DoneRounded />
      break;
    case SHARED_MEDIA_STATES.CONNECTING:
      // Connecting in progress
      buttonText = `Trying ${mediaType}`;
      icon = <HourglassEmptyRounded />
      buttonColour = 'primary.light';
      break;
    default:
      buttonText = `Share ${mediaType} again`;
      switch (mediaType) {
        case 'webcam':
          icon = <VideocamOutlined />;
          break;
        case 'audio':
          icon = <MicNoneOutlined />;
          break;
        default:
          icon = <DesktopWindowsOutlined />;
      }
      if (currentState === 'off') {
        // Inactive state, can be clicked to turn on
        clickable = true;
      }
  }

  return (
    <Button
      variant="outlined"
      startIcon={icon}
      disabled={!clickable}
      onClick={reconnectFunc}
      sx={{ color: buttonColour }}
    >{buttonText}</Button>
  );
}

function ReconnectMediaButton(props) {
  const { reconnectFunc, mediaType, mediaState, mediaWarning, displayAsIcon = false } = props;
  if (displayAsIcon) {
    return renderIconByState(mediaType, mediaState, reconnectFunc, mediaWarning);
  } else {
    return renderButtonByState(mediaType, mediaState, reconnectFunc);
  }
}

ReconnectMediaButton.propTypes = {
  reconnectFunc: PropTypes.func.isRequired,
  mediaType: PropTypes.string.isRequired,
  mediaState: PropTypes.string.isRequired,
  mediaWarning: PropTypes.string,
  displayAsIcon: PropTypes.bool,
};

export default ReconnectMediaButton;
