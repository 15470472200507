import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { omit } from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { AuthConsumer, authContext } from '../authContext';
import Can, { CanThey } from '../components/Can';
import AddButton from '../components/form/AddButton';
import ScrollButtons from '../components/form/ScrollButtons';
import SearchFormByOptions from '../components/search/SearchFormByOptions';
import ShiftForm from '../components/form/ShiftForm';
import ShiftTable from '../components/table/ShiftTable';
import ShiftUploadForm from '../components/form/ShiftUploadForm';
import UploadButton from '../components/form/UploadButton';
import Routing from '../utils/Routing';
import { SEARCH_ROSTER_LABELS } from '../constants/search';
import { SESSION_TIMES } from '../constants/examSessions';
import { SHIFT_SEARCH_OPTIONS, SHIFT_SEARCH_FIELDS, SHIFT_LOCATIONS } from '../constants/shift';
import { EVIG_FILE_TYPE } from '../constants/upload';
import Section from '../components/Section';

const styles = {
  buttonContainer: {
    columnGap: 1,
    marginLeft: { xs: 0, sm: 1 },
    marginTop: { xs: 1, sm: 0 }
  },
};

function ManageRoster(_props) {
  const location = useLocation();
  const uriParams = useParams();
  const { capabilityContextAccess, isPending } = useContext(authContext);
  const [canEdit, setCanEdit] = useState(false);
  const [canManageAssignments, setCanManageAssignments] = useState(false);
  const [canViewExam, setCanViewExam] = useState(false);
  const [hasShiftUpdated, setHasShiftUpdated] = useState(false);

  uriParams.searchSingleSelect = uriParams?.searchSingleSelect || SHIFT_LOCATIONS.ALL.value;
  uriParams.session = uriParams?.session || SESSION_TIMES.am.value;

  useEffect(() => {
    const setUserAccess = () => {
      setCanEdit(CanThey(capabilityContextAccess, false, 'MANAGE_SHIFT'));
      setCanManageAssignments(CanThey(capabilityContextAccess, false, 'MANAGE_ASSIGNMENTS'));
      setCanViewExam(CanThey(capabilityContextAccess, false, 'VIEW_EXAM'));
    };
    !isPending && setUserAccess();
  }, [capabilityContextAccess, isPending, setCanEdit, setCanManageAssignments, setCanViewExam]);

  const canNavigate = (params) => {
    if (!params) { return false; }
    const { date, session } = params;
    return Boolean(date && session);
  };

  const getRoute = (params) => {
    const { date, session, searchSingleSelect } = params;
    const encodedLocation = encodeURIComponent(searchSingleSelect);
    return `${Routing.MANAGE_ROSTER}/date/${date}/session/${session}/location/${encodedLocation}`;
  };

  const getSectionTitle = (session, shiftLocation) => {
    return `${SESSION_TIMES[session].displayName} ${shiftLocation.display} shifts`;
  }

  const getShiftTableLocations = () => {
    if (uriParams.searchSingleSelect === SHIFT_LOCATIONS.ALL.value) {
      return Object.values(omit(SHIFT_LOCATIONS, 'ALL'));
    }

    return [Object.values(SHIFT_LOCATIONS).find(sl => sl.value === uriParams.searchSingleSelect)];
  }

  const shiftTableLocations = getShiftTableLocations();

  const rosterPage = (
    <ScrollButtons>
      <Typography variant="h2">Staff roster</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" py={2} flexDirection={{ xs: 'column', sm: 'row' }}>
        <SearchFormByOptions
          key={location.key}
          canNavigate={canNavigate}
          fieldsToShow={SHIFT_SEARCH_OPTIONS}
          fieldsetSx={{ minWidth: { xs: "75%", lg: "min(820px, 75%)" } }}
          getRoute={getRoute}
          labels={SEARCH_ROSTER_LABELS}
          searchFields={SHIFT_SEARCH_FIELDS}
          uriParams={uriParams}
        />
        {canEdit &&
          <Box display="flex" sx={styles.buttonContainer}>
            <AddButton
              id="add-shift-button"
              heading="Add session"
              title="Add session"
              variant="contained"
              content={<ShiftForm setHasShiftUpdated={setHasShiftUpdated} />}
            />
            {/* the ShiftUploadForm is cloned and the setParams is replaced with a real func,
                but since it's required, need to send an empty func */}
            <UploadButton
              id="upload-sessions-button"
              title="Upload sessions"
              evigFileType={EVIG_FILE_TYPE.SHIFTS}
              additionalFieldsForm={<ShiftUploadForm setParams={() => { /* do nothing */ }} />}
              setHasUpdated={setHasShiftUpdated}
            />
          </Box>
        }
      </Box>
      {(canNavigate(uriParams) && shiftTableLocations.length === 1) &&
        <Section>
          <Typography variant="h3">{getSectionTitle(uriParams.session, shiftTableLocations[0])}</Typography>
          <ShiftTable
            key={location.key}
            canEdit={canEdit}
            canManageAssignments={canManageAssignments}
            canViewExam={canViewExam}
            date={uriParams.date}
            hasShiftUpdated={hasShiftUpdated}
            location={uriParams.searchSingleSelect}
            session={uriParams.session}
            setHasShiftUpdated={setHasShiftUpdated}
          />
        </Section>
      }
      {(canNavigate(uriParams) && shiftTableLocations.length > 1) &&
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
          {(shiftTableLocations).map(shiftLocation => {
            const title = getSectionTitle(uriParams.session, shiftLocation);
            return (
              <Box key={'group-' + uriParams.session + '-' + shiftLocation.value}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ flexDirection: 'row' }} >
                    <Typography variant="h3">{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pt: 0 }} >
                    <ShiftTable
                      key={shiftLocation.value + '-' + location.key}
                      canEdit={canEdit}
                      canManageAssignments={canManageAssignments}
                      canViewExam={canViewExam}
                      date={uriParams.date}
                      hasShiftUpdated={hasShiftUpdated}
                      location={shiftLocation.value}
                      session={uriParams.session}
                      setHasShiftUpdated={setHasShiftUpdated}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })}
        </Box>
      }
    </ScrollButtons>
  )

  return (
    <AuthConsumer>
      {({ capabilityContextAccess }) => (
        <Can
          capabilityContextAccess={capabilityContextAccess}
          contextRequired={false}
          perform={["MANAGE_SHIFT", "VIEW_SHIFT"]}
          yes={() => (rosterPage)}
        />
      )}
    </AuthConsumer>
  );
}

export default ManageRoster;
