import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Box, Typography } from '@mui/material';
import { AuthConsumer } from '../authContext';
import Can from '../components/Can';
import ContextTree from '../components/tree/ContextTree';
import ScrollButtons from '../components/form/ScrollButtons';

function ManageContexts(_props) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const contextPage = (
    <ScrollButtons horizontalScroll={true}>
      <Box maxWidth={1200}>
        <Typography variant="h2">Contexts</Typography>
        <ContextTree key={location?.key} navigate={navigate} topId={params?.topId}/>
      </Box>
    </ScrollButtons>
  )
  return (
    <AuthConsumer>
      {({capabilityContextAccess}) => (
        <Can
          capabilityContextAccess={capabilityContextAccess}
          contextRequired={false}
          perform="MANAGE_CONTEXT"
          yes={() => (contextPage)}
        />
      )}
    </AuthConsumer>
  );
}

export default ManageContexts;
