import { SEARCH_OPTIONS } from './search';

export const NOTICES_SEARCH_OPTIONS = [SEARCH_OPTIONS.date, SEARCH_OPTIONS.session, SEARCH_OPTIONS.searchNumber];

export const NOTICES_OFFSET_TIME_SEARCH_FIELD = [
  {
    component: SEARCH_OPTIONS.date,
    fullWidth: true,
    gridSize: 4,
  }, {
    component: SEARCH_OPTIONS.session,
    gridSize: 5,
  }, {
    component: SEARCH_OPTIONS.searchNumber,
    name: SEARCH_OPTIONS.searchNumber,
    label: 'Offset',
    type: 'number',
    endAdornment: <span>min</span>,
    fullWidth: false,
    helperText: 'Notices older than x minutes',
    gridSize: 3,
  }
];

export const NOTICES_REQUEST_PARAMS = {
  extraData: 'notices,room-scan-status,users',
  hasUnresolvedNoticeType: 'CHAT,RAISEHAND',
  excludeStudentOfflineAndSubmitted: 'true',
};

export const EXAM_SESSION_NOTICE_FIELDS = {
  id: {},
  RAISEHAND: {
    display: 'Hand raised',
  },
  CHAT: {
    display: 'Chat',
  },
  studentName: {
    display: 'Student name',
  },
  studentId: {
    display: 'Student ID',
  },
  supervisorName: {
    display: 'Supervisor name',
  },
  supervisorUsername: {
    display: 'Supervisor username',
  },
  supervisorId: {
    display: 'Supervisor ID',
  },
  onboarderName: {
    display: 'Onboarder name',
  },
  onboarderUsername: {
    display: 'Onboarder username',
  },
  onboarderId: {
    display: 'Onboarder ID',
  },
  examTime: {
    display: 'Time',
  },
  unit: {
    display: 'Exam unit',
  },
  examState: {
    display: 'Status',
  },
  onboardingType: {
    display: 'Onboarding type',
  },
  roomScanStatus: {
    display: 'Room scan status',
  },
};
