import React from 'react';

const TechIssue = ({ height = '16px', width = '16px', ...props }) => (
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={height}
    width={width}
    {...props}
  >
    <title>wrench</title>
    <g id="TechIssue" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TechIssue-Artboard" transform="translate(-632.000000, -217.000000)" fillRule="nonzero">
        <g id="wrench" transform="translate(632.000000, 217.000000)">
          <rect id="TechIssue-Rectangle" fill="#0F70D7" opacity="0" x="0" y="0" width="16" height="16"></rect>
          <path d="M13.696875,3.7421875 C13.6890625,3.728125 13.678125,3.7140625 13.665625,3.703125 C13.5875,3.625 13.4609375,3.625 13.3828125,3.703125 L10.690625,6.3953125 L9.6296875,5.334375 L12.3234375,2.640625 C12.3359375,2.628125 12.3453125,2.615625 12.3546875,2.6015625 C12.4109375,2.50625 12.3796875,2.384375 12.284375,2.328125 C10.75,1.421875 8.740625,1.6296875 7.4203125,2.9484375 C6.3734375,3.9953125 6.0265625,5.4796875 6.38125,6.8140625 L1.803125,11.3921875 C1.75625,11.4390625 1.759375,11.515625 1.8078125,11.5640625 L4.459375,14.215625 C4.5078125,14.2640625 4.5859375,14.2671875 4.63125,14.2203125 L9.2078125,9.64375 C10.54375,10 12.028125,9.6546875 13.0765625,8.60625 C14.3953125,7.2859375 14.603125,5.2765625 13.696875,3.7421875 Z M12.28125,7.809375 C11.3734375,8.7171875 10.0109375,8.8921875 8.928125,8.334375 L8.790625,8.471875 L8.7890625,8.4703125 L4.5078125,12.753125 L3.2703125,11.515625 L6.865625,7.9203125 C6.865625,7.9203125 6.865625,7.921875 6.8671875,7.921875 L7.6921875,7.096875 C7.134375,6.0140625 7.309375,4.6515625 8.2171875,3.74375 C8.8296875,3.13125 9.65625,2.8484375 10.4671875,2.9078125 L8.390625,4.9828125 C8.1953125,5.178125 8.1953125,5.4953125 8.390625,5.690625 L10.3359375,7.6359375 C10.53125,7.83125 10.8484375,7.83125 11.04375,7.6359375 L13.11875,5.5609375 C13.1765625,6.3703125 12.89375,7.1984375 12.28125,7.809375 Z" id="TechIssue-Shape" fill="#3C4043"></path>
        </g>
      </g>
    </g>
  </svg>

);

export default TechIssue;
