import React from 'react';

import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router';
import { LoginCallback } from '@okta/okta-react';
import { split } from 'lodash';

import Routing from './utils/Routing';
import Pages from './pages';
import AppTheme from './components/AppTheme';
import PageWrapper from './components/container/PageWrapper';
import SecureRoute from './components/auth/SecureRoute';
import { AuthConsumer } from './authContext';
import { LOGIN_NONE } from './constants/login';
import { FEATURE_TOGGLES } from './constants/featureToggles';
import { shouldRedirectFromError, shouldRedirectToError } from './utils/shouldRedirect';

// Defaulting to OIDC login unless none is specifically set
const ConfigurableSecureRoute =
  process.env.REACT_APP_AUTH_METHOD === LOGIN_NONE ? Outlet : SecureRoute;

function App(_props) {
  const location = useLocation();
  const currentPath = split(location.pathname, '/', 2)?.[1];
  const isLimitedRenderRoute = Routing.LIMITED_RENDER_ROUTES.includes(currentPath);
  const isRerenderRoute = Routing.RERENDER_ROUTES.includes(currentPath);
  const routeFound = isLimitedRenderRoute || isRerenderRoute;

  return (
    <AppTheme>
      <PageWrapper route={location?.pathname}>
        <>
          {!routeFound &&
            <Routes><Route path="*" element={<Pages.PageNotFound/>}/></Routes>
          }
          {isLimitedRenderRoute &&
            <Routes>
              <Route path={Routing.path()} element={<ConfigurableSecureRoute/>}>
                {/* avoid re-renders of supervisor in session pages on auth updates. Changes will be managed down the tree */}
                <Route
                  exact
                  path={Routing.path(Routing.MONITOR_SESSIONS)}
                  element={<Pages.MonitorSessions />}
                />
              </Route>
            </Routes>
          }
          {isRerenderRoute &&
            <>
              <AuthConsumer>
                {({features, authorised}) => (
                  <Routes>
                    <Route path={Routing.path()} element={<ConfigurableSecureRoute />} >
                      <Route path={Routing.path()} element={<Pages.Home />} />
                      <Route
                        path={Routing.path(Routing.JOIN_SESSION, ':slotId')}
                        element={features?.[FEATURE_TOGGLES.AUTO_ONBOARD]
                          ? <Pages.JoinOnboardSession />
                          : <Pages.JoinSession authorised={authorised} />} />
                      <Route
                        path={Routing.path(Routing.MANAGE_ALLOCATION_ERRORS)}
                        element={<Pages.ManageAllocationErrors />}>
                        <Route
                          path={Routing.path(Routing.MANAGE_ALLOCATION_ERRORS, "date/:date?")}
                          element={<Pages.ManageAllocationErrors />} />
                      </Route>
                      <Route
                        path={Routing.path(Routing.MANAGE_ALLOCATION_POOLS)}
                        element={<Pages.ManageAllocationPools />} />
                      <Route
                        path={Routing.path(Routing.MANAGE_CONTEXTS)}
                        element={<Pages.ManageContexts />}>
                        <Route
                          path={Routing.path(Routing.MANAGE_CONTEXTS, "topBranch/:topId")}
                          element={<Pages.ManageContexts />} />
                      </Route>
                      <Route
                        path={Routing.path(Routing.MANAGE_EXAM_SESSIONS)}
                        element={<Pages.ManageExamSessions />}>
                        <Route
                          path={Routing.path(Routing.MANAGE_EXAM_SESSIONS, ':searchBy?')}
                          element={<Pages.ManageExamSessions />} />
                      </Route>
                      <Route
                        path={Routing.path(Routing.MANAGE_RECORDING, ":searchBy?")}
                        element={<Pages.ListRecording />} />
                      <Route
                        path={Routing.path(Routing.MANAGE_ROLES)}
                        element={<Pages.ManageRoles />} />
                      <Route
                        path={Routing.path(Routing.MANAGE_ROSTER)}
                        element={<Pages.ManageRoster />}>
                        <Route
                          path={Routing.path(Routing.MANAGE_ROSTER, "date/:date?/session/:session?/location/:searchSingleSelect")}
                          element={<Pages.ManageRoster />} />
                      </Route>
                      <Route
                        path={Routing.path(Routing.MANAGE_SUPERVISOR_SESSIONS, 'searchBy?')}
                        element={<Pages.ManageSupervisorSessions />} />
                      <Route
                        path={Routing.path(Routing.MANAGE_USERS)}
                        element={<Pages.ManageUsers />}>
                        <Route
                          path={Routing.path(Routing.MANAGE_USERS, "searchTerm/:searchTerm?")}
                          element={<Pages.ManageUsers />} />
                        <Route
                          path={Routing.path(Routing.MANAGE_USERS, "onlyAAA/:onlyAAA?")}
                          element={<Pages.ManageUsers />} />
                        <Route
                          path={Routing.path(Routing.MANAGE_USERS, "searchTerm/:searchTerm?/onlyAAA/:onlyAAA?")}
                          element={<Pages.ManageUsers />} />
                      </Route>
                      <Route
                        path={Routing.path(Routing.REVIEW_SESSION, ":slotId?")}
                        element={<Pages.ReviewSession />} />
                      <Route
                        path={Routing.path(Routing.SUPERVISE_SESSION, ':slotId')}
                        element={<Pages.SuperviseSession authorised={authorised} />}
                      />
                      <Route
                        path={Routing.path(Routing.UNRESOLVED_NOTICE_REPORT)}
                        element={<Pages.UnresolvedNoticeReport />}>
                        <Route
                          path={Routing.path(Routing.UNRESOLVED_NOTICE_REPORT, "date/:date?/session/:session?/offset/:searchNumber?")}
                          element={<Pages.UnresolvedNoticeReport />} />
                      </Route>
                    </Route>
                    <Route
                      path={Routing.path(Routing.ERROR)}
                      element={<Pages.Error />}/>
                    <Route
                      path={process.env.REACT_APP_AUTHCONFIG_REDIRECT_URI_PARTIAL}
                      element={<LoginCallback />}
                    />

                  </Routes>
                )}
              </AuthConsumer>
              <AuthConsumer>
                {({authorised, isPending, user}) => (
                  <>
                    {shouldRedirectFromError({ authorised, isPending, user, path: location.pathname }) &&
                      <Navigate to="/" />
                    }
                    {shouldRedirectToError({ authorised, isPending, user, path: location.pathname })  &&
                      <Navigate to="/error" />
                    }
                  </>
                )}
              </AuthConsumer>
            </>
          }
        </>
      </PageWrapper>
    </AppTheme>
  );
}

export default App;
