import { format as dateFnsFormat } from 'date-fns';
import { SEARCH_FILTERS as SESSION_SEARCH_FILTERS } from '../constants/examSessions';

//TODO replace usages with mapUrlParams
const mapParams = (params = {}) => {
  return Object.keys(params)
    .map((key) => (params[key] !== undefined) ? `${key}=${params[key]}` : `${key}=`)
    .join("&");
}

export const mapUrlParams = (params = {}, filterUrlParams) => {
  let searchParams = filterUrlParams ? filterUrlParams : new URLSearchParams();
  Object.keys(params).forEach((key) => {
    params[key] !== undefined && searchParams.append(key, params[key]);
  })
  return searchParams;
}

export const getFullDayDatesObj = (date) => {
  if (!date) {
    return undefined;
  }
  return {
    startDateTime: dateFnsFormat(new Date(date), "yyyy-MM-dd'T'00:00:00X"),
    endDateTime: dateFnsFormat(new Date(date), "yyyy-MM-dd'T'23:59:59X"),
  };
}

export const getSessionDatesObj = (dates) => {
  if (!dates) {
    return undefined
  }
  let dateObj = {};
  Object.keys(dates).map((key) =>
    dateObj[key] = dateFnsFormat(new Date(dates[key]), "yyyy-MM-dd'T'HH:mm:ssX")
  );
  return dateObj;
}

export const mapSessionFilters = (filters = {}) => {
  let searchFilters = new URLSearchParams();
  Object.keys(filters).forEach(filter => {
    const filterMapping = SESSION_SEARCH_FILTERS[filter];
    if (filterMapping.mapper) {
      if (filterMapping.mappingType === 'multi') {
        const filterValueList = filterMapping.mappingValue || filters[filter];
        filterValueList.forEach(filterValue => {
          searchFilters.append(filterMapping.mapper, filterValue);
        });
      } else {
        searchFilters.append(filterMapping.mapper, filterMapping.mappingValue || filters[filter]);
      }
    }
  });
  return searchFilters;
}

export default mapParams;
