import React from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import { format as dateFnsFormat, isValid as dateFnsIsValid, parseISO } from 'date-fns';
import { isEmpty, omit, size } from 'lodash';
import Notice from '../notification/Notice';
import Section from '../Section';
import UserLookup from '../search/UserLookup';
import AllocationPoolService from '../../services/AllocationPoolService';
import ShiftService from '../../services/ShiftService';
import { fieldsetContainerStyle, legendStyle } from '../../config/theme';
import { SHIFT_REQUEST_FIELDS as FIELDS, SHIFT_LOCATIONS } from '../../constants/shift';
import { getSessionStartEndTimesAtLocation } from '../../utils/getSessionTime';
import { validateRequired } from '../../utils/validateFields';

class ShiftForm extends React.Component {
  controller = new AbortController();

  constructor(props) {
    super(props);
    const format = "yyyy-MM-dd";
    const baseShift = {
      supervisorId: undefined,
      supervisor: undefined,  // for rendering
      shiftDate: dateFnsFormat(new Date(), format),
      location: Object.values(SHIFT_LOCATIONS).find(sl => !!sl.default).value,
      session: 'am',
      notes: '',
      allocationPoolId: '',
      status: 'NOT_STARTED',
    };
    this.state = {
      shift: baseShift,
      allocationPools: undefined,
      isRequestSuccessful: false,
      isRequestPending: false,
      hasRequestErrored: false,
      errorMessage: '',
    }
  }

  componentDidMount() {
    this.getPools();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  getPools = async () => {
    try {
      const pools = await AllocationPoolService.getAllocationPools(this.controller.signal);
      const formattedPools = this.formatPools(pools)
      this.setState({
        allocationPools: formattedPools,
        hasRequestErrored: false,
        errorMessage: '',
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        this.setState({
          hasRequestErrored: true,
          errorMessage: `Unable to retrieve allocation pools. Error: ${error.message}`,
        });
      }
    }
  };

  formatPools = (pools) => {
    if (isEmpty(pools)) {
      throw new Error('There are no allocation pools available.');
    }
    return pools
      .filter(p => !p.disabled)
      .map((pool) => { return { key: pool.id, value: pool.name } });
  };

  formatDateTime = (dateTime, format) => {
    if (!dateTime) {
      return undefined;
    }
    if (!format) {
      format = "yyyy-MM-dd'T'HH:mm:ssX";
    }

    const parsedDateTime = parseISO(dateTime);
    if (dateFnsIsValid(parsedDateTime)) {

      return dateFnsFormat(parsedDateTime, format);
    }
  };

  setField = (event, type) => {
    const fieldValue = (type === 'date')
      ? this.formatDateTime(event.target.value, "yyyy-MM-dd")
      : event.target.value;
    this.setState({ shift: { ...this.state.shift, [event.target.id]: fieldValue } })
  };

  setSelectField = (event) => {
    this.setState({ shift: { ...this.state.shift, [event.target.name]: event.target.value } })
  };

  setUser = (_fieldName, user) => {
    this.setState({ shift: { ...this.state.shift, supervisor: user?.[0], supervisorId: user?.[0]?.value } });
  };

  validateFields = (shift) => {
    if (isEmpty(shift)) {
      throw new Error("Please provide the required details");
    }
    validateRequired(FIELDS, shift);
    const shiftDate = shift.shiftDate && parseISO(shift.shiftDate);
    if (!dateFnsIsValid(shiftDate)) {
      throw new Error("The session date is invalid");
    }
    return true;
  };

  finaliseShift = (shift) => {
    let finalisedShift = omit(shift, 'supervisor');
    const shiftTimes = getSessionStartEndTimesAtLocation(finalisedShift.shiftDate, finalisedShift.session, finalisedShift.location);

    finalisedShift['startTime'] = this.formatDateTime(shiftTimes.startDateTime.toISOString());
    finalisedShift['endTime'] = this.formatDateTime(shiftTimes.endDateTime.toISOString());
    return finalisedShift;
  }

  saveShift = async () => {
    const { shift } = this.state;
    const { setHasShiftUpdated } = this.props;
    try {
      this.validateFields(shift);
      const shiftToSave = this.finaliseShift(shift);
      setHasShiftUpdated(false);
      await ShiftService.saveShift(shiftToSave, this.controller.signal);
      this.setState({
        hasRequestErrored: false,
        isRequestSuccessful: true,
        errorMessage: '',
      });
      setHasShiftUpdated(true);
    } catch (error) {
      if (error.name !== 'AbortError') {
        this.setState({
          hasRequestErrored: true,
          isRequestSuccessful: false,
          errorMessage: error.message,
        });
      }
    }
  };

  getSelectItems = (field) => {
    if (!isEmpty(field.allowedValues)) {
      return field.allowedValues;
    }
    if (!isEmpty(field.valuesFromState)) {
      return this.state[field.valuesFromState];
    }
    return [];
  }

  render() {
    const { shift, isRequestSuccessful, hasRequestErrored, errorMessage } = this.state;

    return (
      <Section>
        <FormControl component="fieldset" sx={fieldsetContainerStyle} aria-label='Add supervision session'>
          <FormLabel component="legend" sx={legendStyle}>Add supervision session</FormLabel>
          {isRequestSuccessful &&
            <Notice noticeType="success">The session was created successfully.</Notice>
          }
          {hasRequestErrored &&
            <Notice noticeType="error">{errorMessage}</Notice>
          }
          {
            Object.keys(FIELDS).map((field) => {
              if (FIELDS[field].type === 'userSelect') {
                return (
                  <UserLookup
                    key={field}
                    dataProps={{ 'data-name': 'shift-supervisor-user-input-container' }}
                    errored={hasRequestErrored && (FIELDS[field].required && isEmpty(shift[field]))}
                    fieldAriaLabel="Select supervisor (Enter three or more characters to search for a person) (required)"
                    fieldLabel="Supervisor name, username or ID"
                    fieldName={field}
                    inputDataProps={{ 'data-name': 'shift-supervisor-user-input-box' }}
                    multiple={false}
                    required={FIELDS[field].required}
                    setUserValue={this.setUser}
                    sx={{ my: 1 }}
                    userValue={[shift.supervisor]}
                  />
                );
              } else if (FIELDS[field].type === 'select') {
                const menuItems = this.getSelectItems(FIELDS[field]);
                return (
                  <React.Fragment key={field}>
                    {!isEmpty(menuItems) &&
                      <TextField
                        id={field}
                        name={field}
                        select
                        sx={{ mx: 0, my: 1 }}
                        label={FIELDS[field].display}
                        value={shift[field]}
                        onChange={(event) => this.setSelectField(event, field)}
                        required={FIELDS[field].required}
                        error={hasRequestErrored && ((FIELDS[field].required && isEmpty(shift[field])) || (size(shift[field]) > FIELDS[field].maxLength))}
                        size='medium'
                        variant='outlined'
                        fullWidth
                        disabled={FIELDS[field].disabled}
                      >
                        {menuItems.map((item) => {
                          return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                        })}
                      </TextField>
                    }
                  </React.Fragment>
                )
              } else if (FIELDS[field].type === 'date') {
                return (
                  <LocalizationProvider key={field} dateAdapter={AdapterDateFns}>
                    <TextField
                      sx={{ mx: 0, my: 1 }}
                      id={field}
                      label={FIELDS[field].display}
                      type="date"
                      defaultValue={shift[field]}
                      onChange={(event) => this.setField(event, FIELDS[field].type)}
                      required={FIELDS[field].required}
                      error={hasRequestErrored && ((FIELDS[field].required && isEmpty(shift[field])) || (size(shift[field]) > FIELDS[field].maxLength))}
                      slotProps={{
                        inputProps: { shrink: true },
                      }}
                      size='medium'
                      variant='outlined'
                      fullWidth
                    />
                  </LocalizationProvider>
                )
              } else if (FIELDS[field].type === 'textfield') {
                return (
                  <TextField
                    key={field}
                    sx={{ mx: 0, my: 1 }}
                    id={field}
                    label={FIELDS[field].display}
                    value={shift[field]}
                    onChange={(event) => this.setField(event, FIELDS[field].type)}
                    required={FIELDS[field].required}
                    error={hasRequestErrored && FIELDS[field].required && isEmpty(shift[field])}
                    size="medium"
                    variant='outlined'
                    fullWidth />
                )
              } else {
                return (<></>)
              }
            })
          }
          <Box display='flex' justifyContent='flex-end'>
            <Button
              key='save'
              color='primary'
              variant='contained'
              onClick={this.saveShift}
            >Save</Button>
          </Box>
        </FormControl>
      </Section>
    );
  }
}

ShiftForm.propTypes = {
  setHasShiftUpdated: PropTypes.func.isRequired
};

export default ShiftForm;
