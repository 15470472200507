import { assign, has } from 'lodash';
import kurentoUtils from 'kurento-utils';

// Import webRTC shim methods for browser support
// eslint-disable-next-line
import adapter from 'webrtc-adapter';

import streamSettings from '../config/streamSettings';
import { DESKTOP_STREAM_ID, SFU_COMPONENT_TYPE } from '../constants/webRtc';
import { SCREEN_SHARE_TYPES, FULL_SCREEN_BROWSER_MAPPERS } from '../constants/mediaStates';

const errorMessages = {
  browserNotSupported: "Browser is not supported. Try again using a different browser or device.",
}

export default class VideoStreamService {

  /**
   * Constructor initialises the common class properties.
   */
  constructor() {

    // List of managed streams
    this.streams = {};
    this.connections = {
      video: {
        ws: null,
        wsQueue: [],
        open: false,
        changeRequested: undefined,
        wsOpenResolver: [],
        wsCloseResolver: [],
      },
      screenshare: {
        ws: null,
        wsQueue: [],
        open: false,
        changeRequested: undefined,
        wsOpenResolver: [],
        wsCloseResolver: [],
      },
    };

    // Check for generating ice servers only once
    this.canGenerateIceCandidates = false;

    // Store the connection information for the BBB host
    this.connectionProps = undefined;

    // Window online/offline handler functions
    this.handlerFuncs = {
      onWindowOnline: {},
      onWindowOffline: {}, 
    };
  }

  defaultStreamProperties() {
    return {
      isLocal: true,
      constraints: {},
      bitrate: streamSettings.defaultWebcamQualityBitrate,
      renderElementRef: null,
      handlers: {
        onMediaStarted: [],
        onMediaStopped: [],
        onMediaAttached: [],
        onError: [],
        onScreenShareStarted: [],
      },
      peer: undefined,
      outboundIceQueue: [],
      mediaFlowing: false,
      triggers: {
        onAttached: null,
      },
      // Check for if we should send ice candidate offers back to the server.
      sendCandidateSignal: false,
    };
  }

  /**
   * Open the websocket and initialise the connection handler. This won't actually communicate any
   * data until the 'add' method is called for a specific device.
   * @param {object} connectionProps Connection properties for the BBB meeting.
   * @param {string} connectionProps.remoteMediaServer The endpoint details that will be used for webRTC connections.
   * @param {string} connectionProps.meeting.sessionToken The BBB session token used for opening the media connection websocket.
   * @param {string} connectionProps.meeting.internalMeetingID BBB internal meeting ID.
   * @param {string} connectionProps.meeting.voiceBridge BBB voice bridge ID for audio conference.
   * @param {string} connectionProps.userId BBB user ID for the current meeting participant
   * @param {string} connectionProps.fullName BBB user full name for the current meeting participant.
   * @param {object} connectionProps.connectionDetails STUN/TURN server information for routing webRTC.
   * @param {string} type The type of connection to open (either 'video' or 'screenshare')
   */
  open(connectionProps, type = SFU_COMPONENT_TYPE.VIDEO) {
    console.debug('[VideoStreamService] open video connection, type', type);
    return new Promise((resolve, reject) => {

      if (this.connections[type].open) {
        // Already open, just return
        return resolve();
      }

      if (typeof this.connectionProps === 'undefined') {
        this.connectionProps = connectionProps;
      } 
      this.connections[type].wsOpenResolver.push(resolve);
      this.connections[type].wsCloseResolver.push(reject);
      this.connections[type].changeRequested = 'connect';
      const { remoteMediaServer, sessionToken, connectionDetails } = this.connectionProps;

      // Format the relay server information for webRTC requests
      this.connectionProps.iceServers = this.formatStunTurnServers(connectionDetails);

      // Open a new websocket for both webcam and screenshare streams
      this.connections[type].ws = new WebSocket(
        `wss://${remoteMediaServer}${streamSettings.bbbSfuPath}?sessionToken=${sessionToken}`,
        []
      );

      // Activate websocket handlers
      this.addWebsocketHandlers(type);
    });
  }

  /**
   * Add the new local or remote device to the list that is being managed by this media connection handler.
   * @param {string} deviceId Unique reference from BigBlueButton for this device (the user ID, or 'desktop').
   * @param {boolean} isLocal True if the source is from this client, otherwise false.
   * @param {object} renderElementRef The HTML element reference which will receive this media.
   * @param {object} constraints List of device constraints to apply when selecting a source. -- TODO: Remove, deprecated
   * @param {string} bitrate Preferred quality bit rate for video stream source.
   */
  async add(deviceId, isLocal, renderElementRef = null, constraints = null, bitrate = null) {
    // Add the stream to the management list.
    let deviceAlreadyManaged = false;
    if (!this.streams.hasOwnProperty(deviceId)) {
      this.streams[deviceId] = this.defaultStreamProperties();
    } else {
      deviceAlreadyManaged = true;
    }

    const type = this.getStreamTypeFromDeviceId(deviceId);
    let useBitrate = bitrate;
    if (useBitrate === null) {
      useBitrate = (type === SFU_COMPONENT_TYPE.SCREENSHARE ? streamSettings.screenshareBitRate 
        : streamSettings.defaultWebcamQualityBitrate);
    } 
      

    assign(this.streams[deviceId], {
      isLocal,
      constraints,
      bitrate: useBitrate,
      renderElementRef,
    });

    // Make sure the connection is open before trying to add the device
    const connectionType = deviceId === DESKTOP_STREAM_ID? SFU_COMPONENT_TYPE.SCREENSHARE : SFU_COMPONENT_TYPE.VIDEO;
    if (this.connections[connectionType].open) {
      // If the connection is open and available, just connect immediately
      console.debug(`[VideoStreamService]: Adding ${deviceAlreadyManaged ? 'existing' : 'new'} device [${deviceId}]`);
      this.connectDevice(deviceId);
    } else {
      // If the connection is closed or in the process of opening, it will be connected whenever the stream is opened again
      console.debug(`[VideoStreamService]: Queuing ${deviceAlreadyManaged ? 'existing' : 'new'} device [${deviceId}]`);
    }
  }

  /**
   * Remove a device from the list that is being managed by this media connection handler.
   * @param {string} deviceId Unique reference from BigBlueButton for this device (the user ID).
   * @param {boolean} disconnect also disconnect device or not
   */
  async remove(deviceId, disconnect = false) {
    console.debug('[VideoStreamService] remove: deviceId', deviceId);
    if (!this.streams.hasOwnProperty(deviceId)) {
      return;
    }

    if (disconnect) {
      const type = this.getStreamTypeFromDeviceId(deviceId);
      this.connections[type].changeRequested = 'disconnect';
    }

    console.debug(`[VideoStreamService]: Removing stream management for device [${deviceId}]`);
    await this.stop(deviceId);
    delete this.streams[deviceId];
  }

  removeNonRunningDeviceFromQueue(deviceId) {
    console.debug(`[VideoStreamService]: Removing stream management for device [${deviceId}] without stopping the device`);
    delete this.streams[deviceId];
  }

  /**
   * Stop a device stream and disconnect from the remote peer, but retain the information for
   * any future reconnection.
   * @param {string} deviceId Unique reference from BigBlueButton for this device (the user ID).
   */
  stop = async (deviceId) => {
    console.debug('[VideoStreamService] stop: deviceId', deviceId);
    if (!this.streams.hasOwnProperty(deviceId)) {
      return;
    }

    if (deviceId === DESKTOP_STREAM_ID) {
      const { isLocal } = this.streams[deviceId];
      if (isLocal) {
        await this.unshareScreen();
      } else {
        await this.unviewScreen();
      }
    } else {
      // Stop the peer from communicating
      this.disconnectWebcam(deviceId, false);
      this.handleMediaChange(deviceId, 'stop');
    }

  }

  /**
   * Close down the video connection gracefully. 
   */
  close = async (type = SFU_COMPONENT_TYPE.VIDEO, stopDevices = true, clearHandlers = false) => {
    console.debug('[VideoStreamService] close video connection, type', type);
    if (clearHandlers) {
      this.removeWebsocketHandlers(type);
    }

    if (this.connections[type].open) {
      this.connections[type].changeRequested = 'disconnect';

      // Pause any active streams remaining.
      if (stopDevices) {
        for (const deviceId of this.getStreamsByType(type)) {
          await this.stop(deviceId);
        }
      }

      clearInterval(this.connections[type].pingInterval);

      // Close websocket connection to prevent multiple reconnects from happening.
      this.connections[type].open = false;
      this.connections[type].ws.close();
      this.connections[type].changeRequested = undefined;
    }
  }

  /**
   * Create a new peer connection for a webcam feed (local or remote)
   * @param {string} deviceId The unique device name
   */
  connectWebcam = async (deviceId) => {

    // Check if the peer is already being processed
    if (this.streams[deviceId].peer) {
      console.debug(`[VideoStreamService]: Webcam [${deviceId}] already has peer object, skipping connect`);
      return;
    }

    // WebRTC restrictions may need a capture device permission to release
    // useful ICE candidates on recvonly/no-gUM peers
    if (!this.streams[deviceId].isLocal) {
      try {
        // TODO: revisit in future if this check is needed, because the latest BBB release is
        // moving away from having to perform this extra getUserMedia check to allow candidates
        // to be generated.
        await this.tryGenerateIceCandidates();
      } catch (error) {
        // `Forced gUM to release additional ICE candidates failed due to ${error.name}.`
      }
    }

    this.streams[deviceId].outboundIceQueue = [];
    const peerOptions = {
      mediaConstraints: streamSettings.webcamMediaConstraints,
      onicecandidate: (candidate) => this.onIceCandidateGenerated(candidate, deviceId),
      oncandidategatheringdone: () => this.onIceCandidateGatheringDone(deviceId),
    };

    // If there were STUN/TURN servers found, add this config
    const { iceServers } = this.connectionProps;
    if (iceServers.length > 0) {
      peerOptions.configuration = {};
      peerOptions.configuration.iceServers = iceServers;
    }

    let WebRtcPeerObj;
    if (this.streams[deviceId].isLocal) {
      WebRtcPeerObj = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly;
    } else {
      WebRtcPeerObj = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly;
    }

    // Create the new peer object using kurento utils methods
    this.streams[deviceId].peer = new WebRtcPeerObj(peerOptions, (error) => {
      const newPeer = this.streams[deviceId].peer;
      if (!newPeer) {
        return this.onWebRTCError('No video peer object created', deviceId);
      }
      newPeer.started = false;
      newPeer.attached = false;
      newPeer.negotiated = false;
      if (newPeer.inboundIceQueue == null) {
        newPeer.inboundIceQueue = [];
      }

      if (error) {
        return this.onWebRTCError(error, deviceId);
      }

      // If the peer object was successfully created, generate the initial offer
      newPeer.generateOffer((error, sdpOffer) => this.onOfferGenerated(error, sdpOffer, deviceId));
      return false;
    });

    const peer = this.streams[deviceId].peer;
    if (peer && peer.peerConnection) {
      const conn = peer.peerConnection;
      conn.onconnectionstatechange = (event) => this.onConnectionStateChange(event, deviceId);
    }
  }

  /**
   * Start sharing a desktop screen with the BBB meeting.
   */
  shareScreen = () => {
    return new Promise(async (resolve, reject) => {

      // Make sure that a stream is open (since we might be reopening)
      if (!this.connections[SFU_COMPONENT_TYPE.SCREENSHARE]?.open) {
        return reject("No available screenshare media stream to use");
      }

      const { iceServers } = this.connectionProps;

      // Try to obtain the desktop stream using the browser functionality
      const desktopStream = await this.getScreenStream().catch((err) => {
        let errorSource = 'sourceSelection';
        if (err.message.toLowerCase() === 'permission denied') {
          // Pull out permission errors specifically
          errorSource = 'permissionDenied';
        } else if (err.message.toLowerCase() === 'permission denied by system') {
          errorSource = 'permissionDeniedBySystem';
        }
        this.handleDeviceError(DESKTOP_STREAM_ID, 'Screenshare source selection failed: ' + err.message, true, errorSource);
        return reject(err.message);
      });
      if (!desktopStream) return;

      // Now start the screensharing peer
      const options = {
        onicecandidate: (candidate) => this.onIceCandidateGenerated(candidate, DESKTOP_STREAM_ID),
        oncandidategatheringdone: () => this.onIceCandidateGatheringDone(DESKTOP_STREAM_ID),
        videoStream: desktopStream,
        configuration: {
          iceServers,
        },
      };

      this.streams[DESKTOP_STREAM_ID].peer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, (error) => {
        if (error) {
          this.handleDeviceError(DESKTOP_STREAM_ID, 'Screenshare peer creation failed: ' + JSON.stringify(error), true, 'peerCreation');
          return reject(error);
        }

        const newPeer = this.streams[DESKTOP_STREAM_ID].peer;
        newPeer.negotiated = false;
        newPeer.inboundIceQueue = [];
        newPeer.generateOffer((error, sdpOffer) => this.onOfferGenerated(error, sdpOffer, DESKTOP_STREAM_ID));

        const localStream = newPeer.peerConnection.getLocalStreams()[0];
        localStream.getVideoTracks()[0].onended = () => {
          this.onScreenshareEnded("Screenshare video track cancelled");
        };
        localStream.getVideoTracks()[0].oninactive = () => {
          this.onScreenshareEnded("Screenshare video track inactive");
        };

        return resolve();
      });

      this.streams[DESKTOP_STREAM_ID].peer.peerConnection.onconnectionstatechange = (event) => {
        this.onConnectionStateChange(event, DESKTOP_STREAM_ID);
      };
    });
  }

  /**
   * Stop sharing desktop.
   */
  unshareScreen = async () => {
    if (!this.streams.hasOwnProperty(DESKTOP_STREAM_ID)) {
      return;
    }

    const { peer } = this.streams[DESKTOP_STREAM_ID];
    if (peer) {
      this.disposeOfPeer(DESKTOP_STREAM_ID);
    }

    // When we dispose of screenshare, we want to disconnect the stream and create a new one.
    // closeDevices option is set to false since we are already disconnecting the screenshare.
    if (this.connections[SFU_COMPONENT_TYPE.SCREENSHARE].changeRequested !== 'disconnect') {
      await this.restartStreamByType(SFU_COMPONENT_TYPE.SCREENSHARE, false);
    }
    
    // Execute any handlers related to stopping video streams
    this.handleMediaChange(DESKTOP_STREAM_ID, 'stop');
  }

  /**
   * View a shared desktop screen.
   */
  viewScreen = () => {
    return new Promise(async (resolve, reject) => {
      const { iceServers } = this.connectionProps;

      const options = {
        mediaConstraints: {
          audio: false,
        },
        onicecandidate: (candidate) => this.onIceCandidateGenerated(candidate, DESKTOP_STREAM_ID),
        oncandidategatheringdone: () => this.onIceCandidateGatheringDone(DESKTOP_STREAM_ID),
        configuration: {
          iceServers,
        },
      };

      this.streams[DESKTOP_STREAM_ID].peer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, (error) => {
        if (error) {
          this.handleDeviceError(DESKTOP_STREAM_ID, `Unable to view screenshare: ${JSON.stringify(error)}`, true, 'peerCreation');
          return reject(error);
        }

        const newPeer = this.streams[DESKTOP_STREAM_ID].peer;
        newPeer.negotiated = false;
        newPeer.inboundIceQueue = [];
        newPeer.generateOffer((error, sdpOffer) => this.onOfferGenerated(error, sdpOffer, DESKTOP_STREAM_ID));
      });

      this.streams[DESKTOP_STREAM_ID].peer.peerConnection.onconnectionstatechange = (event) => {
        this.onConnectionStateChange(event, DESKTOP_STREAM_ID);
      };

      return resolve();
    });
  }

  /**
   * Stop viewing a remote desktop stream.
   */
  unviewScreen = async () => {
    if (!this.streams.hasOwnProperty(DESKTOP_STREAM_ID)) {
      return;
    }

    const { peer } = this.streams[DESKTOP_STREAM_ID];
    if (peer) {
      this.disposeOfPeer(DESKTOP_STREAM_ID);
    }

    // When we dispose of screenshare, we want to disconnect the stream and create a new one.
    // closeDevices option is set to false since we are already disconnecting the screenshare.
    if (this.connections[SFU_COMPONENT_TYPE.SCREENSHARE].changeRequested !== 'disconnect') {
      await this.restartStreamByType(SFU_COMPONENT_TYPE.SCREENSHARE, false);
    }

    // Execute any handlers related to stopping video streams
    this.handleMediaChange(DESKTOP_STREAM_ID, 'stop');
  }

  getWindowOnlineEvent = (type) => {
    if (!this.handlerFuncs.onWindowOnline.hasOwnProperty(type)) {
      this.handlerFuncs.onWindowOnline[type] = () => {
        setTimeout(
          () => this.tryReopen(type), 
          streamSettings.networkInterruptionTimeout
        );
      };
    }
    return this.handlerFuncs.onWindowOnline[type];
  }

  getWindowOfflineEvent = (type) => {
    if (!this.handlerFuncs.onWindowOffline.hasOwnProperty(type)) {
      this.handlerFuncs.onWindowOffline[type] = () => {
        this.onWsClose(type);
      };
    }
    return this.handlerFuncs.onWindowOffline[type];
  }

  /**
   * Associate websocket event handlers.
   */
  addWebsocketHandlers = (type = SFU_COMPONENT_TYPE.VIDEO) => {
    // Set handler functions
    this.connections[type].ws.onopen = () => this.onWsOpen(type);
    this.connections[type].ws.onclose = () => this.onWsClose(type);
    this.connections[type].ws.onmessage = (message) => this.onWsMessage(message, type);

    // Add listeners for the client browser going on/off line from network connectivity, and for unloading.
    window.addEventListener('online', this.getWindowOnlineEvent(type));
    window.addEventListener('offline', this.getWindowOfflineEvent(type));
  }

  /**
   * Handler for receipt of a websocket message, determines which method to call
   * based on message ID type.
   * @param {string} message Received web socket data packet.
   * @param {string} type SFU component type
   */
  onWsMessage = (message, type = SFU_COMPONENT_TYPE.VIDEO) => {
    // Deserialize the data JSON into an object
    const parsedMessage = JSON.parse(message.data);
    // Based on the id type, call the relevant handler method
    switch (parsedMessage.id) {
      case 'startResponse':
        this.handleStartResponse(parsedMessage, type);
        break;

      case 'playStart':
        this.handlePlayStart(parsedMessage, type);
        break;

      case 'playStop':
        this.handlePlayStop(parsedMessage, type);
        break;

      case 'stopSharing':
        this.handleStopSharing(parsedMessage, type);
        break;

      case 'iceCandidate':
        this.handleIceCandidate(parsedMessage, type);
        break;

      case 'pong':
        break;

      case 'error':
      default:
        this.handleSFUError(parsedMessage, type);
        break;
    }
  }

  /**
   * Upon the socket being closed unexpectedly, call the close handler.
   */
  onWsClose = (type = SFU_COMPONENT_TYPE.VIDEO) => {
    console.debug('[VideoStreamService] onWsClose, type', type);
    if (this.connections[type].open) {
      this.open = false;
      this.close(type);
    }
    this.connections[type].changeRequested = undefined;

    while (this.connections[type].wsCloseResolver.length > 0) {
      const resolverFunc = this.connections[type].wsCloseResolver.pop();
      resolverFunc();
    }
    console.debug(`[VideoStreamService]: Closed socket for stream type [${type}], clearing open resolvers`);
    this.connections[type].wsOpenResolver = [];
  }

  /**
   * When the websocket is opened, send any data that was flagged as pending
   * and start a keep-alive ping timer.
   */
  onWsOpen = (type = SFU_COMPONENT_TYPE.VIDEO) => {
    console.debug('[VideoStreamService] onWsOpen video connection, type', type);
    // Resend queued messages that happened when socket was not connected
    while (this.connections[type].wsQueue.length > 0) {
      const pendingMessage = this.connections[type].wsQueue.pop();
      this.connections[type].ws.send(pendingMessage.json, pendingMessage.callback);
    }
    this.connections[type].pingInterval = setInterval(() => this.ping(type), 
      streamSettings.pingInterval);
    this.connections[type].open = true;
    this.connections[type].changeRequested = undefined;

    console.debug(`[VideoStreamService]: Socket opened for type [${type}]`);

    // If there are any devices that we should be managing already set, then try to reconnect to them
    // on stream connection open
    const existingDevices = this.getStreamsByType(type);
    if (existingDevices.length) {
      console.debug(`[VideoStreamService]: Adding ${existingDevices.length} previously queued devices [${existingDevices.join(', ')}]`);
      for (const existingDeviceId of this.getStreamsByType(type)) {
        this.connectDevice(existingDeviceId);
      }
    }
    
    this.connections[type].wsCloseResolver = [];
    if (this.connections[type].wsOpenResolver.length > 0) {
      // This must be our initial connection attempt.
      while (this.connections[type].wsOpenResolver.length > 0) {
        const resolverFunc = this.connections[type].wsOpenResolver.pop();
        resolverFunc();
      }
    }
  }

  /**
   * Send a simple ping message to the websocket.
   */
  ping = (type = SFU_COMPONENT_TYPE.VIDEO) => {
    const message = { id: 'ping' };
    if (Object.keys(this.streams).length) {
      this.sendMessage(message, type);
    }
  }

  /**
   * Construct the message packet that will encapsulate anything sent to the
   * media server.
   * @param {object} message The message object
   * @param {string} message.id The type of message
   * @param {*} message.data The data of the message
   */
  sendMessage = (message, type = SFU_COMPONENT_TYPE.VIDEO) => {
    return new Promise((resolve, reject) => {
      const { ws } = this.connections[type];
      const jsonMessage = JSON.stringify(message);
      const messageCallback = (error) => {
        if (error) {
          reject(error);
          return;
        } else {
          resolve();
          return;
        }
      };

      // If there is an active websocket connection, serialize and then send the
      // message to the media server.
      if (this.connectedToMediaServer(type)) {
        ws.send(jsonMessage, messageCallback);
      } else if (message.id !== 'stop' && message.id !== 'ping') {
        // No need to queue video stop messages or pings
        this.connections[type].wsQueue.push({
          json: jsonMessage,
          callback: messageCallback
        });
      }
    });
  }

  /**
   * Test whether the websocket to the media server is open
   * and available for use.
   * @returns {boolean} True if the websocket connection is open.
   */
  connectedToMediaServer = (type = SFU_COMPONENT_TYPE.VIDEO) => {
    return this.connections[type].open 
      && this.connections[type].ws.readyState === WebSocket.OPEN;
  }

  /**
   * Send any pending outbound ICE candidate configurations to the server, one by one.
   * @param {object} peer The remote media server peer object.
   * @param {string} deviceId The unique device ID, such as webcam.
   */
  processOutboundIceQueue = (deviceId) => {
    const { outboundIceQueue } = this.streams[deviceId];
    while (outboundIceQueue && outboundIceQueue.length) {
      const candidate = outboundIceQueue.shift();
      this.sendIceCandidateToSFU(deviceId, candidate);
    }
  }

  /**
   * Handler for 'startResponse' message back from the media server, indicating that the remote
   * peer has provided an SDP answer. If the answer looks good, start processing any remote ICE
   * candidates, and send any of our own ICE candidates as well.
   * @param {object} message The data message received from the media server.
   */
  handleStartResponse = (message, socketType = SFU_COMPONENT_TYPE.VIDEO) => {
    const { sdpAnswer, role, connectionId, type, response } = message;
    let streamId = (type === SFU_COMPONENT_TYPE.SCREENSHARE) ? DESKTOP_STREAM_ID : message.cameraId;

    // Validate that for screenshare streams, we have an 'accepted' response, otherwise error
    if (type === SFU_COMPONENT_TYPE.SCREENSHARE && response !== 'accepted') {
      this.handleSFUError({
        reason: `Received startResponse that was not accepted by SFU`,
        streamId,
      });
      return;
    }

    if (typeof streamId === 'undefined' || !has(this.streams, streamId)) {
      this.handleSFUError({
        streamId,
        reason: `Received start response for unknown stream ${streamId}`,
      });
      return;
    }

    const { peer } = this.streams[streamId];
    this.streams[streamId].sfuConnectionId = connectionId;

    // Validate that the response role matches what we requested
    const expectedRole = this.getStreamRoleFromDeviceId(streamId);
    if (role !== expectedRole) {
      this.handleSFUError({
        streamId,
        reason: `Received start response with role ${role} but expected role ${expectedRole}`,
      });
      return;
    }

    console.debug(`[VideoStreamService]: Start request accepted for ${type} ${role} stream ${streamId}`);

    // Let the peer process the answer response
    if (peer) {
      peer.processAnswer(sdpAnswer, (error) => {
        if (error) {
          this.handleDeviceError(
            streamId,
            `Error processing SDP answer from SFU for ${type} ${role} stream ${streamId}:`
            + JSON.stringify(error),
            true,
            'sdpAnswer'
          );
          return;
        }

        // Mark the peer as negotiated and flush the ICE queue
        peer.negotiated = true;
        this.processOutboundIceQueue(streamId);
        this.processInboundIceQueue(streamId);
      });
    }
  }

  /**
   * Handler for receiving an ICE candidate message from the media server.
   * @param {object} message Message data from the media server
   */
  handleIceCandidate = (message) => {
    const { type, candidate } = message;

    let streamId = (type === SFU_COMPONENT_TYPE.SCREENSHARE) ? DESKTOP_STREAM_ID : message.cameraId;
    if (typeof streamId === 'undefined' || !has(this.streams, streamId)) {
      this.handleSFUError({
        streamId: streamId,
        reason: `Received ice candidate for unknown stream ${streamId}`
      });
      return;
    }

    const { peer } = this.streams[streamId];

    if (peer) {
      if (peer.negotiated) {
        // Add the candidate to the list if we have received an SDP answer
        this.addCandidateToPeer(streamId, candidate);
      } else {
        // ICE candidates are queued until a SDP answer has been processed.
        // This was done due to a long term iOS/Safari quirk where it'd
        // fail if candidates were added before the offer/answer cycle was completed.
        if (peer.inboundIceQueue === null) {
          peer.inboundIceQueue = [];
        }
        peer.inboundIceQueue.push(candidate);
      }
    }
  }

  /**
   * Stop a peer connection for a particular device. This could be due to an error that occurred
   * or because the client is stopping the stream.
   * @param {string} deviceId The ID of the streaming device.
   * @param {boolean} restarting If set to true, the restart timer will be left active to attempt reconnection.
   */
  disconnectWebcam = async (deviceId, restarting = false) => {
    // in this case, 'closed' state is not caused by an error;
    // we stop listening to prevent this from being treated as an error
    console.debug(`[VideoStreamService]: Disconnecting webcam [${deviceId}], restarting = `, restarting);
    if (!has(this.streams, deviceId)) {
      // Device has already been removed
      return;
    }
    const { peer, restartTimeout, restartTimer } = this.streams[deviceId] || {};
    if (peer && peer.peerConnection) {
      const conn = peer.peerConnection;
      conn.oniceconnectionstatechange = null;
    }

    await this.sendStopMessage(deviceId);

    // Clear the shared camera media flow timeout and current reconnect period
    // when destroying it if the peer won't restart
    if (!restarting) {
      if (restartTimeout) {
        clearTimeout(restartTimeout);
        delete this.streams[deviceId].restartTimeout;
      }

      if (restartTimer) {
        delete this.streams[deviceId].restartTimer;
      }
    }

    this.disposeOfPeer(deviceId);
  }

  /**
   * Dispose of the peer connection after it has been stopped.
   * @param {string} deviceId The device name
   */
  disposeOfPeer = (deviceId) => {
    const { peer } = this.streams[deviceId] || {};
    if (peer) {
      console.debug(`[VideoStreamService]: Disposing of peer for device [${deviceId}]`, peer);
      if (typeof peer.peerConnection?.onconnectionstatechange === 'function') {
        peer.peerConnection.onconnectionstatechange = null;
      }
      if (typeof peer.dispose === 'function') {
        peer.dispose();
      }
      delete this.streams[deviceId].outboundIceQueue;
      delete this.streams[deviceId].peer;
    }
  }

  /**
   * Generate handler for a timeout when starting a new connection.
   * @param {string} deviceId Unique device name for media sharing
   * @returns {function} Handler for a timeout when trying to start a peer connection
   */
  _getWebRTCStartTimeout = (deviceId) => {
    return () => {
      // Peer that timed out is a sharer/publisher
      if (this.streams[deviceId].isLocal) {
        this.handleDeviceError(deviceId, `Camera SHARER has not succeeded in ${streamSettings.cameraShareFailedWaitTime} for ${deviceId}`, 
        true, 'peerTimeout');
        this.disconnectWebcam(deviceId);
      } else {
        // Create new reconnect interval time
        const oldReconnectTimer = this.streams[deviceId].restartTimer;
        const newReconnectTimer = Math.min(
          2 * oldReconnectTimer,
          streamSettings.maxCameraShareFailedWaitTime,
        );
        this.streams[deviceId].restartTimer = newReconnectTimer;

        // Clear the current reconnect interval so it can be re-set in connectWebcam
        if (this.streams[deviceId].restartTimeout) {
          delete this.streams[deviceId].restartTimeout;
        }

        // Peer that timed out is a subscriber/viewer
        // Subscribers try to reconnect according to their timers if media could
        // not reach the server. That's why we pass the restarting flag as true
        // to the stop procedure as to not destroy the timers
        this.disconnectWebcam(deviceId, true);
        this.connectWebcam(deviceId);
      }
    };
  }

  /**
   * Handler for errors occuring during WebRTC communication events
   * @param {string} error Code for error event
   * @param {string} deviceId Unique device name for media sharing
   */
  onWebRTCError = (error, deviceId) => {
    const { isLocal } = this.streams[deviceId];
    // 2001 means MEDIA_SERVER_OFFLINE. It's a server-wide error.
    // We only display it to a sharer/publisher instance to avoid popping up
    // redundant toasts.
    // If the client only has viewer instances, the WS will close unexpectedly
    // and an error will be shown there for them.
    if (error === 2001 && !isLocal) {
      return;
    }

    //const errorMessage = intlClientErrors[error.name]
    //  || intlSFUErrors[error] || intlClientErrors.permissionError;
    // Only display WebRTC negotiation error toasts to sharers. The viewer streams
    // will try to autoreconnect silently, but the error will log nonetheless
    if (isLocal) {
      let errorSource = 'peerCreation';
      if (error.message.toLowerCase() === 'permission denied') {
        // Pull out permission errors specifically
        errorSource = 'permissionDenied';
      } else if (error.message.toLowerCase() === 'permission denied by system') {
        errorSource = 'permissionDeniedBySystem';
      }
      this.handleDeviceError(deviceId, `Camera peer creation failed for ${deviceId} due to ${error.message}`, true, errorSource)
    } else {
      // If it's a viewer, set the reconnection timeout. There's a good chance
      // no local candidate was generated and it wasn't set.
      this.setReconnectionTimeout(deviceId);
    }

    // shareWebcam as the second argument means it will only try to reconnect if
    // it's a viewer instance (see disconnectWebcam restarting argument)
    this.disconnectWebcam(deviceId, !isLocal);
  }

  /**
   * Create a new timer if necessary to try reconnecting a media sharing device
   * @param {string} deviceId Unique device name
   */
  setReconnectionTimeout = (deviceId) => {
    const { peer, restartTimer, restartTimeout } = this.streams[deviceId];
    const peerHasStarted = peer && peer.started === true;
    const shouldSetReconnectionTimeout = !restartTimeout && !peerHasStarted;

    if (shouldSetReconnectionTimeout) {
      const newReconnectTimer = restartTimer || streamSettings.cameraShareFailedWaitTime;
      this.streams[deviceId].restartTimer = newReconnectTimer;
      this.streams[deviceId].restartTimeout = setTimeout(
        this._getWebRTCStartTimeout(deviceId),
        newReconnectTimer,
      );
    }
  }

  /**
   * Handler for when we are able to generate an ICE candidate so that it is
   * sent to the media server
   * @param {string} deviceId Unique device name
   */
  onIceCandidateGenerated = (candidate, deviceId) => {
    if (this?.streams?.[deviceId]?.sendCandidateSignal) {
      const { peer } = this.streams[deviceId];

      if (deviceId !== DESKTOP_STREAM_ID && peer && !peer.negotiated) {
        this.streams[deviceId].outboundIceQueue.push(candidate);
        return;
      }

      this.sendIceCandidateToSFU(deviceId, candidate);
    }

    return null;
  }

  /**
   * Handler that is called when all local ice candidates have been gathered
   * @param {string} deviceId Unique device name
   */
  onIceCandidateGatheringDone = (deviceId) => {
    console.debug(`[VideoStreamService]: Ice candidate gathering done for device ${deviceId}`);
  }


  /**
   * Callback function which is executed when the local webRTC offer has been generated,
   * to send the 'start' message to the media server on the data channel.
   * @param {object} error Any error that prevented the offer from being generated
   * @param {object} offerSdp The sdp offer that was generated
   * @param {string} deviceId The stream device that this offer relates to
   * @returns 
   */
   onOfferGenerated = (error, offerSdp, deviceId) => {
    if (error) {
      return this.onWebRTCError(error, deviceId);
    }

    const { bitrate } = this.streams[deviceId];
    const { meeting, userId, fullName } = this.connectionProps;
    const { internalMeetingID, voiceBridge } = meeting;
    const type = this.getStreamTypeFromDeviceId(deviceId);
    const role = this.getStreamRoleFromDeviceId(deviceId);

    const message = {
      id: 'start',
      type,
      role,
      sdpOffer: offerSdp,
      voiceBridge: voiceBridge,
      userName: fullName,
      bitrate,
    };

    if (type === SFU_COMPONENT_TYPE.SCREENSHARE) {
      // Properties specific to screenshare offers
      message.internalMeetingId = internalMeetingID;
      message.callerName = userId;
      message.contentType = 'screenshare';
      message.hasAudio = false;

      // Include the maximum resolution supported
      const { width, height } = this.getScreenshareDimensions();
      message.vh = height;
      message.vw = width;
    } else {
      // Properties specific to webcam/video offers
      message.cameraId = deviceId;
      message.meetingId = internalMeetingID;
      message.userId = userId;
    }

    this.sendMessage(message, type);
    return false;
  }

  /**
   * Callback function that triggers when the webRTC connection state has changed for a particular
   * stream. The new state will be visible on the peer object.
   * @param {object} eventIdentifier The type of event that occurred
   * @param {string} deviceId Unique stream device identifier
   */
   onConnectionStateChange = (eventIdentifier, deviceId) => {
    if (!this.streams.hasOwnProperty(deviceId)) {
      return;
    }

    const { peer, isLocal } = this.streams[deviceId];
    const type = this.getStreamTypeFromDeviceId(deviceId);
    if (peer) {
      const { connectionState } = peer.peerConnection;
      if (eventIdentifier) {
        console.debug(`[VideoStreamService]: Connection state for ${type} device ${deviceId} changed.`,
          ' Event: ', eventIdentifier,
          ' ConnectionState: ', connectionState);
      }

      if (connectionState === 'failed' || connectionState === 'closed') {
        peer.peerConnection.onconnectionstatechange = null;
        if (deviceId !== DESKTOP_STREAM_ID) {
          this.disconnectWebcam(deviceId, !isLocal);
        } else if (isLocal) {
          this.unshareScreen();
        } else {
          this.unviewScreen();
        }
        this.handleDeviceError(deviceId, `Missing peer at ICE connection state transition for device ${deviceId}`, true, 'iceFailed');
      }
    }
  }

  onScreenshareEnded = (reason) => {
    console.log('[VideoStreamService]: Screenshare ended: ', reason);
    this.handleDeviceError(DESKTOP_STREAM_ID, 'Screenshare ended ' + reason, true, 'screenshareEnded');
    this.unshareScreen();
  }

  /**
   * Construct the message to be sent to the media server containing an ICE candidate.
   * @param {object} candidate ICE candidate generated locally
   * @param {string} deviceId Unique device name
   */
  sendIceCandidateToSFU = (deviceId, candidate) => {
    const { meeting, userId } = this.connectionProps;
    const { voiceBridge } = meeting;
    const type = this.getStreamTypeFromDeviceId(deviceId);
    const role = this.getStreamRoleFromDeviceId(deviceId);

    const message = {
      role,
      type,
      candidate,
      callerName: userId,
    };

    if (type === SFU_COMPONENT_TYPE.SCREENSHARE) {
      message.id = 'iceCandidate';
      message.voiceBridge = voiceBridge;
    } else {
      message.id = 'onIceCandidate';
      message.cameraId = deviceId;
    }

    this.sendMessage(message, type);
  }

  /**
   * Handle any error message raised from the media server.
   * @param {object} message The error object received
   */
  handleSFUError = (message) => {
    let { reason, streamId } = message;

    if (message.type === SFU_COMPONENT_TYPE.SCREENSHARE) {
      streamId = DESKTOP_STREAM_ID;
    }

    if (!(streamId in this.streams)) {
      console.warn('[VideoStreamService]: SFU error for unknown stream', message);
      return;
    }
    const { isLocal, mediaFlowing } = this.streams[streamId];

    this.handleDeviceError(streamId, reason, true, 'sfuError');

    if (mediaFlowing) {
      this.handleMediaChange(streamId, 'stop');
    }

    // Don't try to reconnect if we are sharing webcam and it failed
    if (streamId !== DESKTOP_STREAM_ID) {
      this.disconnectWebcam(streamId, !isLocal);
    } else {
      if (isLocal) {
        this.unshareScreen();
      } else {
        this.unviewScreen();
      }
    }
  }

  /**
   * Execute any error handling callback for this device.
   * @param {string} deviceId The unique identifier for the device which errored
   * @param {string} error Descriptive error message.
   * @param {boolean} fatal If true, the stream will be closed
   */
  handleDeviceError = (deviceId, error, fatal = true, source) => {
    const { handlers } = this.streams[deviceId];
    if (handlers && 'onError' in handlers) {
      for (const callbackFunc of handlers.onError) {
        callbackFunc(error, fatal, source);
      }
    }
  }

  /**
   * Execute any handlers for starting or stopping the stream video, and attach or
   * unattach the video stream to any element that is managing it.
   * @param {string} deviceId The unique identifier for the device stream that had a change.
   * @param {string} type Either 'start' or 'stop'.
   */
  handleMediaChange = (deviceId, type) => {

    if (!this.streams.hasOwnProperty(deviceId)) {
      return;
    }

    const {
      handlers,
      peer,
      renderElementRef,
      isLocal,
      mediaFlowing,
    } = this.streams[deviceId];

    switch (type) {
      case 'start':
        if (renderElementRef) {
          // Attach the stream to its video page element
          if (!peer.attached) {
            const stream = isLocal ? peer.getLocalStream() : peer.getRemoteStream();
            renderElementRef.pause();
            renderElementRef.srcObject = stream;
            renderElementRef.load();
            peer.attached = true;
          }
        }

        if (!mediaFlowing) {
          this.streams[deviceId].mediaFlowing = true;
          if ('onMediaStarted' in handlers) {
            for (const callbackFunc of handlers.onMediaStarted) {
              callbackFunc(deviceId, peer);
            }
          }
        }
        break;

      case 'stop':
        if (renderElementRef) {
          // Unattach the stream from its current video page element
          renderElementRef.pause();
          renderElementRef.currentTime = 0;
          renderElementRef.srcObject = null;
          renderElementRef.load();
        }
        if (mediaFlowing) {
          if (peer) {
            peer.attached = false;
          }
          this.streams[deviceId].mediaFlowing = false;
          if ('onMediaStopped' in handlers) {
            for (const callbackFunc of handlers.onMediaStopped) {
              callbackFunc(deviceId);
            }
          }
        }
        break;

      default:
        break;
    }

    // Tell the session manager video is flowing
    //if (isLocal) {
    //  this.session.shareMedia('webcam', cameraId);
    //}
  }

  /**
   * Pick up any received ICE candidates and add to the peer object
   * @param {string} deviceId Unique device name
   */
  processInboundIceQueue = (deviceId) => {
    const { peer } = this.streams[deviceId];
    while (peer && peer.inboundIceQueue.length) {
      const candidate = peer.inboundIceQueue.shift();
      this.addCandidateToPeer(deviceId, candidate);
    }
  }

  handleScreenShared = (deviceId, screenType) => {
    const { handlers } = this.streams[deviceId];
    if (handlers && 'onScreenShareStarted' in handlers) {
      for (const callbackFunc of handlers.onScreenShareStarted) {
        callbackFunc(screenType);
      }
    }
  }

  /**
   * Wrapper for function to add a received ICE candidate to the peer object
   * @param {object} candidate The candidate object received
   * @param {string} deviceId Unique device name
   */
  addCandidateToPeer = (deviceId, candidate) => {
    const { peer } = this.streams[deviceId];
    peer.addIceCandidate(candidate, (error) => {
      if (error) {
        // Just ignore the error. We can't be sure if a candidate failure on add is
        // fatal or not, so that's why we have a timeout set up for negotiations
        // and listeners for ICE state transitioning to failures, so we won't
        // act on it here
        console.warn(`[VideoStreamService]: Error while adding iceCandidate for device ${deviceId}`, error);
      }
    });
  }

  /**
   * Handler method for when a PlayStop message is received from the media server, to update
   * the local state objects accordingly.
   * @param {object} message Message received from the media server
   */
  handlePlayStop = (message) => {
    const { cameraId } = message;

    // Execute any callbacks now that the media has stopped
    if (this.streams.hasOwnProperty(cameraId)) {
      this.handleMediaChange(cameraId, 'stop');
      this.disconnectWebcam(cameraId, false);
    }
  }

  /**
   * Handler method for when a StopSharing message is received from the media server,
   * which means it advises the screen sharing will be stopped.
   * @param {object} message Message received from the media server
   */
  handleStopSharing = (message) => {
    if (this.streams.hasOwnProperty(DESKTOP_STREAM_ID)) {
      this.handleMediaChange(DESKTOP_STREAM_ID, 'stop');
      this.stop(DESKTOP_STREAM_ID);
    }
  }

  /**
   * Handler method for when a PlayStart message is received from the media server, to update
   * the local state objects accordingly.
   * @param {object} message Message
   */
  handlePlayStart = (message) => {
    const { cameraId, type } = message;

    if (!this.streams.hasOwnProperty(cameraId) && type !== SFU_COMPONENT_TYPE.SCREENSHARE) {
      return;
    }

    const streamId = type !== SFU_COMPONENT_TYPE.SCREENSHARE ? cameraId : DESKTOP_STREAM_ID;
    const { peer, restartTimeout } = this.streams[streamId];

    if (peer) {
      peer.started = true;

      // Clear camera shared timeout when camera successfully starts
      clearTimeout(restartTimeout);
      delete this.streams[streamId].restartTimeout;
      delete this.streams[streamId].restartTimer;

      this.handleMediaChange(streamId, 'start');

    } else {
      // SFU playStart response for ${cameraId} arrived after the peer was discarded, ignore it.
    }
  }

  /**
   * Update the DOM element which is playing this stream.
   * @param {string} deviceId Unique name for this stream device
   * @param {object} newRef The new DOM element reference to which this stream is attached
   */
  attachToElement = (deviceId, newRef) => {
    if (this.streams.hasOwnProperty(deviceId)) {
      const { peer, mediaFlowing, renderElementRef, isLocal, handlers } = this.streams[deviceId];

      // Flag the video will be reattached if we are changing element
      if (mediaFlowing && newRef !== renderElementRef && peer) {
        peer.attached = false;
      }

      // Update the reference object for video playing
      this.streams[deviceId].renderElementRef = newRef;

      // Attach and play the video
      if (mediaFlowing) {
        // Attach the stream to its video page element
        const stream = isLocal ? peer.getLocalStream() : peer.getRemoteStream();
        newRef.pause();
        newRef.srcObject = stream;
        newRef.load();
        if (peer) {
          peer.attached = true;
        }
        if ('onMediaAttached' in handlers) {
          for (const callbackFunc of handlers.onMediaAttached) {
            callbackFunc(deviceId, peer, newRef);
          }
        }
      }

      // Check if there are any attach triggers to execute
      const execTrigger = this.streams[deviceId].triggers.onAttached;
      if (execTrigger !== null) {
        execTrigger(deviceId);
        this.streams[deviceId].triggers.onAttached = null;
      }
    }
  }

  /**
   * Remove this stream from the DOM element which was playing it
   * @param {string} deviceId Unique name for this stream device
   * @param {object} newRef The old DOM element reference from which this stream should be removed
   */
  unattachFromElement = (deviceId, oldRef) => {
    if (this.streams.hasOwnProperty(deviceId)) {
      let { mediaFlowing, renderElementRef, peer } = this.streams[deviceId];

      // Unattach from the current element if it matches the request
      if (renderElementRef === oldRef) {
        oldRef.pause();
        oldRef.currentTime = 0;
        oldRef.srcObject = null;
        oldRef.load();
        this.streams[deviceId].renderElementRef = null;
        if (mediaFlowing && peer) {
          peer.attached = false;
        }
      }
    }
  }

  /**
   * Register an external function handler to update the client when an event occurs
   * @param {string} eventType Can be one of onMediaStarted, onMediaStopped, onMediaAttached or OnError, onScreenShareStarted
   * @param {string} deviceId The unique name for the device to which this callback relates
   * @param {function} handlerFunc Callback function to be registered when that event occurs
   */
  registerDeviceHandler = (deviceId, eventType, handlerFunc) => {
    if (deviceId in this.streams) {
      if (!('handlers' in this.streams[deviceId])) {
        this.streams[deviceId].handlers = {};
      }
      if (eventType in this.streams[deviceId]) {
        this.streams[deviceId].handlers[eventType].push(handlerFunc);
      } else {
        this.streams[deviceId].handlers[eventType] = [handlerFunc];
      }
    } else {
      this.streams[deviceId] = this.defaultStreamProperties();
      this.streams[deviceId].handlers[eventType].push(handlerFunc);
    }
  }

  /**
   * Remove all device handler functions for a particular device
   * @param {string} deviceId The unique name for the device to which this callback relates
   */
  unregisterDeviceHandlers = (deviceId) => {
    if (deviceId === 'all') {
      Object.keys(this.streams).forEach((d) => {
        this.streams[d].handlers = {};
      });
    } else if (deviceId in this.streams) {
      this.streams[deviceId].handlers = {};
    }
  }

  /**
   * Remove a specific device handler function for a particular device
   * @param {string} deviceId The unique name for the device to which this callback relates
   * @param {string} eventType Can be one of onMediaStarted, onMediaStopped, onMediaAttached or OnError
   * @param {function} handlerFunc Callback function to be removed
   */
  unregisterDeviceHandler = (deviceId, eventType, handlerFunc) => {
    if (this.streams.hasOwnProperty(deviceId) && this.streams[deviceId].handlers.hasOwnProperty(eventType)) {
      const removeIndex = this.streams[deviceId].handlers[eventType].indexOf(handlerFunc);
      if (removeIndex >= 0) {
        this.streams[deviceId].handlers[eventType].splice(removeIndex, 1);
      }
    }
  }

  /**
   * Try to generate candidates for a recvonly RTCPeerConnection without
   * a gUM permission and check if there are any candidates generated other than
   * a mDNS host candidate. If there aren't, forcefully request gUM permission
   * for mic (best chance of a gUM working is mic) to try and make the browser
   * generate at least srflx candidates.
   * This is a workaround due to a behaviour some browsers display (mainly Safari)
   * where they won't generate srflx or relay candidates if no gUM permission is
   * given. Since our media servers aren't able to make it work by prflx
   * candidates, we need to do this.
   * @returns Promise that resolves when ICE candidates have been generated locally
   */
  tryGenerateIceCandidates() {
    return new Promise((resolve, reject) => {
      this.canGenerateIceCandidates().then(() => {
        resolve();
      }).catch(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(() => {
          this.canGenerateIceCandidates().then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        }).catch((error) => {
          console.error('[VideoStreamService] - on tryGenerateIceCandidates -> getUserMedia error', error,
            'Constraints={ audio: true, video: false }');
          reject(error);
        });
      });
    });
  }

  /**
   * Attempt to generate ICE candidates
   */
  canGenerateIceCandidates() {
    return new Promise((resolve, reject) => {
      if (this.couldGenerateIceCandidates) {
        resolve();
        return;
      }

      const { iceServers } = this.connectionProps;
      const pc = new RTCPeerConnection({ iceServers });
      let countIceCandidates = 0;

      try { pc.addTransceiver('audio'); } catch (e) { }
      pc.onicecandidate = function (e) {
        if (countIceCandidates) return;
        if (e.candidate && e.candidate.candidate.indexOf('.local') === -1) {
          countIceCandidates++;
          this.couldGenerateIceCandidates = true;
          resolve();
        }
      };

      pc.onicegatheringstatechange = function (e) {
        if (e.currentTarget.iceGatheringState === 'complete' && countIceCandidates === 0) {
          console.log('No useful ICE candidate found. Will request gUM permission.');
          reject();
        }
      };

      setTimeout(() => {
        pc.close();
        if (!countIceCandidates) reject();
      }, 5000);

      const p = pc.createOffer({ offerToReceiveVideo: true });
      p.then((answer) => { pc.setLocalDescription(answer); });
    });
  }

  /**
   * If the browser goes offline and then back on again, attempt to reconnect if necessary
   */
  tryReopen = (type = SFU_COMPONENT_TYPE.VIDEO) => {
    if (!this.connections[type].ws) {
      // Recreate the websocket
      const { remoteMediaServer, sessionToken } = this.connectionProps;

      // Reopen the web socket.
      this.connections[type].ws = new WebSocket(
        `wss://${remoteMediaServer}${streamSettings.bbbSfuPath}?sessionToken=${sessionToken}`,
        [],
      );
    }
  }

  /**
   *
   * @param {object} serversInfo Response from BBB containing STUN and TURN server info.
   * @param {array} serversInfo.turnServers Array of available TURN servers.
   * @param {array} serversInfo.stunServers Array of available STUN servers.
   * @returns {array} Combined STUN and TURN server information formatted for media server.
   */
  formatStunTurnServers(serversInfo) {
    const turnServers = [];
    for (const turnEntry of serversInfo.turnServers) {
      const { password, url, username } = turnEntry;
      turnServers.push({
        urls: url,
        password,
        username,
      });
    }
    const stunServers = serversInfo.stunServers.map(server => server.url);
    const rtcStuns = stunServers.map(url => ({ urls: url }));
    const rtcTurns = turnServers.map(t => ({ urls: t.urls, credential: t.password, username: t.username }));
    return rtcStuns.concat(rtcTurns);
  }

  /**
   * Helper function to determine the type of getDisplayMedia function that is applicable to the user's
   * current browser.
   */
  getBoundGDM = () => {
    if (typeof navigator.getDisplayMedia === 'function') {
      return navigator.getDisplayMedia.bind(navigator);
    } else if (navigator.mediaDevices && typeof navigator.mediaDevices.getDisplayMedia === 'function') {
      return navigator.mediaDevices.getDisplayMedia.bind(navigator.mediaDevices);
    }
  }

  /**
   * Attempt to fetch the video stream for desktop screenshare, triggering the user prompt to
   * select the screen space to share.
   * @returns {Promise} Resolves upon successfully obtaining a stream object.
   */
  getScreenStream = async () => {
    return new Promise((resolve, reject) => {
      const mediaConstraints = streamSettings.screenshareMediaConstraints;

      const gDMCallback = (stream) => {
        return new Promise((callbackResolve, callbackReject) => {
          // Some older Chromium variants choke on gDM when audio: true by NOT generating
          // a promise rejection AND not generating a valid input screen stream, need to
          // work around that manually for now - prlanzarin
          if (stream == null) {
            return callbackReject(errorMessages.browserNotSupported);
          }

          let hasSharedDesktop = false;
          let sharedSurfaces = [];
          if (typeof stream.getVideoTracks === 'function'
            && typeof mediaConstraints.video === 'object') {
            stream.getVideoTracks().forEach(track => {
              if (typeof track.applyConstraints === 'function') {
                track.applyConstraints(mediaConstraints.video).catch(error => {
                  console.warn('[VideoStreamService]: Error applying screenshare video constraint', error);
                });
              }

              // Validate that the student is sharing their whole screen
              let displaySurface = undefined;
              if (typeof track.getSettings === 'function') {
                const settings = track.getSettings();
                displaySurface = settings.displaySurface;
              }
              if (displaySurface) {
                // DisplaySurface is only available on newer browsers (chrome)
                if (displaySurface === FULL_SCREEN_BROWSER_MAPPERS.chrome) {
                  hasSharedDesktop = true;
                } else {
                  sharedSurfaces.push(displaySurface);
                }
              } else {
                // For firefox, try checking the track label
                if (track.label === FULL_SCREEN_BROWSER_MAPPERS.firefox) {
                  hasSharedDesktop = true;
                } else {
                  sharedSurfaces.push(track.label);
                }
              }
            });
          }

          if (typeof stream.getAudioTracks === 'function'
            && typeof mediaConstraints.audio === 'object') {
            stream.getAudioTracks().forEach(track => {
              if (typeof track.applyConstraints === 'function') {
                track.applyConstraints(mediaConstraints.audio).catch(error => {
                  console.warn('[VideoStreamService]: Error applying screenshare audio constraint', error);
                });
              }
            });
          }

          // For now, not sharing the whole desktop doesn't block the student from continuing, it only shows an enabled
          // screen share button with a different icon, sends an error log message to the gateway and adds it to the
          // screenshare-start message from the gateway.
          // This could be enhanced in future iterations to prompt the student to try again.
          if (hasSharedDesktop) {
            this.handleScreenShared('desktop', SCREEN_SHARE_TYPES.FULL.mapper);
          } else {
            this.handleScreenShared('desktop', SCREEN_SHARE_TYPES.PARTIAL.mapper);
            console.error('Not sharing whole desktop: only ' + sharedSurfaces.join(', '));
            this.handleDeviceError(DESKTOP_STREAM_ID, 'Not sharing whole desktop, only ' + sharedSurfaces.join(', '), false, 'partialScreenshare');
          }

          return callbackResolve(stream);
        });
      };

      const getDisplayMedia = this.getBoundGDM();

      if (typeof getDisplayMedia === 'function') {
        return getDisplayMedia(mediaConstraints)
          .then(gDMCallback)
          .then((stream) => {
            return resolve(stream);
          })
          .catch(error => {
            console.error('[VideoStreamService]: getDisplayMedia call failed when attempting to share screen', error);
            return reject(error);
          });
      } else {
        // getDisplayMedia isn't supported, error its way out
        return reject(errorMessages.browserNotSupported);
      }
    });
  };

  /**
   * Helper function to determine if the given mediaStream has audio in it
   * @param {object} stream The media stream to test
   * @returns True if there are audio tracks
   */
  streamHasAudioTrack = (stream) => {
    return stream
      && typeof stream.getAudioTracks === 'function'
      && stream.getAudioTracks().length >= 1;
  }

  /**
   * Fetch the SFU stream type (either 'screenshare' or 'video') based on the current device properties.
   */
  getStreamTypeFromDeviceId = (deviceId) => {
    return deviceId === DESKTOP_STREAM_ID ? SFU_COMPONENT_TYPE.SCREENSHARE : SFU_COMPONENT_TYPE.VIDEO;
  }

  /**
   * Fetch the applicable SFU role ('share', 'viewer', 'send' or 'recv') based on the current device properties.
   */
  getStreamRoleFromDeviceId = (deviceId) => {
    if (!has(this.streams, deviceId + '.isLocal')) {
      return null;
    }
    const type = this.getStreamTypeFromDeviceId(deviceId);
    if (type === SFU_COMPONENT_TYPE.SCREENSHARE) {
      return this.streams[deviceId].isLocal ? 'send' : 'recv';
    } else {
      return this.streams[deviceId].isLocal ? 'share' : 'viewer';
    }
  }

  /**
   * Determine the current dimensions of the users screen
   * @returns {object} Dimensions object with 'height' and 'width' properties.
   */
  getScreenshareDimensions = () => {
    const screenHeight = window.screen.height;
    const screenWidth = window.screen.width;

    const maxShareHeight = streamSettings.maxScreenshareDimensions.height;
    const maxShareWidth = streamSettings.maxScreenshareDimensions.width;

    let dimensions = {
      width: screenWidth,
      height: screenHeight,
    };

    // Scale down the screenshare sent accordingly if it is too large
    if (screenHeight > maxShareHeight || screenWidth > maxShareWidth) {
      const factorWidth = maxShareWidth / screenWidth;
      const factorHeight = maxShareHeight / screenHeight;
    
      if (factorWidth < factorHeight) {
        dimensions.width = Math.trunc(screenWidth * factorWidth);
        dimensions.height = Math.trunc(screenHeight * factorWidth);
      } else {
        dimensions.width = Math.trunc(screenWidth * factorHeight);
        dimensions.height = Math.trunc(screenHeight * factorHeight);
      }
    }
    return dimensions;
  }

  /**
   * Send a stop message to the SFU to advise that streaming has stopped.
   */
  sendStopMessage = async (deviceId) => {
    const type = this.getStreamTypeFromDeviceId(deviceId);
    const role = this.getStreamRoleFromDeviceId(deviceId);
    if (!this.streams.hasOwnProperty(deviceId) || !this.connectedToMediaServer(type)) {
      console.warn(`[VideoStreamService]: Unable to send stop message for device ${deviceId} to SFU`);
      return;
    }

    const { voiceBridge } = this.connectionProps.meeting;

    const stopMessage = {
      id: 'stop',
      type,
      role
    };

    if (type === SFU_COMPONENT_TYPE.SCREENSHARE) {
      stopMessage.voiceBridge = voiceBridge;
    } else {
      stopMessage.cameraId = deviceId;
    }

    console.debug(`[VideoStreamService]: Sending stop message for device ${deviceId} to media SFU server`, stopMessage);
    await this.sendMessage(stopMessage, type);
  }

  /**
   * Disassociate any websocket event handlers.
   */
  removeWebsocketHandlers = (type = SFU_COMPONENT_TYPE.VIDEO) => {
    // Set handler functions
    this.connections[type].ws.onopen = undefined;
    this.connections[type].ws.onclose = undefined;
    this.connections[type].ws.onmessage = undefined;

    window.removeEventListener('online', this.getWindowOnlineEvent(type));
    window.removeEventListener('offline', this.getWindowOfflineEvent(type));
  }

  /**
   * Disconnect and then reopen the media stream, which will stop any active devices being managed
   */
   restartStreamByType = (type, stopDevices = true) => {
    return new Promise((resolve, reject) => {
      // First stop all the handlers and then close the existing stream
      this.close(type, stopDevices, true);

      // Now reopen the stream
      this.open(this.connectionProps, type)
        .then(() => {
          this.addWebsocketHandlers(type);
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  /**
   * Helper function for connecting up to a new stream
   * @param {string} deviceId Device name to connect
   * @returns 
   */
  connectDevice = async (deviceId) => {
    if (!this.streams.hasOwnProperty(deviceId)) {
      return;
    }

    const { isLocal, renderElementRef } = this.streams[deviceId];

    if (deviceId === DESKTOP_STREAM_ID) {
      // Desktop sharing/viewing has specific functionality
      if (isLocal) {
        await this.shareScreen().catch((err) => {
          this.handleDeviceError(DESKTOP_STREAM_ID, 'Failed to start sharing screen: ' + err, true, 'shareFailed')
        });
      } else {
        // For showing the desktop share, we need a display video element
        if (renderElementRef !== null) {
          await this.viewScreen().catch((err) => {
            this.handleDeviceError(DESKTOP_STREAM_ID, 'Failed to start viewing screenshare: ' + err, true, 'viewFailed')
          });
        } else {
          this.streams[deviceId].triggers.onAttached = this.viewScreen;
        }
      }
    } else {
      // Webcam is handled within this class.
      if (isLocal || renderElementRef !== null) {
        this.connectWebcam(deviceId);
      } else {
        // If remote viewing but we don't have an element yet, wait to connect the stream
        this.streams[deviceId].triggers.onAttached = this.connectWebcam;
      }
    }

    console.debug(`[VideoStreamService]: Connected device ${deviceId}.`)
  };

  /** Utility function to return the stream IDs of any managed devices that match the provided type
   * @param {string} type The type of connection, either 'screenshare' or 'video'
   * @returns An array of device ids that match.
   */
  getStreamsByType = (type) => {
    return Object.keys(this.streams)
      .filter(deviceId => (type === SFU_COMPONENT_TYPE.SCREENSHARE && deviceId === DESKTOP_STREAM_ID) 
        || (type === SFU_COMPONENT_TYPE.VIDEO && deviceId !== DESKTOP_STREAM_ID));
  }

  /**
   * Determines whether a specific device stream is currently being managed by the service
   * @param {string} deviceId The name of the device
   * @returns True if the device is being managed
   */
  isDeviceManaged = (deviceId) => {
    return this.streams.hasOwnProperty(deviceId);
  }

}
