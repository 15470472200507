import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid2,
  Typography,
} from '@mui/material';
import BackgroundBanner from './BackgroundBanner';
import RecordingIndicator from '../notification/RecordingIndicator';
import VideoStream from '../audioVisual/VideoStream';

import { authContext } from '../../authContext';
import { joinContext } from '../context/JoinContext';
import handler from '../audioVisual/handler';
import addConferenceError from '../audioVisual/helper/addConferenceError';
import clearConferenceError from '../audioVisual/helper/clearConferenceError';
import logToGateway from '../../utils/logToGateway';
import { ACTIONS } from '../../constants/joinSession';
import { SHARED_MEDIA_STATES } from '../../constants/mediaStates';

function BannerWithStudentSelfViewContainer(props) {
  const { actionTypeDetails, bannerGridDisplayOverride } = props;
  const { state, dispatch } = useContext(joinContext);
  const { user, features } = useContext(authContext);
  const connectedUserId = state.examSession?.connectionDetails?.connectionProps?.userId;
  const connectionErrors = state.examSession?.connectionDetails?.connectionErrors;

  const addError = (mediaType, error) => {
    dispatch({
      type: ACTIONS.UPDATE_EXAM_SESSION,
      value: {
        connectionDetails: {
          ...state.examSession?.connectionDetails,
          connectionErrors: addConferenceError(mediaType, error, connectionErrors),
        }
      }
    });
  };

  const clearErrors = (mediaType) => {
    dispatch({
      type: ACTIONS.UPDATE_EXAM_SESSION,
      value: {
        connectionDetails: {
          ...state.examSession?.connectionDetails,
          connectionErrors: clearConferenceError(mediaType, undefined, connectionErrors),
        }
      }
    });
  };

  const logToGatewayHandler = (logType, logDetails) => {
    logToGateway(
      state.gatewayService,
      logType,
      logDetails,
      user.id,
      state.examSession?.id
    );
  };

  const updateVideoState = (mediaType, status) => {
    dispatch({
      type: ACTIONS.UPDATE_SHARED_MEDIA_STATUS,
      value: {
        mediaType: mediaType,
        status: status,
      }
    });
  };

  const updateWarnings = (updatedWarnings) => {
    dispatch({ type: ACTIONS.SET_SHARED_MEDIA_WARNINGS, value: updatedWarnings });
  };

  const onVideoErrorHandler = handler.onVideoError(
    addError,
    () => { return state },
    () => { return connectedUserId },
    logToGatewayHandler,
    updateVideoState,
    updateWarnings
  );

  const onVideoShareHandler = handler.onVideoShare(
    clearErrors,
    () => { return state },
    () => { return connectedUserId },
    updateVideoState);

  const onVideoUnshareHandler = handler.onVideoUnshare(
    () => { return state },
    () => { return connectedUserId },
    updateWarnings,
    updateVideoState);

  const onScreenShareStarted = (screenType) => {
    dispatch({ type: ACTIONS.SET_SCREEN_SHARE_TYPE, value: screenType });
  }

  return (
    <Box sx={bannerGridDisplayOverride?.sx}>
      <BackgroundBanner
        backgroundGradientPalette={actionTypeDetails?.backgroundGradientPalette}
        backgroundColor={actionTypeDetails?.backgroundColor}
        { ...bannerGridDisplayOverride?.backgroundBanner || {} }
      >
        <Grid2 container sx={{ alignItems: 'center' }}>
          <Grid2 size={{ xs: 0, sm: 3 }} { ...bannerGridDisplayOverride?.statusContainer || {} }>
            {Object.values(state.sharedMediaStatus).includes(SHARED_MEDIA_STATES.CONNECTED) &&
              <RecordingIndicator />
            }
          </Grid2>
          <Grid2 size={{ xs: 6 }} { ...bannerGridDisplayOverride?.textContainer || {} }>
            <Typography
              color="common.white"
              component="p"
              sx={{ textAlign: 'center', p: 2, mb: 0 }}
              variant="h4"
              key={`banner-${actionTypeDetails?.mapper}`}
            >{actionTypeDetails?.bannerContent}</Typography>
          </Grid2>
          <Grid2
            size={{ xs: 6, sm: 3 }}
            sx={{ '@media (min-width: 600px)': { flexGrow: 1 }, flexGrow: 1 }}
            { ...bannerGridDisplayOverride?.selfViewContainer || {} }
          >
            {(state.sharedMediaStatus.webcam === SHARED_MEDIA_STATES.CONNECTED || state.sharedMediaStatus.webcam === SHARED_MEDIA_STATES.CONNECTING) &&
              <Box m={1} borderRadius="10px" border="2px solid" borderColor="popPink.main" overflow="hidden" maxWidth="250px">
                <VideoStream
                  isLocal={true}
                  mirror={true}
                  onVideoError={(err, disconnected, source) => onVideoErrorHandler('webcam', err, disconnected, source, features)}
                  onVideoStarted={() => onVideoShareHandler('webcam')}
                  onVideoStopped={() => onVideoUnshareHandler('webcam')}
                  render={true}
                  streamId={connectedUserId}
                  streamService={state.videoService}
                  videoConstraints={{
                    video: { deviceId: state.deviceInfo.videoDevice },
                    audio: false,
                  }}
                />
              </Box>
            }
            {(state.sharedMediaStatus.desktop === SHARED_MEDIA_STATES.CONNECTED || state.sharedMediaStatus.desktop === SHARED_MEDIA_STATES.CONNECTING) &&
              <VideoStream
                isLocal={true}
                onScreenShareStarted={onScreenShareStarted}
                onVideoError={(err, disconnected, source) => onVideoErrorHandler('desktop', err, disconnected, source, features)}
                onVideoStarted={() => onVideoShareHandler('desktop')}
                onVideoStopped={() => onVideoUnshareHandler('desktop')}
                render={false}
                streamId="desktop"
                streamService={state.videoService}
              />}
          </Grid2>
        </Grid2>
      </BackgroundBanner>
    </Box>
  )
}

BannerWithStudentSelfViewContainer.propTypes = {
  actionTypeDetails: PropTypes.object,
  bannerGridDisplayOverride: PropTypes.object,
};

export default BannerWithStudentSelfViewContainer;