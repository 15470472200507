import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Popover } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { scrollBoxStyle } from '../../config/theme';

const contentStyle = {
  padding: 2,
  maxWidth: '40%',
  lineHeight: 1.5,
};

const buttonStyle = {
  marginTop: -2,
}

function InfoPopover(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const {
    buttonStyle: buttonStyleOverride,
    buttonText, content,
    contentStyle: contentStyleOverride,
    icon,
    iconButtonSize,
    popoverText,
  } = props;
  const id = open ? 'info-popover' : undefined;
  const popoverTop = anchorEl ? anchorEl.getBoundingClientRect().top : 0;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  }

  return (
    <Box>
      {!buttonText &&
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          aria-label="More information"
          sx={{ ...buttonStyle, ...buttonStyleOverride }}
          size={iconButtonSize || 'large'}
        >
          {icon ? icon : <InfoOutlinedIcon/>}
        </IconButton>
      }
      {buttonText &&
        <Button
          color="secondary"
          onClick={handleClick}
          size="small"
          sx={{ ...buttonStyleOverride, fontSize: '0.8rem', lineHeight: '1rem', textTransform: 'initial' }}
          variant="outlined"
        >
          {buttonText}
        </Button>
      }
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{ sx: { ...contentStyle, ...contentStyleOverride, maxHeight: `calc(100% - ${popoverTop}px)` } }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={scrollBoxStyle}>
          {popoverText}
          <div>
          {content}
          </div>
        </Box>
      </Popover>
    </Box>
  );
}

InfoPopover.propTypes = {
  buttonStyle: PropTypes.object,
  buttonText: PropTypes.string,
  content: PropTypes.object,
  contentStyle: PropTypes.object,
  icon: PropTypes.object,
  iconButtonSize: PropTypes.string,
  popoverText: PropTypes.string,
};

export default InfoPopover;
