import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Grid2 } from '@mui/material';

import BannerWithStudentSelfViewContainer from './container/BannerWithStudentSelfViewContainer';
import ChatContainer from './container/ChatContainer';
import HelpContainer from './container/HelpContainer';
import Notice from './notification/Notice';
import OnboardStepper from './content/OnboardStepper';
import PrivacyAdvice from './content/PrivacyAdvice';
import StepAction from './step/StepAction';
import Troubleshooting from './content/Troubleshooting';
import VideoStream from './audioVisual/VideoStream';

import getConferenceErrorByPriority from './audioVisual/helper/getConferenceErrorByPriority';
import logToGateway from '../utils/logToGateway';
import mapSortedOnboardStepsAndProgress from './step/helper/mapSortedOnboardStepsAndProgress';
import onRaiseHand from './audioVisual/handler/onRaiseHand';
import shouldHideChat from '../utils/shouldHideChat';
import startSharingVideo from './audioVisual/helper/startSharingVideo';

import { authContext } from '../authContext';
import { joinContext } from './context/JoinContext';

import { ACTIONS, ONBOARD_ACTION_TYPES } from '../constants/joinSession';
import { FEATURE_TOGGLES } from '../constants/featureToggles';
import { MEETING_ENDED_STATES } from '../constants/session';
import { MEDIA_STREAM_CONNECTION_STATES } from '../constants/mediaStates';
import { STUDENT } from '../constants/chat';

import OnboardService from '../services/OnboardService';

function Join(props) {
  const { canDisplaySession, examDetails } = props;
  const { state, dispatch } = useContext(joinContext);
  const { user, features } = useContext(authContext);
  const [onboardAssignmentRequestError, setRequestError] = useState();
  const showChatOnlyWithOtherParticipants = shouldHideChat(examDetails);
  const actionTypeDetails = ONBOARD_ACTION_TYPES[state.currentActionType] || ONBOARD_ACTION_TYPES.CONNECTING;
  const activeExamTypeDetails = ONBOARD_ACTION_TYPES.EXAM_START[state.examState] || actionTypeDetails;
  const conferenceError = getConferenceErrorByPriority(
    state.examSession?.connectionDetails?.connectionErrors, actionTypeDetails.errorCategory);
  const showTroubleshooting = !isEmpty(conferenceError)
    && !MEETING_ENDED_STATES.includes(ONBOARD_ACTION_TYPES[state.currentActionType]?.meetingStateKey);
  const showOnboardStepper = isEmpty(onboardAssignmentRequestError) && !isEmpty(state.onboardProgressSteps) && actionTypeDetails?.displayStepper;
  const visibleBanner = !state.participantMedia?.video?.streaming && !showTroubleshooting;

  const restartVideoShare = (videoType) => {
    const setVideoStates = (mediaType, connectionState) => {
      dispatch({ type: ACTIONS.UPDATE_SHARED_MEDIA_STATUS, value:
          { mediaType: mediaType, status: connectionState }
      });
    };
    startSharingVideo(videoType, state.sharedMediaStatus, setVideoStates);
  };

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_EXAM_SESSION, value: examDetails });
    dispatch({ type: ACTIONS.UPDATE_EXAM_STATE, value: examDetails?.examState });
  }, [dispatch, examDetails]);

  const getOnboardAssignmentDetails = useCallback(async () => {
    if (!canDisplaySession || isEmpty(examDetails.id)) {
      return;
    }
    try {
      setRequestError(undefined);
      const onboardFlowAssignment = await OnboardService.getExamSessionFlowAssignment(examDetails.id);
      // Initialise progress if there isn't any, otherwise we 'recalibrate' the progress to reset the completion status upon fully reloading the page
      const onboardProgress = isEmpty(onboardFlowAssignment.onboardSlotProgress) ? await OnboardService.addProgress(onboardFlowAssignment.id) : await OnboardService.recalibrateOnboardProgress(onboardFlowAssignment.id);
      const onboardFlow = await OnboardService.getOnboardFlowFromFlowAssignment(onboardFlowAssignment.id);

      const mappedStepsWithProgress = mapSortedOnboardStepsAndProgress(onboardFlow.onboardFlowSteps, onboardProgress);

      const onboardAssignmentDetails = {
        id: onboardFlowAssignment.id,
        onboardProgressSteps: mappedStepsWithProgress,
      };
      dispatch({ type: ACTIONS.SET_ONBOARD_ASSIGNMENT, value: onboardAssignmentDetails });
    } catch (error) {
      setRequestError('We were unable to get details for that eVigilation onboard session. Please try again or contact IT support');
      console.error('Unable to get onboard details', error);
    }
  }, [canDisplaySession, examDetails.id, dispatch]);

  useEffect(() =>  { getOnboardAssignmentDetails() }, [getOnboardAssignmentDetails]);

  const invisibleBannerWithSelfViewProps = {
    statusContainer: { sx: { display: 'none', }, },
    sx: { position: 'absolute', top: 0, right: 0, maxWidth: '200px', zIndex: 2, opacity: '75%', },
    textContainer: { sx: { display: 'none', }, },
    backgroundBanner: { backgroundGradientPalette: undefined, backgroundColor: 'transparent', },
  };

  return (
    <>
      {canDisplaySession && state.examSession &&
        <>
          <Box display="flex" flexDirection="column" ml={3} mr={3} mt={1}>
            <Grid2 container mt={2} spacing={2}>
              <Grid2 size={{ sm: 6, md: 8, xl: 9 }} height="calc(100vh - 112px)">
                <Box bgcolor="common.white" height="100%" display="flex" flexDirection="column" position="relative">
                  <BannerWithStudentSelfViewContainer
                    actionTypeDetails={state.currentActionType === ONBOARD_ACTION_TYPES.EXAM_START.mapper ? activeExamTypeDetails : actionTypeDetails}
                    bannerGridDisplayOverride={visibleBanner ? {} : invisibleBannerWithSelfViewProps}
                  />
                  {!state.participantMedia?.video?.streaming && showTroubleshooting &&
                    <Troubleshooting
                      category={conferenceError.category}
                      errorCode={features[FEATURE_TOGGLES.HIDE_DETAILED_ERROR_CODES] ? conferenceError.errorKey : conferenceError.errorCode}
                      gatewayConnectionOpen={state.gatewayConnectionOpen}
                      startSharingVideo={restartVideoShare}
                      streamStatus={state.sharedMediaStatus}
                      streamWarnings={state.sharedMediaWarnings}
                    />
                  }
                  {!state.participantMedia?.video?.streaming && !showTroubleshooting &&
                    <>
                      <Box p={showOnboardStepper ? 1 : 0}>
                        {!isEmpty(onboardAssignmentRequestError) &&
                          <Notice noticeType="error">{onboardAssignmentRequestError}</Notice>
                        }
                        {showOnboardStepper &&
                          <OnboardStepper />
                        }
                      </Box>
                      <Box flexGrow="1" display="flex" flexDirection="column" justifyContent="space-between" overflow="hidden">
                        <StepAction />
                      </Box>
                    </>
                  }
                  {state.participantMedia?.video?.streaming &&
                    <VideoStream
                      streamService={state.videoService}
                      streamId={state.participantMedia?.video?.stream}
                      onVideoError={(err, _disconnected) => {
                        logToGateway(
                          state.gatewayService,
                          'student_webcam_view',
                          { error: err, source: 'mainVideo', stream: state.participantMedia?.video?.stream },
                          user.id,
                          state.examSession.id
                        );
                      }}
                      isLocal={false}
                    />
                  }
                  <Box bgcolor="#f5f6f6" m={1} p={1}>
                    <PrivacyAdvice
                      examLandingPage={state.examSession.examLandingPage}
                      startSharingAudio={() => {
                        dispatch({
                          type: ACTIONS.SET_AUDIO_STATE,
                          value: { 'audio': MEDIA_STREAM_CONNECTION_STATES.RECONNECTING }
                        });
                      }}
                      startSharingVideo={restartVideoShare}
                      streamStatus={state.sharedMediaStatus}
                      streamWarnings={state.sharedMediaWarnings}
                    />
                  </Box>
                </Box>
              </Grid2>
              <Grid2 size={{ sm: 6, md: 4, xl: 3 }} position="relative">
                <ChatContainer
                  gatewayService={state.gatewayService}
                  displayName={user?.fullName || 'Student'}
                  userId={user.id || ''}
                  authorType={STUDENT.authorType}
                  showChatOnlyWithParticipant={showChatOnlyWithOtherParticipants}
                  renderContainer={true}
                  slotId={examDetails.id}
                >
                  <HelpContainer
                    examType={state.examSession.examType}
                    gatewayConnectionOpen={state.gatewayConnectionOpen}
                    humanSupervised={state.examSession.humanSupervised}
                    onRaiseHand={onRaiseHand(state.gatewayService)}
                  />
                </ChatContainer>
              </Grid2>
            </Grid2>
          </Box>
        </>
      }
    </>
  )
}

Join.propTypes = {
  canDisplaySession: PropTypes.bool,
  examDetails: PropTypes.object,
}

export default Join;