import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

function SingleSelect(props) {
  const { field, handleChange, value } = props;

  return (
    <TextField
      select
      fullWidth={field.fullWidth}
      label={field.label}
      slotProps={{ inputLabel: { shrink: true } }}
      onChange={(event) => handleChange(field.name, event.target.value)}
      sx={{ bgcolor: 'common.white' }}
      value={value || ''}
    >
      {field.options.map((option) => {
        return (
          <MenuItem key={option.label} value={option.id}>
            {option.label}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

SingleSelect.propTypes = {
  field: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default SingleSelect;