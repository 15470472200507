import { useState, useEffect, cloneElement } from 'react';
import PropTypes from 'prop-types';
import ShiftService from '../../services/ShiftService';

const WithShifts = (props) => {
  const {
    userId,
    onDate,
    else: elseFunction = () => null,
    whileLoading: loadingFunction = () => null,
    children
  } = props;
  const [ requestState, setRequestState ] = useState('PENDING');
  const [ shifts, setShifts ] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    // Get shifts from backend on component load
    setRequestState('PENDING');
    const searchData = {
      supervisorId: userId,
      fullDayFromDate: onDate ?? Date.now(),
      fetchSlotCountDetails: false,
    };
    ShiftService.getSupervisorShifts(searchData, controller.signal)
    .then((results) => {
      setRequestState('COMPLETE');
      setShifts(results);
    })
    .catch((error) => {
      if(error !== 'AbortError') {
        console.error(`Unable to retrieve user shifts`, error);
        setRequestState('ERROR');
      }
    });

    return () => {
      controller.abort();
    };
  }, [userId, onDate]);

  if (requestState === 'PENDING') {
    // While waiting for shift request to complete, show any loading component function
    return loadingFunction();
  } else if (shifts.length > 0) {
    // On successful retrieval of one or more shifts, pass this list to
    // any child elements and render
    return cloneElement(children, {shifts});
  } else {
    // If no shifts are found, show any defined else function
    return elseFunction();
  }
};

WithShifts.propTypes = {
  userId: PropTypes.string.isRequired,
  onDate: PropTypes.number,
  else: PropTypes.func,
  whileLoading: PropTypes.func,
};

export default WithShifts;
