import { format as dateFnsFormat, isValid as dateFnsIsValid, parseISO, set } from 'date-fns';
import { TZDate } from '@date-fns/tz';
import { isEmpty } from 'lodash';
import { SESSION_TIMES as sessionTimesConst } from '../constants/examSessions';
import { SHIFT_LOCATIONS } from '../constants/shift';


const getSessionTime = (startDateTime, endDateTime) => {
  if (!startDateTime || !endDateTime) {
    return '';
  }
  return `${getTimeDisplay(startDateTime)} – ${getTimeDisplay(endDateTime)}`;
}

export const getTimeDisplay = (dateTime) => {
  if (!dateTime) return '';
  const parsedDate = typeof dateTime === 'string' ? parseISO(dateTime) : dateTime;
  if (dateFnsIsValid(parsedDate)) {
    return dateFnsFormat(parsedDate, 'HH:mm');
  }
  return '';
}

export const getSessionStartEndTimes = (selectedDate, selectedSession) => {
  const startFormat = "T" + sessionTimesConst[selectedSession]['startTime'];
  const endFormat = "T" + sessionTimesConst[selectedSession]['endTime'];
  return {
    startDateTime: selectedDate + startFormat,
    endDateTime: selectedDate + endFormat,
  }
};

export const getSessionStartEndTimesAtLocation = (selectedDate, selectedSession, location) => {
  const { startDateTime, endDateTime } = getSessionStartEndTimes(selectedDate, selectedSession);

  let shiftLocation = location;
  if (location === SHIFT_LOCATIONS.ALL.value || !location) {
    shiftLocation = Object.values(SHIFT_LOCATIONS).find(sl => sl.default);
  };

  return {
    startDateTime: parseISO(startDateTime, { in: TZDate.tz(shiftLocation) }),
    endDateTime: parseISO(endDateTime, { in: TZDate.tz(shiftLocation) }),
  }

}

export const getSessionName = (startTime, SESSION_TIMES, location) => {
  if (!startTime) {
    return;
  }

  const dateOfStartTime = parseISO(startTime);
  if (!dateFnsIsValid(dateOfStartTime) || !SESSION_TIMES) {
    return;
  }

  let sessionName;

  for (let session in SESSION_TIMES) {
    const startTimeArr = SESSION_TIMES[session].startTime.split(':');
    const endTimeArr = SESSION_TIMES[session].endTime.split(':');
    const setOptions = { ...(!isEmpty(location)) && { in: TZDate.tz(location) } }
    const start = set(dateOfStartTime, { hours: startTimeArr[0], minutes: startTimeArr[1], seconds: startTimeArr[2] }, setOptions);
    const end = set(dateOfStartTime, { hours: endTimeArr[0], minutes: endTimeArr[1], seconds: endTimeArr[2] }, setOptions);
    if (dateOfStartTime >= start && dateOfStartTime <= end) {
      sessionName = SESSION_TIMES[session].displayName;
      break;
    }
  }
  return sessionName;
};

export default getSessionTime;
