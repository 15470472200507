import { isEmpty } from 'lodash';
import { SHIFT_LOCATIONS } from '../constants/shift';

/**
 * @param {string} locationName IANA location name
 * @returns {Object} Matching shift location object
*/
export const getShiftLocation = (locationName) => {
    if (isEmpty(locationName)) {
        return Object.values(SHIFT_LOCATIONS).find(sl => sl.default === true);
    }

    return Object.values(SHIFT_LOCATIONS).find(sl => sl.value === locationName);
}