import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { find, isEmpty } from 'lodash';

import { Box, Fab, FormControl, FormLabel, Grid2 } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import DateSelector from './DateSelector';
import MultiComboField from './MultiComboField';
import SearchField from './SearchField';
import SessionSelector from './SessionSelector';
import SingleSelect from './SearchSingleSelect';
import UserLookup from './UserLookup';
import Routing from '../../utils/Routing';
import { SEARCH_OPTIONS } from '../../constants/search';

function SearchFormByOptions(props) {

  const [localState, setLocalState] = useState({});
  const navigate = useNavigate();

  const {
    canNavigate =  () => true,
    fieldsToShow,
    fieldsetSx,
    getRoute,
    labels,
    searchFields,
    uriParams = {},
  } = props;

  useEffect(() => {
    const getStateFields = (params) => {
      return fieldsToShow.reduce(
        (combinedObject, attributeKeyToAdd) =>
          ({ ...combinedObject, [attributeKeyToAdd]: params[attributeKeyToAdd]}),
        {});
    };
    const getParamsWithMultiFieldValues = () => {
      const params = { ...uriParams } || {};
      if (!isEmpty(params?.[SEARCH_OPTIONS.searchMultiCombo])) {
        const options = find(searchFields, ['component', SEARCH_OPTIONS.searchMultiCombo])?.options;
        params[SEARCH_OPTIONS.searchMultiCombo] = uriParams[SEARCH_OPTIONS.searchMultiCombo].map(item => {
          return find(options, ['id', item]) || item;
        });
      }
      return params;
    }

    const paramsFromUri = getParamsWithMultiFieldValues();
    const stateDerivedFromProps = getStateFields(paramsFromUri);
    setLocalState(stateDerivedFromProps);
  }, [fieldsToShow, searchFields, uriParams])

  const handleFieldChange = (fieldName, value) => {
    setLocalState({ ...localState, [fieldName]: value });
  };

  const getParams = () => {
    let params = {};
    fieldsToShow.forEach((field) => {
      params[field] = localState?.[field]
    });
    return params;
  };

  const onNavigateButtonClick = () => {
    const params = getParams();
    if (canNavigate(params)) {
      navigate(
        Routing.path(getRoute(params))
      );
    }
  };

  const getSearchFields = () => {
    return fieldsToShow.map(field => {
      let fieldElem;
      const fieldDetails = find(searchFields, { component: field });
      switch(field) {
        case SEARCH_OPTIONS.date:
          fieldElem = (
            <DateSelector
              defaultRequired={fieldDetails?.required === undefined ? true : fieldDetails.required}
              field={{ ...fieldDetails, fieldName: field }}
              handleDateChange={handleFieldChange}
              selectedDate={localState?.[field]}
            />
          )
          break;
        case SEARCH_OPTIONS.session:
          fieldElem = (
            <SessionSelector
              fieldName={field}
              handleSessionChange={handleFieldChange}
              selectedSession={localState?.[field]}
            />
          );
          break;
        case SEARCH_OPTIONS.searchNumber:
        case SEARCH_OPTIONS.searchText:
          fieldElem = (
            <SearchField
              field={fieldDetails}
              fieldValue={localState?.[field]}
              handleChange={handleFieldChange}
            />
          );
          break;
        case SEARCH_OPTIONS.userLookup:
          fieldElem = (
            <UserLookup
              dataProps={fieldDetails.dataProps}
              fieldAriaLabel={fieldDetails.ariaLabel}
              fieldLabel={fieldDetails.label}
              fieldName={field}
              inputDataProps={fieldDetails.inputDataProps}
              multiple={fieldDetails.multiple}
              setUserValue={handleFieldChange}
              userValue={localState?.[field]}
            />
          );
          break;
        case SEARCH_OPTIONS.searchMultiCombo:
          fieldElem = (
            <MultiComboField
              field={fieldDetails}
              handleChange={handleFieldChange}
              values={localState?.[field]}
            />
          );
          break;
        case SEARCH_OPTIONS.searchSingleSelect:
          fieldElem = (
            <SingleSelect
              field={fieldDetails}
              handleChange={handleFieldChange}
              value={localState?.[field]}
            />
          );
          break;
        default:
          break;
      }
      // either gridSize has been defined, or divide equally by number of fields, without dividing by 0/nothing
      const numFieldsToDivideBy = (!fieldsToShow?.length || fieldsToShow === 0) ? 12 : fieldsToShow.length;

      return (
        <Grid2 key={field} size={{ xs: 12, md: fieldDetails?.gridSize ?? (12 / numFieldsToDivideBy) }}>
          {fieldElem}
        </Grid2>
      );
    })
  };

  return (
    <FormControl component="fieldset" sx={{ display: "block", ...fieldsetSx }} aria-label={labels.fieldsetLabel}>
      <FormLabel component="legend" style={{ display:'none' }} />
      <Box display="flex" alignItems="flex-start">
        <Grid2 container spacing={2} sx={{ width: '100%' }}>
          {getSearchFields()}
        </Grid2>
        <Box ml={2}>
          <Fab
            color="primary"
            aria-label="Search"
            disabled={!canNavigate(getParams())}
            onClick={onNavigateButtonClick}
            size="medium"
          >
            <SearchIcon />
          </Fab>
        </Box>
      </Box>
    </FormControl>
  );
}

SearchFormByOptions.propTypes = {
  canNavigate: PropTypes.func.isRequired,
  fieldsToShow: PropTypes.array.isRequired,
  fieldsetSx: PropTypes.object,
  getRoute: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    fieldsetLabel: PropTypes.string.isRequired,
  }),
  searchFields: PropTypes.array,
  uriParams: PropTypes.object.isRequired,
};

export default SearchFormByOptions;
