import rules from '../config/rules';
import { has, includes, intersection, isEmpty } from 'lodash';

//Overloaded function that allows actions to be a single or collection of type string
//Where contextRequired is true, it will return if any context/action combination is allowed for the logged in user
//Where it matters to know which one, send single action
const check = (currentRules, capabilityContextAccess, contextRequired, actions, data) => {
  
  // always act as an array, even when single string received
  const actionsList = [].concat(actions);

  if (capabilityContextAccess === undefined || isEmpty(capabilityContextAccess)) {
    // if the logged in person has no capabilities, check if there's an auth override for that capability
    let overrideResult = false;
    for (const action of actionsList) {
      if (has(currentRules[action], 'override')) {
        overrideResult = currentRules[action].override(data, contextRequired);
        if (overrideResult === true) break;
      }
    }
    return overrideResult;
  }

  if (intersection(Object.keys(capabilityContextAccess), actionsList).length === 0) {
    return false;
  }

  if (!contextRequired) {
    return true;
  }
  if (data === undefined) {
    return false;
  }

  let allowedForAtLeastOneAction = false;

  actionsList.forEach((action) => {
    let allowedContexts = capabilityContextAccess?.[action]?.['contexts'];
    if (includes(allowedContexts, data['id'])) {
      allowedForAtLeastOneAction = true;
    }
  });

  return allowedForAtLeastOneAction;
};

export const CanThey = (capabilityContextAccess, contextRequired, actions, data) => {
  return check(rules, capabilityContextAccess, contextRequired, actions, data);
}

const defaultFunc = () => null;

const Can = ({ yes = defaultFunc, no = defaultFunc, ...props }) => {
  return check(rules, props.capabilityContextAccess, props.contextRequired, props.perform, props.data)
    ? yes()
    : no();
}

export default Can;
