import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AutorenewIcon from '@mui/icons-material/Autorenew';

function RefreshButton(props) {

  const { refreshCallback, disabled = false } = props;

  return (
    <Button
      variant="text"
      color= "primary"
      startIcon={<AutorenewIcon/>}
      onClick={() => refreshCallback()}
      disabled={disabled}
      sx={{
        textTransform: 'none',
        lineHeight: 'initial',
      }}
    >
      Refresh
    </Button>
);
}

RefreshButton.propTypes = {
  refreshCallback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default RefreshButton;
