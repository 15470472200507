import React from 'react';
import { useLocation, useParams } from 'react-router';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AuthConsumer } from '../authContext';
import Can from '../components/Can';
import ExamSessionNoticeTable from '../components/table/ExamSessionNoticeTable';
import ScrollButtons from '../components/form/ScrollButtons';
import SearchFormByOptions from '../components/search/SearchFormByOptions';
import Routing from '../utils/Routing';
import { NOTICES_OFFSET_TIME_SEARCH_FIELD, NOTICES_SEARCH_OPTIONS } from '../constants/unresolvedNotices';
import { SEARCH_EXAM_SESSION_LABELS } from '../constants/search';

function UnresolvedNoticeReport(_props) {
  const location = useLocation();
  const uriParams = useParams();

  if(!uriParams.searchNumber) {
    uriParams.searchNumber = '4';
  }

  const canNavigate = (params) => {
    if(!params) { return false; }
    const { date, searchNumber, session } = params;
    return Boolean(date && searchNumber && session);
  }

  const getRoute = (params) => {
    const { date, searchNumber, session } = params;
    return `${Routing.UNRESOLVED_NOTICE_REPORT}/date/${date}/session/${session}/offset/${searchNumber}`;
  };

  const reportPage = (
    <>
      <Typography variant="h2">Unresolved notice report</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" py={2}>
        <SearchFormByOptions
          key={location.key}
          canNavigate={canNavigate}
          fieldsToShow={NOTICES_SEARCH_OPTIONS}
          getRoute={getRoute}
          labels={SEARCH_EXAM_SESSION_LABELS}
          searchFields={NOTICES_OFFSET_TIME_SEARCH_FIELD}
          uriParams={uriParams}
        />
      </Box>
      {canNavigate(uriParams) &&
        <ExamSessionNoticeTable
          key={location.key}
          date={uriParams.date}
          searchNumber={uriParams.searchNumber}
          session={uriParams.session}
        />
      }
    </>
  );

  return (
    <AuthConsumer>
      {({ capabilityContextAccess }) => (
        <ScrollButtons>
          <Can
            capabilityContextAccess={capabilityContextAccess}
            contextRequired={false}
            perform="MANAGE_ASSIGNMENTS"
            yes={() => (reportPage)}
          />
        </ScrollButtons>
      )}
    </AuthConsumer>
  );
}

export default UnresolvedNoticeReport;
