import NetworkService from './NetworkService';
import MOCK from './mock';
import mockRequest from '../utils/mockRequest';
import { getFullDayDatesObj, getSessionDatesObj, mapUrlParams } from '../utils/mapParams';
import { SHIFT_LOCATIONS } from '../constants/shift';

export default class ShiftService {

  static async getSupervisorShifts(searchData, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.SHIFTS);
    }
    const { supervisorId, fullDayFromDate, sessionTimes, fetchSlotCountDetails = false, location } = searchData;
    let params = { fetchSlotCountDetails };

    if (fullDayFromDate) {
      const dateTimes = getFullDayDatesObj(fullDayFromDate);
      params = {
        ...params,
        startDateTimeWindowStart: dateTimes.startDateTime,
        startDateTimeWindowEnd: dateTimes.endDateTime
      };
    }
    if (sessionTimes) {
      const dateTimes = getSessionDatesObj(sessionTimes);
      params = {
        ...params,
        startDateTimeWindowStart: dateTimes.startDateTime,
        startDateTimeWindowEnd: dateTimes.endDateTime
      };
    }
    if (supervisorId) {
      params = { ...params, supervisorId };
    }

    if (location && location !== SHIFT_LOCATIONS.ALL.value) {
      params = { ...params, location };
    }

    const response = await NetworkService.get(
      `/shifts?${mapUrlParams(params)}`,
      abortSignal
    );
    return await response.json();
  }

  static async getShiftAssignments(shiftId, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAMSESSIONS);
    }
    const response = await NetworkService.get(`/shift/${shiftId}/assignments`, abortSignal);
    return await response.json();
  }

  static async setShiftStatus(shiftId, status, abortSignal) {
    const statusObj = { status: status };
    return await NetworkService.patch(`/shift/${shiftId}`, statusObj, abortSignal).then(resp => { return resp });
  }

  static async saveShift(shift, abortSignal) {
    // add conditional for editing shift
    return await NetworkService.post('/shift', shift, undefined, abortSignal).then(resp => { return resp });
  }

  static async deleteShift(shiftId, abortSignal) {
    return await NetworkService.delete(`/shift/${shiftId}`, undefined, abortSignal).then(resp => { return resp });
  }

}
