import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { omit } from 'lodash';
import { monitoringContext } from '../context/MonitoringContext';
import { getSessionName } from '../../utils/getSessionTime';
import { getShiftLocation } from '../../utils/getShiftLocation';
import { ACTIONS } from '../../constants/monitorSessions';
import { SESSION_TIMES } from '../../constants/examSessions';
import SHIFT_STATUSES from '../../constants/shift';

function ViewShiftButton() {
  const styles = {
    textTransform: 'initial',
    fontWeight: 'normal',
    lineHeight: '1.3rem',
    textAlign: 'left',
    color: 'white',
    borderBottom: '1px solid #f5f6f6',
    margin: 0,
    padding: 2,
    background: 'black',
    justifyContent: 'flex-start',
    borderRadius: 0,
    '&:hover': {
      background: 'black',
    },
  };

  const { state, dispatch } = useContext(monitoringContext);

  const isShiftActive = SHIFT_STATUSES.ACTIVE.mapper === state.activeShift?.status
    || SHIFT_STATUSES.INACTIVE.mapper === state.activeShift?.status;

  const openShifts = () => {
    dispatch({ type: ACTIONS.SET_ACTIVE_CONNECTION, value: undefined })
  };

  const getShiftSessionLocation = () => {
    if (isShiftActive) {
      return getShiftLocation(state.activeShift.location);
    }

    return {};
  }

  const shiftLocation = getShiftSessionLocation();

  const shiftDisplay = isShiftActive
    ? `My session: ${shiftLocation?.display} ${getSessionName(state.activeShift.startTime, omit(SESSION_TIMES, 'all'), shiftLocation?.value)} session`
    : 'View my sessions';

  return (
    <Button
      fullWidth
      id='view-shift-button'
      sx={styles}
      variant="text"
      onClick={openShifts}
    >{shiftDisplay}</Button>
  );
}

export default (ViewShiftButton);
