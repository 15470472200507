import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { format as dateFnsFormat, isToday, isValid as dateFnsIsValid } from 'date-fns';

const styles = {
  chatMessageContainerSelf: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    wordBreak: 'break-word',
  },
  chatMessageContainerOther: {
    width: '100%',
    wordBreak: 'break-word',
  },
  chatTextSelf: {
    backgroundColor: 'primary.main',
    color: 'white',
    boxShadow: '3px 3px 10px 0 rgba(0, 0, 0, 0.31)',
    borderRadius: '25px',
    padding: 2,
  },
  chatTextOther: {
    backgroundColor: 'primary.contrastText',
    boxShadow: '3px 3px 10px 0 rgba(0, 0, 0, 0.31)',
    borderRadius: '25px',
    padding: 2,
  },
};

const ChatMessage = forwardRef((props, ref) => {

  const {
    displayName,
    fromSelf = false,
    text,
    timestamp = Math.round((new Date()).getTime() / 1000),
  } = props;

  const getMessageTime = () => {
    let messageTime = '';
    if (dateFnsIsValid(timestamp)) {
      const dateFormat = isToday(timestamp) ? 'HH:mm' : 'd MMM, HH:mm';
      messageTime = dateFnsFormat(timestamp, dateFormat);
    }
    return messageTime;
  }

  useEffect(() => {
    if(ref?.current) {
      ref.current.scrollIntoView({ behaviour: 'smooth', block: 'end' });
    }
  }, [ref])

  return (
    <Box display="flex" flexDirection="column" px={2} py={1.5} alignItems={fromSelf ? 'flex-end' : 'flex-start'}>
      <Box pl={2}><Typography variant="caption" color="textSecondary">{!fromSelf && displayName} {getMessageTime()}</Typography></Box>
      <Box sx={fromSelf ? styles.chatMessageContainerSelf : styles.chatMessageContainerOther} m={0} p={0}>
        <Box ref={ref} sx={fromSelf ? styles.chatTextSelf : styles.chatTextOther}>
          <div>{text}</div>
        </Box>
      </Box>
    </Box>
  );
});

ChatMessage.propTypes = {
  displayName: PropTypes.string,
  fromSelf: PropTypes.bool,
  text: PropTypes.string.isRequired,
  timestamp: PropTypes.object,
};

export default ChatMessage;
