import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const scrollBoxStyle = {
  overflowY: 'auto',
  width: '100%',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
    backgroundColor: 'transparent',
  },
  '&:hover::-webkit-scrollbar': {
    backgroundColor: '#eee',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccc',
    borderRadius: '8px',
  },
};

function ScrollableFadeBox(props) {
  const scrollRef = useRef();
  const [scrollBoxStyles, setScrollBoxStyles] = useState();
  const { pagePosition, resetPagePosition } = props;

  const calculateScrollboxStyles = () => {
    const scrollBox = scrollRef.current;

    const isScrollTop = scrollBox.scrollTop <= 1;
    const isScrollBottom = (scrollBox.scrollHeight - scrollBox.scrollTop - scrollBox.clientHeight) <= 1;
    const scrollTopShadow = 'inset 0px 11px 8px -10px #757575';
    const scrollBottomShadow = 'inset 0px -11px 8px -10px #757575';

    let combinedBoxShadow;
    if (!isScrollTop && !isScrollBottom) {
      combinedBoxShadow = `${scrollTopShadow}, ${scrollBottomShadow}`;
    }

    if (isScrollTop && !isScrollBottom) {
      combinedBoxShadow = scrollBottomShadow;
    }

    if (isScrollBottom && !isScrollTop) {
      combinedBoxShadow = scrollTopShadow;
    }

    return {
      width: '100%',
      minHeight: 0,
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
      '&:before': {
        content: '""',
        position: 'absolute',
        overflowY: 'hidden',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        boxShadow: combinedBoxShadow,
        pointerEvents: 'none',
        zIndex: 1,
      }
    };
  }

  const handleScroll = useCallback(() => {
    setScrollBoxStyles(calculateScrollboxStyles());
  }, []);

  useEffect(() => {
    if (pagePosition !== null) {
      scrollRef.current.scrollTop = pagePosition;
      resetPagePosition && resetPagePosition();
      // recalculate mask once a new step has loaded and scrolled to top
      setScrollBoxStyles(calculateScrollboxStyles());
    }
  }, [pagePosition, resetPagePosition]);

  useEffect(() => {
    if (scrollRef.current !== undefined) {
      setScrollBoxStyles(calculateScrollboxStyles());
    }
  }, []);

  return (
    <Box sx={{ ...scrollBoxStyles }}>
      <Box sx={{ ...scrollBoxStyle, ...props.sx }} onScroll={handleScroll} ref={scrollRef}>
        {props.children}
      </Box>
    </Box>
  );
}

ScrollableFadeBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  pagePosition: PropTypes.number,
  resetPagePosition: PropTypes.func,
  sx: PropTypes.object,
};

export default ScrollableFadeBox;
