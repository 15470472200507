import React from 'react';

const YellowFlag = ({ height = '16px', width = '16px', ...props }) => (
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={height}
    width={width}
    {...props}
  >
    <mask id="path-1-inside-1" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2677 6.43366C13.7644 6.28114 14.1095 5.82883 14.1266 5.30806C14.1437 4.7873 13.8289 4.31322 13.3433 4.12831L2.70987 0.0793539C2.33643 -0.0628436 1.91718 -0.0118734 1.58841 0.215693C1.25965 0.44326 1.06335 0.818362 1.06335 1.21905V8.5333C1.06335 8.53445 1.06336 8.53561 1.06336 8.53676V15.238C1.06336 15.6588 1.40341 15.9999 1.82289 15.9999C2.24236 15.9999 2.58242 15.6588 2.58242 15.238V9.71363C2.59978 9.70913 2.61708 9.70424 2.63432 9.69895L13.2677 6.43366Z" />
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.2677 6.43366C13.7644 6.28114 14.1095 5.82883 14.1266 5.30806C14.1437 4.7873 13.8289 4.31322 13.3433 4.12831L2.70987 0.0793539C2.33643 -0.0628436 1.91718 -0.0118734 1.58841 0.215693C1.25965 0.44326 1.06335 0.818362 1.06335 1.21905V8.5333C1.06335 8.53445 1.06336 8.53561 1.06336 8.53676V15.238C1.06336 15.6588 1.40341 15.9999 1.82289 15.9999C2.24236 15.9999 2.58242 15.6588 2.58242 15.238V9.71363C2.59978 9.70913 2.61708 9.70424 2.63432 9.69895L13.2677 6.43366Z" fill="#FFD14B" />
    <path d="M14.1266 5.30806L14.6263 5.32444L14.1266 5.30806ZM13.2677 6.43366L13.1209 5.95568H13.1209L13.2677 6.43366ZM13.3433 4.12831L13.5212 3.66104L13.3433 4.12831ZM2.70987 0.0793539L2.53195 0.546625V0.546625L2.70987 0.0793539ZM1.58841 0.215693L1.30384 -0.195426L1.30384 -0.195426L1.58841 0.215693ZM1.06336 8.53676H1.56336L1.56336 8.53535L1.06336 8.53676ZM2.58242 9.71363L2.45701 9.22961C2.23645 9.28676 2.08242 9.48579 2.08242 9.71363H2.58242ZM2.63432 9.69895L2.48754 9.22097L2.48754 9.22097L2.63432 9.69895ZM13.6269 5.29169C13.6168 5.59983 13.4127 5.8661 13.1209 5.95568L13.4145 6.91163C14.1161 6.69617 14.6023 6.05783 14.6263 5.32444L13.6269 5.29169ZM13.1653 4.59558C13.4507 4.70424 13.637 4.98351 13.6269 5.29169L14.6263 5.32444C14.6504 4.59108 14.2071 3.92221 13.5212 3.66104L13.1653 4.59558ZM2.53195 0.546625L13.1653 4.59558L13.5212 3.66104L2.8878 -0.387917L2.53195 0.546625ZM1.87298 0.626813C2.06635 0.492964 2.31262 0.46311 2.53195 0.546625L2.8878 -0.387917C2.36024 -0.588798 1.768 -0.51671 1.30384 -0.195426L1.87298 0.626813ZM1.56335 1.21905C1.56335 0.982032 1.67948 0.760754 1.87298 0.626813L1.30384 -0.195426C0.839814 0.125766 0.563354 0.654691 0.563354 1.21905H1.56335ZM1.56335 8.5333V1.21905H0.563354V8.5333H1.56335ZM1.56336 8.53535C1.56336 8.53467 1.56335 8.53398 1.56335 8.5333H0.563354C0.563354 8.53492 0.563357 8.53655 0.563361 8.53817L1.56336 8.53535ZM1.56336 15.238V8.53676H0.563359V15.238H1.56336ZM1.82289 15.4999C1.68102 15.4999 1.56336 15.3842 1.56336 15.238H0.563359C0.563359 15.9335 1.1258 16.4999 1.82289 16.4999V15.4999ZM2.08242 15.238C2.08242 15.3842 1.96475 15.4999 1.82289 15.4999V16.4999C2.51998 16.4999 3.08242 15.9335 3.08242 15.238H2.08242ZM2.08242 9.71363V15.238H3.08242V9.71363H2.08242ZM2.48754 9.22097C2.4774 9.22409 2.46722 9.22697 2.45701 9.22961L2.70782 10.1976C2.73234 10.1913 2.75677 10.1844 2.7811 10.1769L2.48754 9.22097ZM13.1209 5.95568L2.48754 9.22097L2.78109 10.1769L13.4145 6.91163L13.1209 5.95568Z" fill="#FFC620" mask="url(#path-1-inside-1)" />
  </svg>

);

export default YellowFlag;
