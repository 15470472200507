import React, { useCallback, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Typography } from '@mui/material';
import ImageFromData from './ImageFromData'
import { monitoringContext } from '../context/MonitoringContext';
import Notice from '../notification/Notice';
import RefreshButton from '../form/RefreshButton';
import UserIdentityText from './UserIdentityText';
import OnboardService from '../../services/OnboardService';
import getIdentityProgressIds from '../step/helper/getIdentityProgressIds';
import { ACTIONS } from '../../constants/monitorSessions';
import { ADDITIONAL_PATH_PARAMS } from '../../constants/joinSession';
import { NOTICE_TYPES } from '../../constants/noticeTypes';

function IdVerificationView(props) {
  const { state, dispatch } = useContext(monitoringContext);
  const { examSlotId, user, withContextText = true } = props;
  const controllerRef = useRef(new AbortController());
  const onboardIdentity = state.onboardIdentity?.[examSlotId];
  const idCaptureIds = onboardIdentity?.identityCaptureProgressIds?.idCaptureIds;
  const faceCaptureIds = onboardIdentity?.identityCaptureProgressIds?.faceCaptureIds;

  const idImageServiceFunc = async (progressId, additionalPathParam, controllerSignal) => {
    return OnboardService.getOnboardProgressImage({
      onboardAssignmentId: onboardIdentity?.onboardAssignmentId,
      onboardProgressId: progressId,
      additionalPathParam: additionalPathParam,
    }, controllerSignal);
  };

  const getOnboardIdentity = useCallback(async () => {
    try {
      const flowAssignment = await OnboardService.getExamSessionFlowAssignment(examSlotId, controllerRef.current?.signal);
      const flow = flowAssignment?.id &&
        await OnboardService.getOnboardFlowFromFlowAssignment(flowAssignment.id, controllerRef.current?.signal);
      const onboardIdentityDetails = {
        onboardAssignmentId: flowAssignment?.id,
        identityCaptureProgressIds: getIdentityProgressIds(flowAssignment, flow),
      };
      dispatch({ type: ACTIONS.SET_ONBOARD_IDENTITY, value: { slotId: examSlotId, onboardDetails: onboardIdentityDetails } });
    } catch (err) {
      // if it's empty, it will show a message
      console.error(err);
    }
  }, [dispatch, examSlotId]);

  const refreshIdentityInfo = () => {
    dispatch({ type: ACTIONS.SET_ONBOARD_IDENTITY, value: { slotId: examSlotId, onboardDetails: {} } });
  };

  useEffect(() => {
    if (isEmpty(state.onboardIdentity?.[examSlotId])) {
      getOnboardIdentity();
    }
  }, [examSlotId, getOnboardIdentity, state.onboardIdentity]);

  return (
    <>
      {withContextText &&
        <>
          <Typography variant="h2">ID verification details</Typography>
          <Typography variant="body1">View or confirm ID verification details</Typography>
        </>
      }
      {!isEmpty(user) &&
        <>
          <Typography variant="h5" component={withContextText ? 'h3' : 'h2'} sx={{ mt: 1 }}>
            Student details
          </Typography>
          <UserIdentityText sx={{ display: 'flex' }} user={user} />
        </>
      }
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" component={withContextText ? 'h3' : 'h2'} sx={{ my: 1 }}>
          Captured photos
        </Typography>
        <RefreshButton refreshCallback={refreshIdentityInfo} disabled={false} />
      </Box>
      {isEmpty(idCaptureIds) && isEmpty(faceCaptureIds)
        ?
          <Notice noticeType={NOTICE_TYPES.notice}>No images available</Notice>
        :
          <Box display="flex" gap={2} justifyContent="space-evenly">
            {!isEmpty(idCaptureIds) &&
              <>
                {idCaptureIds.map(idCaptureId => {
                  return (
                    <ImageFromData
                    imageAlt="Student's ID capture"
                    imageText="Photo of ID"
                    key={`${idCaptureId}-id-photo`}
                    serviceFunc={(controllerSignal) =>
                      idImageServiceFunc(idCaptureId, ADDITIONAL_PATH_PARAMS.idVerificationId, controllerSignal)}
                    />
                  )
                })}
              </>
            }
            {!isEmpty(faceCaptureIds) &&
              <>
                {faceCaptureIds.map(faceCaptureId => {
                  return (
                    <ImageFromData
                      imageAlt="Student's face capture"
                      imageText="Photo of student"
                      key={`${faceCaptureId}-student-photo`}
                      serviceFunc={(controllerSignal) =>
                        idImageServiceFunc(faceCaptureId, ADDITIONAL_PATH_PARAMS.idVerificationFace, controllerSignal)}
                    />
                  )
                })}
              </>
              }
          </Box>
      }
    </>
  )
}
IdVerificationView.propTypes = {
  examSlotId: PropTypes.string,
  user: PropTypes.object,
  withContextText: PropTypes.bool,
};

export default IdVerificationView;