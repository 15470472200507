import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import FlowAssignmentProgressList from '../content/FlowAssignmentProgressList';
import Notice from '../notification/Notice';
import NotificationBar from '../notification/NotificationBar';
import RefreshButton from './RefreshButton';
import ScrollableFadeBox from '../container/ScrollableFadeBox';
import SkipCompleteOnboardStepConfirmationForm from './SkipCompleteOnboardStepConfirmationForm';
import WarningPopup from '../popup/WarningPopup';
import mapSortedOnboardStepsAndProgress from '../step/helper/mapSortedOnboardStepsAndProgress';
import GatewayService from '../../services/GatewayService';
import OnboardService from '../../services/OnboardService';
import { CanThey } from '../Can';
import { authContext } from '../../authContext';
import { monitoringContext } from '../context/MonitoringContext';
import { transparentScrollbarStyle } from '../../config/theme';
import { getActiveStepFromMappedAssignmentDetails } from '../step/helper/getActiveStepFromMappedAssignmentDetails';
import { getIdentityProgressIdsFromPreMappedData } from '../step/helper/getIdentityProgressIds';
import { ACTIONS } from '../../constants/monitorSessions';
import { NOTICE_TYPES } from '../../constants/noticeTypes';

function OnboardProgressForm(props) {
  const [onboardFlowAssignmentDetails, setOnboardFlowAssignmentDetails] = useState({
    id: undefined,
    details: [],
  });
  const [activeStepProgressDetails, setActiveStepProgressDetails] = useState();
  const [flowAssignmentGetRequestPending, setFlowAssignmentGetRequestPending] = useState(false);
  const [flowAssignmentGetRequestMessage, setFlowAssignmentGetRequestMessage] = useState({});

  const [skipCompleteStepPopupOpen, setSkipCompleteStepPopupOpen] = useState(false);
  const [stepUpdateType, setStepUpdateType] = useState();
  const [progressUpdateNotification, setProgressUpdateNotification] = useState('');

  const controllerRef = useRef(new AbortController());
  const { capabilityContextAccess } = useContext(authContext);
  const { dispatch } = useContext(monitoringContext);

  const fetchProgressData = useCallback(async () => {
    if (isEmpty(props.slotId)) {
      return;
    }
    const controller = controllerRef.current;
    try {
      setFlowAssignmentGetRequestPending(true);
      const flowAssignment = await OnboardService.getExamSessionFlowAssignment(props.slotId, controller?.signal);
      const hasNotStartedOnboarding = !isEmpty(flowAssignment) && isEmpty(flowAssignment?.onboardSlotProgress);
      if (hasNotStartedOnboarding) {
        setOnboardFlowAssignmentDetails({ id: flowAssignment.id, details: [] });
        setFlowAssignmentGetRequestPending(false);
        setFlowAssignmentGetRequestMessage({ type: NOTICE_TYPES.notice, message: 'Student has not started onboarding yet' });
        return;
      }

      const flow = await OnboardService.getOnboardFlowFromFlowAssignment(flowAssignment.id, controller?.signal);

      const flowAssignmentDetails = mapSortedOnboardStepsAndProgress(flow.onboardFlowSteps, flowAssignment.onboardSlotProgress);
      const currentActiveStepDetails = getActiveStepFromMappedAssignmentDetails(flowAssignmentDetails);
      const onboardIdentityDetails = {
        onboardAssignmentId: flowAssignment?.id,
        identityCaptureProgressIds: getIdentityProgressIdsFromPreMappedData(flowAssignmentDetails),
      };

      setOnboardFlowAssignmentDetails({ id: flowAssignment.id, details: flowAssignmentDetails });
      setFlowAssignmentGetRequestPending(false);
      setFlowAssignmentGetRequestMessage({});
      setActiveStepProgressDetails(currentActiveStepDetails);
      dispatch({ type: ACTIONS.SET_ONBOARD_IDENTITY, value: { slotId: props.slotId, onboardDetails: onboardIdentityDetails } });
    } catch (err) {
      if (!controller?.signal?.aborted) {
        console.error(err);
        setFlowAssignmentGetRequestMessage({ type: NOTICE_TYPES.error, message: 'We were unable to retrieve the onboarding data for the specified slot. Please try again.' });
        setFlowAssignmentGetRequestPending(false);
      }
    }
  }, [dispatch, props.slotId]);

  useEffect(() => {
    const controller = controllerRef.current;
    return () =>  { controller.abort() };
  }, []);

  useEffect(() => { fetchProgressData(); }, [fetchProgressData]);

  const handleSkipCompleteStepPopupClose = async (skipConfirmed, skipStepResponseContent) => {
    if (skipConfirmed) {
      const updatedAssignmentDetails = onboardFlowAssignmentDetails.details.map(assignmentDetail =>
        assignmentDetail.progress.id === skipStepResponseContent.id ? { ...assignmentDetail, progress: skipStepResponseContent } : assignmentDetail);
      const previousActiveStepName = activeStepProgressDetails?.flowStep?.onboardStep?.displayName || activeStepProgressDetails?.flowStep?.onboardStep?.name;
      const updatedActiveStepDetails = getActiveStepFromMappedAssignmentDetails(updatedAssignmentDetails);

      setOnboardFlowAssignmentDetails({ ...onboardFlowAssignmentDetails, details: updatedAssignmentDetails });
      setSkipCompleteStepPopupOpen(false);
      setProgressUpdateNotification(`'${previousActiveStepName}' ${stepUpdateType === 'skip' ? 'skipped' : 'completed'}`);
      setActiveStepProgressDetails(updatedActiveStepDetails);

      props.onSkipRequestComplete && props.onSkipRequestComplete();
    } else {
      setSkipCompleteStepPopupOpen(false);
    }
  }

  const handleStepUpdateButtonClick = (type) => () => {
    setStepUpdateType(type);
    setSkipCompleteStepPopupOpen(true);
  }

  const slotHasOnboardingDetails = !isEmpty(onboardFlowAssignmentDetails.details);
  const confirmationPopupTitle = stepUpdateType === 'skip' ? 'Skip onboarding step' : 'Complete onboarding step' ;

  const isActiveStepSkippable = activeStepProgressDetails?.flowStep?.onboardStep?.skippable
  const skipByCapability = activeStepProgressDetails?.flowStep?.onboardStep?.capability?.key;
  const hasPermissionToSkipActiveStep = isActiveStepSkippable && CanThey(capabilityContextAccess, true, skipByCapability, { id: props.slotContextId });
  const isRefreshLocked = flowAssignmentGetRequestPending;
  const isSkipLocked = isRefreshLocked || !hasPermissionToSkipActiveStep;

  const hasExternalCompletableStep = onboardFlowAssignmentDetails.details?.find(details => details.flowStep.onboardStep.externalCompletable !== undefined) !== undefined;

  const completeByCapabiltiy = activeStepProgressDetails?.flowStep?.onboardStep?.externalCompleteByCapability?.key;
  const isActiveStepExternallyCompletable = activeStepProgressDetails?.flowStep?.onboardStep?.externalCompletable;
  const hasPermissionToCompleteStep = isActiveStepExternallyCompletable && CanThey(capabilityContextAccess, true, completeByCapabiltiy, { id: props.slotContextId });
  const isCompleteLocked = isRefreshLocked || !hasPermissionToCompleteStep

  const hasNoticeMessage = !isEmpty(flowAssignmentGetRequestMessage?.message) && !isEmpty(flowAssignmentGetRequestMessage?.type);

  return (
    <Box display="flex" flexDirection="column" maxHeight="100%">
      <ScrollableFadeBox sx={{ ...transparentScrollbarStyle, minHeight: 0, px: 2, pt: 2 }} pagePosition={slotHasOnboardingDetails ? 0 : null}>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" pb={1} justifyContent="space-between">
          <Typography variant="h5" component="h3">Onboarding status</Typography>
          <RefreshButton refreshCallback={fetchProgressData} disabled={isRefreshLocked} />
        </Box>
        {hasNoticeMessage &&
          <Notice noticeType={flowAssignmentGetRequestMessage.type}>{flowAssignmentGetRequestMessage.message}</Notice>
        }
        {slotHasOnboardingDetails &&
          <Box display="flex">
            <FlowAssignmentProgressList onboardFlowAssignmentDetails={onboardFlowAssignmentDetails.details} activeStepProgressDetails={activeStepProgressDetails} />
          </Box>
        }
      </ScrollableFadeBox>
      {slotHasOnboardingDetails &&
        <>
          <Box sx={{ p: 2, mb: 0, borderRadius: 0, flexGrow: 1 }} >
            <Button
              aria-label="Skip onboarding step"
              startIcon={<KeyboardArrowRight fontSize='small' />}
              fullWidth
              variant="outlined"
              sx={{ justifyContent: 'center', textTransform: 'initial', mb: 1 }}
              disabled={isSkipLocked}
              onClick={handleStepUpdateButtonClick('skip')}
            >
              Skip onboarding step
            </Button>
            {hasExternalCompletableStep &&
              <Button
                aria-label="Complete step"
                variant="outlined"
                fullWidth
                sx={{ justifyContent: 'center', textTransform: 'initial' }}
                disabled={isCompleteLocked}
                onClick={handleStepUpdateButtonClick('complete')}
              >
                Complete step
              </Button>
            }
          </Box>
          <WarningPopup
            open={skipCompleteStepPopupOpen}
            title={confirmationPopupTitle}
            onClose={() => handleSkipCompleteStepPopupClose(false)}
            content={
              <SkipCompleteOnboardStepConfirmationForm
                flowAssignmentId={onboardFlowAssignmentDetails?.id}
                gatewayService={props.gatewayService}
                stepDetails={activeStepProgressDetails}
                onClose={handleSkipCompleteStepPopupClose}
                type={stepUpdateType}
              />
            }
          />
          <NotificationBar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            show={!isEmpty(progressUpdateNotification)}
            message={progressUpdateNotification}
            onClose={() => setProgressUpdateNotification('')}
          />
        </>
      }
    </Box>
  )
}

OnboardProgressForm.propTypes = {
  gatewayService: PropTypes.instanceOf(GatewayService),
  onSkipRequestComplete: PropTypes.func,
  slotId: PropTypes.string.isRequired,
  slotContextId: PropTypes.string.isRequired,
};

export default OnboardProgressForm;
