import React from 'react';
import PropTypes from 'prop-types';
import { darken, lighten } from '@mui/material/styles';
import { Box, Button, Chip, TableCell, TableRow, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import MUIDataTable from 'mui-datatables';
import { find, findIndex, get, isEmpty, reject, sortBy, without } from 'lodash';
import { authContext } from '../../authContext';
import AppTheme from '../AppTheme';
import DeleteButton from '../form/DeleteButton';
import Notice from '../notification/Notice';
import RequestStatusIndicator from '../notification/RequestStatusIndicator';
import ShiftAssignmentsWrapper from '../container/ShiftAssignmentsWrapper';
import StartEndShiftButton from '../form/StartEndShiftButton';
import ShiftService from '../../services/ShiftService';
import { getSessionName, getSessionStartEndTimesAtLocation } from '../../utils/getSessionTime';
import { buttonLinkStyle, themeObject } from '../../config/theme';
import { FEATURE_TOGGLES } from '../../constants/featureToggles';
import { MSG_404 } from '../../constants/login';
import { SESSION_TIMES } from '../../constants/examSessions';
import SHIFT_STATUSES, { SHIFT_FIELDS, SHIFT_LOCATIONS } from '../../constants/shift';

const getStyle = (styleName, theme = themeObject) => {
  const baseThemePrimaryMain = themeObject?.palette?.primary?.main;
  const styles = {
    active: {
      color: darken(theme?.palette?.success?.main, 0.1),
      backgroundColor: lighten(theme?.palette?.success?.main, 0.95),
    },
    inactive: {
      color: darken(theme?.palette?.warning?.main, 0.1),
      backgroundColor: lighten(theme?.palette?.warning?.main, 0.95),
    },
    notStarted: {
      color: baseThemePrimaryMain,
      backgroundColor: lighten(baseThemePrimaryMain, 0.95),
    },
    finished: {
      color: 'error.main',
      backgroundColor: lighten(theme?.palette?.error?.main, 0.95),
    },
    requestStatusIndicatorContainer: {
      position: 'fixed',
      left: '50%',
      top: '50%',
      zIndex: 2,
      transform: 'translate(-50%, 0)',
    },
  }

  return styles[styleName];
};

class ShiftTable extends React.Component {

  state = {
    shiftList: undefined,
    tableData: undefined,
    openedAssignmentsShift: undefined,
    isRequestPending: true,
    hasRequestErrored: false,
    errorMessage: '',
    endShiftError: '',
  }

  controller = new AbortController();

  componentDidMount() {
    this.loadShiftData();
  }

  componentDidUpdate(prevProps) {
    const { hasShiftUpdated } = this.props;
    if (prevProps.hasShiftUpdated !== hasShiftUpdated && hasShiftUpdated) {
      this.loadShiftData();
    }
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  getDefaultLocationShifts = async (searchData) => {
    const shifts = await ShiftService.getSupervisorShifts(searchData, this.controller.signal);

    const defaultLocation = Object.values(SHIFT_LOCATIONS).find(sl => sl.default === true);
    return shifts.filter(shift => shift.location === defaultLocation.value || !shift.location)
      .map(shift => ({...shift, location: defaultLocation.value}));
  }

  getShifts = async () => {
    const { date, session, location } = this.props;
    const { features } = this.context;

    const sessionStartWindow = getSessionStartEndTimesAtLocation(date, session, location);
    const commonSearchData = {
      fetchSlotCountDetails: features[FEATURE_TOGGLES.FETCH_SHIFT_ALLOCATED_SLOTS],
      sessionTimes: sessionStartWindow,
    }

    const defaultLocation = Object.values(SHIFT_LOCATIONS).find(sl => sl.default === true);
    if (location === defaultLocation.value) {
      return this.getDefaultLocationShifts(commonSearchData);
    }

    const searchData = {
      ...commonSearchData,
      location,
    }

    return ShiftService.getSupervisorShifts(searchData, this.controller.signal);
  }

  loadShiftData = async () => {
    this.setState({
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: '',
    });

    try {
      const shifts = await this.getShifts();

      this.setState({
        shiftList: shifts,
        tableData: this.processData(shifts),
        isRequestPending: false,
        hasRequestErrored: false,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        if (error.message === MSG_404) {
          this.setState({
            shiftList: [],
            isRequestPending: false,
          });
        } else {
          this.setState({
            isRequestPending: false,
            hasRequestErrored: true,
            errorMessage: error.message,
          });
        }
      }
    }
  };

  getColumns = (tableData) => {
    if (isEmpty(tableData)) { return; }
    return (
      without(Object.keys(SHIFT_FIELDS).map((heading) => {
        if (SHIFT_FIELDS[heading].toggle && !this.context.features[SHIFT_FIELDS[heading].toggle]) {
          return undefined;
        }
        const label = SHIFT_FIELDS[heading].display;
        let stdOptions = {
          customFilterListOptions: {
            render: v => {
              return `${label}: ${v}`
            }
          }
        };
        if (heading === 'id') {
          return {
            name: heading,
            label: label,
            options: { display: 'excluded', filter: false, download: true }
          }
        }
        if (heading === 'status') {
          return {
            name: heading,
            label: label,
            options: {
              customBodyRenderLite: (dataIndex) => {
                const status = tableData[dataIndex].status
                return (<Chip sx={(theme) => { return this.getStatusClass(status, theme) }} label={status} size="small" />);
              },
              ...stdOptions,
            }
          }
        }
        if (heading === 'staffName') {
          return {
            name: heading,
            label: label,
            options: {
              customBodyRenderLite: (dataIndex) => {
                return this.displayStaffName(
                  tableData[dataIndex].staffName,
                  tableData[dataIndex].id,
                  tableData[dataIndex].allocationCount
                )
              },
              ...stdOptions,
            }
          }
        }
        if (heading === 'action') {
          if (!this.props.canEdit && !this.props.canManageAssignments) { return undefined; }
          return {
            name: heading,
            label: label,
            options: {
              filter: false,
              download: false,
              customBodyRenderLite: (dataIndex) => {
                const row = tableData[dataIndex];
                if (row.action === 'canDelete') {
                  return (
                    <DeleteButton
                      key={row.id}
                      buttonAriaLabel={`delete session for ${row.staffName}, ${row.shiftSession}`}
                      deleteServiceCallback={ShiftService.deleteShift}
                      itemId={row.id}
                      removeItem={this.removeShift}
                    />
                  )
                }
                if (row.action === 'canManageAssignments') {
                  return (
                    <Box display="flex" justifyContent="space-between" alignItems='center'>
                      <StartEndShiftButton
                        key={row.id}
                        isAdmin={true}
                        setShiftUpdated={this.props.setHasShiftUpdated}
                        setShiftUpdateError={this.setEndShiftError}
                        shift={find(this.state.shiftList, { 'id': row.id })}
                      />
                      {this.state.endShiftError &&
                        <Tooltip title={this.state.endShiftError}>
                          <CancelIcon color="error" />
                        </Tooltip>
                      }
                    </Box>
                  )
                }
              },
            },
          }
        }
        return { name: heading, label: label, options: stdOptions }
      }), undefined)
    )
  }

  processData = (shiftList) => {
    if (isEmpty(shiftList)) { return; }
    return (
      sortBy(shiftList.map((shift) => {
        let shiftDisplayObj = {};
        Object.keys(SHIFT_FIELDS).forEach(field => {
          if (field === 'shiftSession') {
            shiftDisplayObj[field] = getSessionName(shift.startTime, SESSION_TIMES, shift.location);
          } else if (field === 'status') {
            shiftDisplayObj[field] = SHIFT_STATUSES[get(shift, SHIFT_FIELDS[field].mapper)].display;
          } else if (field === 'action') {
            shiftDisplayObj[field] = this.getAction(shift);
          } else {
            shiftDisplayObj[field] = get(shift, SHIFT_FIELDS[field].mapper);
          }
        });
        return shiftDisplayObj;
      }), ['shiftSession', 'staffName'])
    );
  };

  getStatusClass = (status, theme) => {
    const statusObj = find(SHIFT_STATUSES, { display: status });
    return statusObj ? getStyle(statusObj.class, theme) : undefined;
  };

  displayStaffName = (name, shiftId, allocationCount) => {
    const { canManageAssignments, canViewExam } = this.props;
    const { openedAssignmentsShift } = this.state;
    return (
      <>
        {(allocationCount > 0 || openedAssignmentsShift === shiftId)
          && (canManageAssignments || canViewExam)
          ?
            <Button
              sx={{ ...buttonLinkStyle }}
              variant='text'
              color='primary'
              size='small'
              onClick={(e) => this.toggleShiftAssignments(shiftId, e)}
              aria-label={`Toggle session assignment view for ${name}`}
            >
              {name}
            </Button>
          : <Box px='5px'>{name}</Box>
        }
      </>
    )
  };

  toggleShiftAssignments = (id) => {
    const { openedAssignmentsShift } = this.state;
    const newShiftId = id === openedAssignmentsShift ? undefined : id;
    this.setState({
      openedAssignmentsShift: newShiftId,
    });
  };

  getAction = (shift) => {
    const { canEdit, canManageAssignments } = this.props;
    if (canEdit && shift.status === SHIFT_STATUSES.NOT_STARTED.mapper) {
      return 'canDelete';
    }
    if (canManageAssignments && (shift.status === SHIFT_STATUSES.ACTIVE.mapper
      || shift.status === SHIFT_STATUSES.INACTIVE.mapper)) {
      return 'canManageAssignments';
    }
  };

  removeShift = (shiftId) => {
    setTimeout(() => {
      const updatedShiftList = reject(this.state.shiftList, { 'id': shiftId });
      this.setState({ shiftList: updatedShiftList, tableData: this.processData(updatedShiftList) });
    }, 3000);
  };

  setEndShiftError = (error) => {
    this.setState({ endShiftError: error })
  };

  render() {
    const {
      shiftList,
      tableData,
      openedAssignmentsShift,
      isRequestPending,
      hasRequestErrored,
      errorMessage,
    } = this.state;

    const { canManageAssignments, setHasShiftUpdated } = this.props;
    const displayData = !isRequestPending && !hasRequestErrored && shiftList.length > 0;

    const columns = this.getColumns(tableData);

    const openedIndex = findIndex(tableData, ['id', openedAssignmentsShift]);

    const options = {
      responsive: 'simple',
      filterType: 'multiselect',
      pagination: false,
      print: false,
      selectToolbarPlacement: 'none',
      selectableRows: 'none',
      expandableRows: true,
      expandableRowsHeader: false,
      rowsExpanded: [openedIndex],
      renderExpandableRow: (rowData, _rowMeta) => {
        const colSpan = rowData.length + 1;
        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <ShiftAssignmentsWrapper
                canManageAssignments={canManageAssignments}
                setHasShiftUpdated={setHasShiftUpdated}
                shiftId={openedAssignmentsShift}
              />
            </TableCell>
          </TableRow>
        );
      },
    };

    // remove this theme override and ThemeProvider if we make rows selectable
    // (ExpandButton will still not be shown, but this hides the column that is shared by select/expand)
    const themeObj = {
      ...themeObject,
      components: {
        ...themeObject.components,
        MUIDataTableSelectCell: {
          styleOverrides: {
            root: {
              display: 'none',
            },
          },
        },
      }
    };

    const components = {
      ExpandButton: function (_props) {
        return <></>;
      }
    };

    return (
      <>
        <div style={getStyle('requestStatusIndicatorContainer')}>
          <RequestStatusIndicator
            isPending={isRequestPending}
            isErrored={hasRequestErrored}
            errorMessage={errorMessage}
          />
        </div>
        {!displayData &&
          <Notice noticeType="notice">There are no sessions that match the search criteria</Notice>
        }
        {hasRequestErrored &&
          <Notice noticeType="error">{errorMessage}</Notice>
        }
        {displayData &&
          <AppTheme themeObjOverride={themeObj}>
            <MUIDataTable
              columns={columns}
              data={tableData}
              options={options}
              components={components}
            />
          </AppTheme>
        }
      </>
    )
  }
}

ShiftTable.propTypes = {
  canEdit: PropTypes.bool,
  canManageAssignments: PropTypes.bool,
  canViewExam: PropTypes.bool,
  date: PropTypes.string,
  hasShiftUpdated: PropTypes.bool,
  session: PropTypes.string,
  setHasShiftUpdated: PropTypes.func.isRequired,
};

export default ShiftTable;
ShiftTable.contextType = authContext;
