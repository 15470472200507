import React from 'react';

const RestBreak = ({ height = '16px', width = '16px', ...props }) => (
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={height}
    width={width}
    {...props}
  >
    <title>Group 9</title>
    <g id="RestBreak" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="RestBreak-Artboard" transform="translate(-633.000000, -301.000000)">
            <g id="RestBreak-Group-9" transform="translate(633.000000, 301.000000)">
                <g id="RestBreak-Icon-Break-Copy" transform="translate(2.000000, 0.000000)">
                    <path d="M9.9966091,3.89671744 C10.3961078,4.71695855 10.395218,5.25196778 10.4148234,5.83932876 L10.4148234,5.83932876 L11.9674797,5.83929976 L12.4112466,8.07313368 L10.0571338,9.3431191 C9.75790317,10.7452476 9.08733595,12.1142588 8.38496752,13.451897 L8.38496752,13.451897 L2.44565989,13.451897 C1.61217939,11.8003657 0.900163503,10.1935364 0.630521021,8.62960999 C0.380568335,7.17988441 0.357010631,5.69227947 0.559298178,4.16680446 L0.559298178,4.16680446 Z M11.4481514,6.34078591 L10.3301168,6.34078591 C10.3474869,7.04813816 10.259257,7.76893253 10.0661238,8.5032474 L11.9320154,7.99655477 L11.4481514,6.34078591 Z" id="RestBreak-Shape" stroke="#333333" strokeWidth="0.5"></path>
                    <path d="M10.4823101,14.6952427 L0.556681006,15.3350134 C0.903190236,15.6068556 1.24825505,15.75 1.5962087,15.75 L9.20905574,15.7491148 C9.53389944,15.7378901 9.86458547,15.5955679 10.1967065,15.3350134 C10.4083886,15.1689452 10.4609306,14.9402035 10.4823101,14.6952427 Z" id="RestBreak-Saucer" stroke="#333333" strokeWidth="0.5"></path>
                    <g id="RestBreak-Clock" transform="translate(2.428184, 5.550136)">
                        <circle id="RestBreak-Oval" stroke="#333333" strokeWidth="0.5" cx="3.03523035" cy="3.03523035" r="2.78523035"></circle>
                        <circle id="RestBreak-Oval2" fill="#333333" cx="3.03523035" cy="3.03523035" r="1"></circle>
                        <rect id="RestBreak-Rectangle" fill="#333333" transform="translate(3.035230, 1.907859) rotate(-90.000000) translate(-3.035230, -1.907859) " x="1.99457995" y="1.64769648" width="2.08130081" height="1"></rect>
                        <rect id="RestBreak-Rectangle2" fill="#333333" x="3.12195122" y="2.77506775" width="1.38753388" height="1"></rect>
                    </g>
                    <g id="RestBreak-Waves" transform="translate(2.601626, 0.000000)" fill="#333333" fillRule="nonzero">
                        <path d="M0.327228878,2.8202774 C0.392269528,2.88531805 0.477635816,2.91783838 0.563005572,2.91783838 C0.648375328,2.91783838 0.733738146,2.88531805 0.798782266,2.8202774 C0.928863566,2.6901961 0.928863566,2.47880531 0.798782266,2.34872401 C0.62263484,2.17257659 0.62263484,1.88598146 0.815719892,1.69290613 C1.25202992,1.2565961 1.25202992,0.547236749 0.798105149,0.0926286192 C0.666669268,-0.0374526816 0.465443902,-0.0272907304 0.335362602,0.102791264 C0.205959458,0.232872565 0.214766829,0.452380424 0.344170146,0.582461725 C0.520317572,0.75860915 0.520317572,1.04520427 0.32723252,1.23827961 C-0.109077507,1.67391322 -0.109077507,2.38394899 0.32723252,2.82025901 L0.327228878,2.8202774 Z" id="RestBreak-Path"></path>
                        <path d="M2.58197143,2.8202774 C2.64701208,2.88531805 2.73237836,2.91783838 2.81774812,2.91783838 C2.90311788,2.91783838 2.98848069,2.88531805 3.05352481,2.8202774 C3.18360611,2.6901961 3.18360611,2.47880531 3.05352481,2.34872401 C2.87737739,2.17257659 2.87737739,1.88598146 3.07046244,1.69290613 C3.50677247,1.2565961 3.50677247,0.547236749 3.0528477,0.0926286192 C2.92141182,-0.0374526816 2.72018645,-0.0272907304 2.59010515,0.102791264 C2.46070201,0.232872565 2.46950938,0.452380424 2.59891269,0.582461725 C2.77506012,0.75860915 2.77506012,1.04520427 2.58197507,1.23827961 C2.14566504,1.67391322 2.14566504,2.38394899 2.58197507,2.82025901 L2.58197143,2.8202774 Z" id="RestBreak-Path2"></path>
                        <path d="M4.83671397,2.8202774 C4.90175462,2.88531805 4.98712091,2.91783838 5.07249067,2.91783838 C5.15786042,2.91783838 5.24322324,2.88531805 5.30826736,2.8202774 C5.43834866,2.6901961 5.43834866,2.47880531 5.30826736,2.34872401 C5.13211993,2.17257659 5.13211993,1.88598146 5.32520499,1.69290613 C5.76151501,1.2565961 5.76151501,0.547236749 5.30759024,0.0926286192 C5.17615436,-0.0374526816 4.974929,-0.0272907304 4.8448477,0.102791264 C4.71544455,0.232872565 4.72425192,0.452380424 4.85365524,0.582461725 C5.02980267,0.75860915 5.02980267,1.04520427 4.83671762,1.23827961 C4.40040759,1.67391322 4.40040759,2.38394899 4.83671762,2.82025901 L4.83671397,2.8202774 Z" id="RestBreak-Path3"></path>
                    </g>
                </g>
                <rect id="RestBreak-Rectangle3" x="0" y="0" width="16" height="16"></rect>
            </g>
        </g>
    </g>
  </svg>
);

export default RestBreak;
