const CAPABILITIES = [
    {
      "name": "Manage user",
      "description": "To add or edit user in the system",
      "key": "MANAGE_USER",
      "id": "b91417d2-65a4-4356-9cc4-ef25449aad54"
    },
    {
      "name": "Manage context",
      "description": "To add or edit context in the system",
      "key": "MANAGE_CONTEXT",
      "id": "982b9189-fd51-4f97-855d-1e78022ac2f5"
    },
    {
      "name": "Manage role",
      "description": "To add or edit role in the system",
      "key": "MANAGE_ROLE",
      "id": "20e9792e-0a6f-4ac5-87cc-9fef2bfa9201"
    },
    {
      "name": "Manage role assignment",
      "description": "Allows user to manage role assignments",
      "key": "MANAGE_ROLE_ASSIGNMENT",
      "id": "8c68a8fe-e9c2-4e6e-bac3-ec5540fff3d0"
    },
    {
      "name": "Manage flag",
      "description": "Allows user to manage flags",
      "key": "MANAGE_FLAG",
      "id": "2710dd61-70cb-4576-8386-89d7d4e77f32"
    },
    {
      "name": "Supervise exam",
      "description": "Allows user to supervise exams",
      "key": "SUPERVISE_EXAM",
      "id": "40e22347-1de0-42bc-a51d-58c5d0172122"
    },
    {
      "name": "Sit exam",
      "description": "Allows user to sit exams",
      "key": "SIT_EXAM",
      "id": "72b8aae8-9330-4a21-a23c-0db6cf2021b9"
    },
    {
      "name": "Manage data",
      "description": "Allows user to manage data",
      "key": "MANAGE_DATA",
      "id": "0205e3db-c1a6-4545-9417-b7241b98d374"
    },
    {
      "name": "Review exam",
      "description": "Allows user to review exams including recordings and flags",
      "key": "REVIEW_EXAM",
      "id": "c831ee22-a30b-4cd7-bf02-a7d094055cf9"
    },
    {
      "name": "Manage recording",
      "description": "Allows user to manage (add) recording to exam meetings",
      "key": "MANAGE_RECORDINGS",
      "id": "7313ba5b-74cd-475e-b9f0-150cc2fd94a0"
    },
    {
      "name": "Manage room scans",
      "description": "Allows user to manage (upload) room scan images",
      "key": "MANAGE_ROOM_SCAN",
      "id": "ae0da214-2357-48f5-b7d5-0e008926c184"
    },
    {
      "name": "View exams",
      "description": "Allows user to view exams",
      "key": "VIEW_EXAM",
      "id": "ec6e6279-4061-4559-ad4e-0da1c9c91709"
    },
    {
      "name": "Onboard exam",
      "description": "Allows user to onboard exams",
      "key": "ONBOARD_EXAM",
      "id": "dd5434f2-09c5-4304-bb7a-b89daa3c809e"
    },
    {
      "name": "Config App",
      "description": "Allows user to manage application level configurations",
      "key": "CONFIG_APP",
      "id": "fb232d7b-a6bf-4b6f-ae0d-9a168d3bb8a9"
    },
    {
      "name": "Review flagged images",
      "description": "Allows user to see captured frames which triggered a system generated flag",
      "key": "REVIEW_FLAG_IMAGES",
      "id": "d08ca556-b1e5-4342-8757-d5a27fb2f732"
    },
    {
      "name": "View users",
      "description": "Allows user to view users in the system",
      "key": "VIEW_USER",
      "id": "815c4912-756f-40fc-a95f-49c8d753b6be"
    },
    {
      "name": "View AAA users",
      "description": "Allows user to view users (includes AAA users) in the system",
      "key": "VIEW_AAA_USER",
      "id": "b6c7b538-0702-4d58-9fa2-5a59881540cf"
    },
    {
      "name": "Manage AAA users",
      "description": "To add or edit all users (includes AAA users) in the system",
      "key": "MANAGE_AAA_USER",
      "id": "1baa3cf0-b281-414c-a091-db1954a7e5fb"
    },
    {
      "name": "Manage allocation pools",
      "description": "Allows user to retrieve, add and update JITA allocation pools",
      "key": "MANAGE_ALLOCATION_POOL",
      "id": "3a67a34b-822c-45d8-b53c-5a216521405e"
    },
    {
      "name": "Manage shift",
      "description": "Allows user to manage shifts",
      "key": "MANAGE_SHIFT",
      "id": "ec63d65c-f75d-4412-b785-3d87cfc2dbff"
    },
    {
      "name": "View shift",
      "description": "Allows user to view shifts",
      "key": "VIEW_SHIFT",
      "id": "2d859f10-ccb9-48ad-8a5f-34365f4d0d90"
    },
    {
      "name": "Manage assignments",
      "description": "Allows user to manage exams",
      "key": "MANAGE_ASSIGNMENTS",
      "id": "1ec108c1-1b5f-42f8-8fa6-a127cb04e350"
    },
    {
      "name": "Manage exam",
      "description": "Allows user to manage exam staus ie open/close exam gate",
      "key": "MANAGE_EXAM",
      "id": "86c9106c-8158-4129-9e1e-2a75e847e6c3"
    },
    {
      "name": "Manage integrity",
      "description": "Allows user to manage misconduct and data deletion fields",
      "key": "MANAGE_INTEGRITY",
      "id": "a18168dc-c805-4c64-9ff7-b6d80af4fd62"
    },
    {
      "name": "View integrity",
      "description": "Allows user to view misconduct and data deletion info",
      "key": "VIEW_INTEGRITY",
      "id": "a76592c4-b13b-4eba-9372-f8b50f30da4f"
    },
    {
      "name": "Manage onboarding flow",
      "description": "Allows user to manage automated onboarding flows",
      "key": "MANAGE_ONBOARD_FLOW",
      "id": "872828a5-00e4-4636-bf93-2cba7e4487d3"
    },
    {
      "name": "View onboarding flow",
      "description": "Allows user to view automated onboardings flows",
      "key": "VIEW_ONBOARD_FLOW",
      "id": "d0d868c8-07d4-401b-b7fa-c7fbe86df754"
    },
    {
      "name": "Skip onboarding step by onboarder user",
      "description": "Allows onboarder user to skip automated onboarding steps",
      "key": "SKIP_BY_ONBOARDER",
      "id": "f4a3deb9-4d8b-426d-b9c5-ec0d11863dc9"
    },
    {
      "name": "Skip onboarding step by Admin user",
      "description": "Allows admin user to skip automated onboarding steps",
      "key": "SKIP_BY_ADMIN",
      "id": "78ab6378-a19f-4af8-a75b-27cc6bf94d1e"
    },
    {
      "name": "Skip onboarding step by any user",
      "description": "Allows any user to skip automated onboarding steps",
      "key": "SKIP_BY_ANY",
      "id": "a813287b-5cf4-4f85-bbd5-06e57ae71e3a"
    },
    {
      "name": "Complete an onboarding step",
      "description": "Allows user other than the student to complete certain automated onboarding steps",
      "key": "COMPLETE_STEP",
      "id": "20fc0c28-e665-4f13-b95c-51362787dc35"
    }
  ];

export default CAPABILITIES;