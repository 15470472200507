import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { AuthConsumer, authContext } from '../authContext';
import Can, { CanThey } from '../components/Can';
import UserSearchForm from '../components/search/UserSearchForm';
import UserTable from '../components/table/UserTable';
import Section from '../components/Section';
import AddButton from '../components/form/AddButton';
import ScrollButtons from '../components/form/ScrollButtons';
import UploadButton from '../components/form/UploadButton';
import UserForm from '../components/form/UserForm';
import Routing from '../utils/Routing';
import { SEARCH_USER_LABELS } from '../constants/search';
import { EVIG_FILE_TYPE } from '../constants/upload';

const styles = {
  searchContainer: {
    padding: 1,
  },
  actionContainer: {
    '& > button': {
      margin: 0.5,
      marginTop: 0,
    },
  },
  formLabel: (theme) => ({
    color: 'primary.main',
    [theme.breakpoints.only('xs')]: {
      margin: 0,
    },
  }),
};

function ManageUsers(_props) {
  const { capabilityContextAccess, isPending } = useContext(authContext);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [access, setAccess] = useState({});
  const [hasUserUpdated, setHasUserUpdated] = useState(false);

  const searchTerm = params?.searchTerm || '';

  const navigateWithSwitch = useCallback((searchTerm, onlyAAA) => {
    const pathArr = [Routing.MANAGE_USERS];
    if (!isEmpty(searchTerm)) { pathArr.push('searchTerm', searchTerm) }
    if (access?.canViewAAA && onlyAAA) { pathArr.push('onlyAAA', 'true') }
    navigate(Routing.path.apply(this, pathArr));
  }, [access?.canViewAAA, navigate]);

  useEffect(() => {
    const setUserAccess = () => {
      setAccess({
        canEdit: CanThey(capabilityContextAccess, false, 'MANAGE_USER'),
        canManageAAA: CanThey(capabilityContextAccess, false, 'MANAGE_AAA'),
        canViewAAA: CanThey(capabilityContextAccess, false, ['VIEW_AAA', 'MANAGE_AAA']),
      });
    };
    !isPending && setUserAccess();
  }, [capabilityContextAccess, isPending, setAccess]);

  useEffect(() => {
    const checkAccessToPage = () => {
      const { canViewAAA } = access;
      const { onlyAAA, searchTerm } = params;
      if(!canViewAAA && onlyAAA) {
        navigateWithSwitch(searchTerm, undefined);
      }
    };
    checkAccessToPage();
  }, [access, navigateWithSwitch, params])


  const userSearchPage = (
    <ScrollButtons>
      <Box maxWidth={1300}>
        <Typography variant="h2">User accounts</Typography>
        <Section sx={styles.searchContainer}>
          <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection={{ xs: 'column', sm: 'row' }}>
            <Box display="flex" alignItems="center" minWidth="70%" flexDirection={{ xs: 'column', sm: 'row' }}>
              <Box mr={{ xs: 1, sm: 4 }} flexGrow="1">
                <UserSearchForm
                  key={location.key}
                  searchTerm={searchTerm}
                  navigate={navigateWithSwitch}
                  route={Routing.MANAGE_USERS}
                  onlyAAA={params?.onlyAAA === 'true'}
                  labels={SEARCH_USER_LABELS}
                />
              </Box>
              {access?.canViewAAA &&
                <FormControlLabel
                  sx={styles.formLabel}
                  label="Show only AAA students"
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={params?.onlyAAA === 'true'}
                      onChange={(event) => navigateWithSwitch(searchTerm, event.target.checked)}
                      name="toggleOnlyAAA"
                      color="primary"
                    />}
                />
              }
            </Box>
            <Box sx={styles.actionContainer}>
              {access?.canEdit &&
                <AddButton
                  title="Add user"
                  heading="Add user"
                  variant="contained"
                  content={<UserForm setHasUserUpdated={setHasUserUpdated}/>}
                />
              }
              {access?.canManageAAA &&
                <UploadButton
                  title="Upload AAA"
                  evigFileType={EVIG_FILE_TYPE.USER_AAA}
                  setHasUpdated={setHasUserUpdated}
                />

              }
            </Box>
          </Box>
        </Section>
        {(searchTerm || (params?.onlyAAA === 'true' && access?.canViewAAA)) && access?.canEdit !== undefined &&
          <UserTable
            key={location.key}
            searchTerm={searchTerm}
            onlyAAA={params?.onlyAAA}
            hasUserUpdated={hasUserUpdated}
            setHasUserUpdated={setHasUserUpdated}
            canEdit={access?.canEdit}
            canViewAAA={access?.canViewAAA}
            canManageAAA={access?.canManageAAA}
          />
        }
      </Box>
    </ScrollButtons>
  );

  return (
    <AuthConsumer>
      {({capabilityContextAccess}) => (
        <Can
          capabilityContextAccess={capabilityContextAccess}
          contextRequired={false}
          perform={["VIEW_USER", "VIEW_AAA", "MANAGE_USER", "MANAGE_AAA"]}
          yes={() => (userSearchPage)}
        />
      )}
    </AuthConsumer>
  );
}

export default ManageUsers;
