import React from 'react';

const RedFlag = ({ height = '16px', width = '16px', ...props }) => (
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={height}
    width={width}
    {...props}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-749.000000, -357.000000)" fillRule="nonzero">
            <g transform="translate(749.000000, 357.000000)">
                <rect fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
                <path d="M10.3981316,0.953340278 C9.91286637,1.08566485 9.41027231,1.14855124 8.90626279,1.14000831 C8.16142178,1.10080368 7.4311076,0.924321445 6.7543967,0.62000452 C5.91841293,0.245089703 5.01415904,0.0343708614 4.09378271,0 C2.10691592,0 1.34379407,0.666671532 1.20629465,0.760005541 C1.07337659,0.886338655 0.998958489,1.05953184 1,1.24000904 L1,13.3334305 C1,13.7015832 1.30788956,14 1.68754265,14 C2.06719575,14 2.37498169,13.7015832 2.37503981,13.3334305 L2.37503981,8.4667284 C2.92285687,8.26334076 3.50766877,8.17033688 4.09378271,8.19339306 C4.83862372,8.23259769 5.5689379,8.40907992 6.2456488,8.71339685 C7.08163257,9.08831167 7.98588646,9.29903051 8.90626279,9.33340137 C9.73504824,9.3637542 10.559509,9.20385696 11.3125029,8.86673131 C11.7265834,8.67810416 11.993457,8.27698045 12,7.83339043 L12,2.07334844 C11.9995082,1.69603707 11.812975,1.34170512 11.4981271,1.12000817 C11.1798222,0.897596351 10.7712314,0.835687941 10.3981316,0.953340278 Z" fill={props.fill ? props.fill : "#EC2B2B"}></path>
            </g>
        </g>
    </g>
  </svg>

);

export default RedFlag;
