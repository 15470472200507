import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function MultiComboField(props) {
  const { field, handleChange, values } = props;
  const fieldValues = values ?? [];

  return (
    <Autocomplete
      limitTags={4}
      multiple={true}
      onChange={(_e, changeValue) => handleChange(field.name, changeValue)}
      options={field.options}
      required={field.required}
      renderInput={(params) => (
        <TextField
          {...params}
          label={field.label}
          name={field.name}
          required={field.required}
          sx={{ backgroundColor: 'common.white' }}
        />
      )}
      value={fieldValues}
    />
  )
}

MultiComboField.propTypes = {
  field: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.array,
};

export default MultiComboField;