import NetworkService from './NetworkService';
import MOCK from './mock';
import mockRequest from '../utils/mockRequest';

export default class RoleCapabilityService {
    static async getRoleCapabilities(abortSignal) {
        if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_ENABLED === 'true') {
            return mockRequest(MOCK.ROLE_CAPABILITIES);
        }
        const response = await NetworkService.get(
                `/role-capabilities`
            , abortSignal);
        return await response.json();
    }

    static async saveRolesAndCapabilities(selectedRoleCapabilites) {
        return await NetworkService.put(`/role-capabilities`, selectedRoleCapabilites).then(resp => {return resp});
    }
}
