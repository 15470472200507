import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
} from '@mui/material';
import { Edit as EditIcon, GroupAdd as GroupAddIcon, Save as SaveIcon } from '@mui/icons-material';
import { findIndex, isEmpty } from 'lodash';
import MUIDataTable from 'mui-datatables';

import AddButton from '../form/AddButton';
import FormPopup from '../popup/FormPopup';
import InfoPopover from '../popup/InfoPopover';
import RoleForm from '../form/RoleForm';
import Section from '../Section';
import CapabilityService from '../../services/CapabilityService';
import RoleCapabilityService from '../../services/RoleCapabilityService';
import RoleService from '../../services/RoleService';
import { buttonLinkStyle } from '../../config/theme';
import getCapabilitySelectedStatus from './helper/getCapabilitySelectedStatus';
import getDefaultCheckedCapabilities from './helper/getDefaultCheckedCapabilities';

function RoleCapabilityTable(_props) {
  const controller = useRef(new AbortController());
  const [rolesList, setRolesList] = useState([]);
  const [capabilitiesList, setCapabilitiesList] = useState([]);
  const [selectedRoleCapabilities, setSelectedRoleCapabilities] = useState([]);
  const [loadErrorMessage, setLoadErrorMessage] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [editRoleValues, setEditRoleValues] = useState(undefined);
  const [hasRoleUpdated, setHasRoleUpdated] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const getRolesAndCapabilities = useCallback(async () => {
    setIsLoaded(false);
    setLoadErrorMessage('');
    try {
      let [capabilities, roles, roleCapabilities] = await Promise.all([
        CapabilityService.getCapabilities(controller.current.signal),
        RoleService.getRoles(controller.current.signal),
        RoleCapabilityService.getRoleCapabilities(controller.current.signal),
      ]);
      setCapabilitiesList(capabilities);
      setRolesList(roles);
      const processedRoleCapabilities = getDefaultCheckedCapabilities(
        roles,
        capabilities,
        roleCapabilities
      )
      setSelectedRoleCapabilities(processedRoleCapabilities);
      setTableData(processData(
        capabilities,
        roles,
        processedRoleCapabilities
      ));
      setIsLoaded(true);
    } catch (error) {
      if (error.name !== 'AbortError') {
        setLoadErrorMessage('There was an error retrieving permission information. Please try refreshing the page.');
        console.error("Error loading permission information", error);
      }
    }
  }, []);

  useEffect(() => {
    getRolesAndCapabilities();
  }, [getRolesAndCapabilities]);

  const saveRoleCapabilities = async () => {
    try {
      await RoleCapabilityService.saveRolesAndCapabilities(selectedRoleCapabilities);
      setIsSaveSuccessful(true);
      setSnackbarOpen(true);
      setSnackbarMessage('Role permissions updated');
    } catch (error) {
      console.error('Error saving role capabilities', error);
      setSnackbarOpen(true);
      setIsSaveSuccessful(false);
      setSnackbarMessage('Error saving permissions. Please try again.');
    }
  };

  const openEditPopup = () => {
    setPopupOpen(true);
  };

  const closeEditPopup = () => {
    if (hasRoleUpdated) {
      getRolesAndCapabilities();
      setHasRoleUpdated(false);
    }
    setPopupOpen(false);
    setEditRoleValues(undefined);
  };

  useEffect(() => {
    if (editRoleValues) {
      openEditPopup()
    }
  }, [editRoleValues])

  const handleEditClick = (editRoleValues) => {
    setEditRoleValues(editRoleValues);
  };

  const handleChange = (event, roleId, capabilityId) => {
    const selections = [...selectedRoleCapabilities];
    let index = findIndex(selections, {
      roleId: roleId,
      capabilityId: capabilityId,
    });
    selections[index].isSelected = event.target.checked;
    setSelectedRoleCapabilities(selections);
  };

  const getColumns = () => {
    return [
      {
        name: 'name',
        label: 'Capability',
        options: {
          draggable: false,
          filter: true,
          sort: true,
          filterOptions: {
            names: tableData.map((item) => item.name),
            logic: (value, filters) => {
              if (!filters.length) return true;
              return !filters.includes(value);
            },
          },
          customBodyRenderLite: (index) => {
            return (
              <Box
                data-location-info="capability-header"
                data-capability-id={tableData[index].id}
                data-capability-name={tableData[index].name}
                sx={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  paddingRight: 5,
                }}
              >
                {tableData[index].name}
              </Box>
            );
          },
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              top: 0,
              left: 0,
              zIndex: 1000,
            },
          }),
          setCellProps: () => ({
            style: {
              position: 'sticky',
              left: 0,
              backgroundColor: '#f5f6f6',
              zIndex: 999,
            },
          }),
        },
      },

      ...rolesList.map((role, roleIndex) => ({
        name: 'checkedStates',
        label: role.name,
        id: role.id,
        options: {
          filter: true,
          sort: false,
          filterOptions: {
            names: [`${role.name}: On`, `${role.name}: Off`],
            logic: (valueArray, filterVal) => {
              if (!filterVal.length) return true; 

              const checked = valueArray[roleIndex]; 

              const status = checked ? 'On' : 'Off';

              return !filterVal.includes(`${role.name}: ${status}`);
            },
          },
          customHeadLabelRender: () => (
            <Box
              data-location-info="role-header"
              data-role-id={role.id}
              data-role-name={role.name}
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'flex-start',
              }}
            >
              <div>{role.name}</div>
              <InfoPopover
                buttonStyle={{ mt: -1 }}
                icon={<EditIcon fontSize="small" />}
                iconButtonSize="small"
                popoverText={role['description']}
                content={
                  <Button
                    aria-label={`Edit record for ${role['name']}`}
                    onClick={(_e) => handleEditClick(role)}
                    sx={buttonLinkStyle}
                  >
                    Edit
                  </Button>
                }
              />
            </Box>
          ),
          customBodyRenderLite: (dataIndex) => {
            const capabilityName = tableData[dataIndex].name;
            const capabilityId = tableData[dataIndex].id;
            const checked = getCapabilitySelectedStatus(
              role.id,
              capabilityId,
              selectedRoleCapabilities
            );

            return (
              <FormControlLabel
                control={
                  <Checkbox
                    data-location-info="permission-checkbox"
                    data-capability-id={capabilityId}
                    data-capability-name={capabilityName}
                    data-role-id={role.id}
                    data-role-name={role.name}
                    checked={checked}
                    name={role.name}
                    inputProps={{
                      'aria-label': `Assign access to ${capabilityName} for the ${role.name} role`,
                    }}
                    onChange={(e) =>
                      handleChange(
                        e,
                        role.id,
                        capabilityId,
                      )
                    }
                  />
                }
                label={checked ? 'On' : 'Off'}
              />
            );
          },
        },
      })),
    ];
  };

  const processData = (capabilitiesList, rolesList, selectedRoleCapabilities) => {
    return capabilitiesList
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((capability) => {
        const checkedStates = rolesList.map((role) => {

          return getCapabilitySelectedStatus(
            role.id,
            capability.id,
            selectedRoleCapabilities
          );
        });

        return {
          id: capability.id,
          name: capability.name,
          checkedStates: checkedStates, 
        };
      });
  }

  const customToolbar = () => {

    const handleSave = () => {
      saveRoleCapabilities();
    };

    const handleSnackbarClose = (_event, _reason) => {
      setSnackbarOpen(false)
    };
  
    return (
      <>
        <AddButton
          content={<RoleForm setHasRoleUpdated={setHasRoleUpdated} />}
          heading="Add role"
          icon={<GroupAddIcon/>}
          onCloseCallback={closeEditPopup}
          title="Add role"
          variant="outlined"
        />
        <Button
          color="primary"
          onClick={handleSave}
          startIcon={<SaveIcon/>}
          sx={{ ml: 1, px: 4 }}
          variant="contained"
        >Save</Button>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={3000}
          ClickAwayListenerProps={{ onClickAway: () => { /* don't allow other clicks to hide notice */ } }}
          onClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={isSaveSuccessful ? 'success' : 'error'}
            sx={{
              backgroundColor: isSaveSuccessful ? 'success.main' : 'error.main',
              color: 'white',
              '& .MuiAlert-icon': { color: 'white' },
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    );
  };

  const editRoleForm = (
    <RoleForm
      role={editRoleValues}
      setHasRoleUpdated={setHasRoleUpdated}
    />
  );

  const options = {
    customToolbar: customToolbar,
    download: false,
    draggableColumns: { enabled: true },
    filterType: 'multiselect',
    pagination: false,
    responsive: 'vertical',
    selectableRows: 'none',
    setTableProps: () => { return { size: 'small', sx: { borderCollapse: 'separate' } }},
    tableBodyHeight: 'calc(100vh - 224px)', // magic number is height of toolbar, padding, page heading and page header
  };

  useEffect(() => {
    // TODO - we might be able to improve performance by not reprocessing the entire list
    setTableData(processData(
      capabilitiesList,
      rolesList,
      selectedRoleCapabilities,
    ));
  }, [capabilitiesList, rolesList, selectedRoleCapabilities]);

  useEffect(() => {
    const managedController = controller.current;
    return () => {
      managedController.abort('Role capability table unmounted');
    }
  }, []);

  return (
    <Section>
      {!isEmpty(loadErrorMessage) &&
        <Alert severity="error">{loadErrorMessage}</Alert>
      }
      {isLoaded &&
        <>
          <MUIDataTable
            data={tableData}
            columns={getColumns(
              rolesList,
              tableData,
              selectedRoleCapabilities)}
            options={options}
          />
          <FormPopup
            title="Edit role details"
            open={popupOpen}
            onClose={closeEditPopup}
            content={editRoleForm}
          />
        </>
      }
    </Section>
  );
}

export default RoleCapabilityTable;
