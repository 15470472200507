import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SecurityShield = ({ height = '1rem', width = '1rem', ...props }) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 14 16"
    version="1.0"
    height={height}
    width={width}
    {...props}
  >
    <title>Secured</title>
    <g fill="none" fillRule="evenodd">
        <path d="M0 0H259V38H0z" transform="translate(-23 -8)"/>
        <path fill="none" d="M0 0H251V31H0z" transform="translate(-23 -8)"/>
        <path fill="#C8008F" fillRule="nonzero" d="M35.8 10.529l-5.978-2.482c-.15-.063-.318-.063-.468 0l-5.978 2.482c-.228.094-.376.317-.376.563v3.017c0 4.155 2.511 7.892 6.358 9.462.147.06.313.06.46 0 3.847-1.57 6.358-5.307 6.358-9.462v-3.017c0-.246-.148-.469-.376-.563zm-.844 3.58c0 3.532-2.074 6.784-5.368 8.235-3.206-1.413-5.368-4.607-5.368-8.235V11.5l5.368-2.228 5.368 2.228v2.61zm-6.042 2.057l2.622-2.622c.238-.239.624-.239.863 0 .238.238.238.624 0 .862l-3.054 3.054c-.238.238-.624.238-.862 0l-1.705-1.705c-.238-.238-.238-.625 0-.863s.624-.238.862 0l1.274 1.274z" transform="translate(-23 -8)"/>
    </g>
  </SvgIcon>
);

export default SecurityShield;
