import { SEARCH_OPTIONS } from './search';

export const SUPERVISOR_SESSION_FIELDS = {
  id: {},
  examTime: {
    display: 'Time',
  },
  examDuration: {
    display: 'Duration',
  },
  type: {
     display: 'Type',
  },
  allocationType: {
    display: 'Allocation type',
  },
  unit: {
     display: 'Unit',
  },
  onboardingStatus: {
    display: 'Onboarding type',
  },
  onboarding: {
    display: 'Onboarding',
  },
  supervisionStatus: {
    display: 'Supervision status',
  },
  supervision: {
    display: 'Supervision'
  },
  studentStatus: {
    display: 'Student status',
  },
  studentName: {
    display: 'Student name',
  },
  activeOnboardStep: {
    display: 'Onboarding status',
  },
  examState: {
    display: 'eExam Status',
  },
  studentId: {
    display: 'Student ID',
  },
  videoStatus: {
    display: 'Student video'
  },
  screenShareStatus: {
    display: 'Student screen share'
  },
  agentStatus: {
    display: 'Agent',
  }
};

export const SUPERVISOR_SESSIONS_SEARCH_OPTIONS = [SEARCH_OPTIONS.date, SEARCH_OPTIONS.userLookup];

export const SUPERVISOR_SESSIONS_SEARCH_FIELDS = [
  {
    component: SEARCH_OPTIONS.date,
    dataProps: { 'data-name': 'search-supervisor-sessions-date-input-container' },
    required: true,
    label: 'Select a date (required)',
    fullWidth: true,
    gridSize: 3,
  }, {
    component: SEARCH_OPTIONS.userLookup,
    dataProps: { 'data-name': 'search-supervisor-sessions-user-input-container' },
    inputDataProps: { 'data-name': 'search-supervisor-sessions-user-input-box' },
    name: SEARCH_OPTIONS.userLookup,
    label: 'Search by supervisor name, username or ID (required)',
    ariaLabel: 'Select supervisor (Enter three or more characters to search for a person)',
    multiple: false,
    gridSize: 9,
  },
];
