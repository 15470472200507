import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Box, Grid2, List, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import { Chat as ChatIcon, Videocam }  from '@mui/icons-material';
import { format as dateFnsFormat, isValid as dateFnsIsValid, parseISO } from 'date-fns';
import VideoTime from './VideoTime';
import { USER_TYPES } from '../../constants/users';

const styles = {
  noChatWarning: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  highlightedChat: {
    backgroundColor: 'grey.200',
  },
  iconFlexStart: {
    '&.MuiListItemIcon-alignItemsFlexStart': {
      marginTop: 1,
    },
  },
  chatDisplay: {
    mb: { xs: 1, md: 0 },
  },
  timestampContainer: {
    justifyContent: { md: "flex-end", xs: "flex-start" },
  },
  videoTimeContainer: {
    fontSize: '0.8em',
    padding: '3px 4px 3px 16px',
    '& svg': {
      marginRight: 1,
    },
  },
};

function ReviewChat(props) {
  const { chats, meetings } = props;
  const chatAvailable = !isEmpty(chats);

  return (
    <>
      {!chatAvailable &&
        <Typography variant="body1" sx={styles.noChatWarning}>No chat messages found.</Typography>
      }
      {chatAvailable &&
        <List>
          {chats.map((chat, idx) => {
            const chatDate = parseISO(chat.createdAt);
            return (
              <ListItem
                key={'chat-' + idx}
                divider={idx + 1 !== chats.length}
                sx={chat.senderUserType === USER_TYPES.STUDENT ? {} : styles.highlightedChat}
                alignItems="flex-start"
              >
                <ListItemIcon sx={styles.iconFlexStart} >
                  <ChatIcon style={{ fontSize: "1.5rem" }} />
                </ListItemIcon>
                <ListItemText>
                  <Grid2 container>
                    <Grid2 size={{ xs: 12, sm: 2 }} sx={styles.chatDisplay}>
                      {dateFnsIsValid(chatDate) &&
                        <Box pr={2} display="flex" alignItems="center">{dateFnsFormat(chatDate, 'HH:mm')}</Box>
                      }
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 7 }} sx={styles.chatDisplay}>
                      <Typography variant="body2">{chat.sender.fullName} {chat.senderUserType === USER_TYPES.STUDENT ? '(student)' : '(supervisor)'}</Typography>
                      <Typography variant="body1">{chat.chatMessage}</Typography>
                    </Grid2>
                    <Grid2 container size={{ xs: 12, sm: 3 }} sx={styles.timestampContainer}>
                      {dateFnsIsValid(chatDate) &&
                        <Box display="flex" alignItems="center" alignSelf="flex-start" sx={styles.videoTimeContainer}>
                          <Videocam fontSize="small" />
                          <VideoTime meetings={meetings} timeStamp={chatDate} />
                        </Box>
                      }
                    </Grid2>
                  </Grid2>
                </ListItemText>
              </ListItem>
            )
          })}
        </List>
      }
    </>
  );
}

ReviewChat.propTypes = {
  meetings: PropTypes.array,
  chats: PropTypes.array,
};

export default ReviewChat;
