import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FormPopup from '../popup/FormPopup';

function AddButton(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const { content, heading, icon, id, onCloseCallback, title, variant } = props;

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
    onCloseCallback && onCloseCallback();
  };

  return(
    <>
      <Button
        id={id}
        variant={variant || 'contained'}
        color='primary'
        onClick={openPopup}
        startIcon={icon}
      >{heading}</Button>
      <FormPopup
        title={title}
        open={popupOpen}
        onClose={closePopup}
        content={content}
      />
    </>
  )
}

AddButton.propTypes = {
  content: PropTypes.object,
  heading: PropTypes.string,
  icon:PropTypes.node,
  id: PropTypes.string,
  onCloseCallback: PropTypes.func,
  title: PropTypes.string,
  variant: PropTypes.string,
};

export default AddButton;
