import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, FormLabel, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import Notice from '../notification/Notice';
import Section from '../Section';
import RoleService from '../../services/RoleService';
import { validateRequired } from '../../utils/validateFields';
import { fieldsetContainerStyle, legendStyle } from '../../config/theme';
import { ROLE_FIELDS } from '../../constants/roles';

function RoleForm(props) {
  const baseRole = {name: '', description: ''};
  const [role, setRole] = useState(props.role ? {...props.role} : baseRole);
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
  const [hasRequestErrored, setHasRequestErrored] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updateRole = (event) => {
    setRole({ ...role, [event.target.id]: event.target.value });
  };

  const saveRole = async () => {
    try {
      props.setHasRoleUpdated(false);
      validateRequired(ROLE_FIELDS, role);
      await RoleService.saveRole(role);
      setHasRequestErrored(false);
      setIsRequestSuccessful(true);
      setErrorMessage('');
      props.setHasRoleUpdated(true);
    } catch (error) {
      setHasRequestErrored(true);
      setIsRequestSuccessful(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <Section>
      <FormControl component="fieldset" sx={fieldsetContainerStyle}>
        <FormLabel component="legend" sx={legendStyle}>Manage a role</FormLabel>
        {isRequestSuccessful &&
          <Notice noticeType="success">The role was updated successfully</Notice>
        }
        {hasRequestErrored &&
          <Notice noticeType="error">{errorMessage}</Notice>
        }
        {Object.keys(ROLE_FIELDS).map((field) => {
          return (
            <TextField
              key={field}
              sx={{ m:1 }}
              id={field}
              label={ROLE_FIELDS[field].display}
              value={role[field]}
              onChange={updateRole}
              required={ROLE_FIELDS[field].required}
              error={hasRequestErrored && ROLE_FIELDS[field].required && isEmpty(role[field])}
              size="medium"
              variant='outlined'
              fullWidth/>
          )
        })}
        <Box display='flex' justifyContent='flex-end'>
          <Button
            color='primary'
            variant='contained'
            onClick={saveRole}
          >Save</Button>
        </Box>
      </FormControl>
    </Section>
  );
}

RoleForm.propTypes = {
  role: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  setHasRoleUpdated: PropTypes.func
};

export default RoleForm;
