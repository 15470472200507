import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const NotificationBar = ({ anchorOrigin = defaultAnchorOrigin, autoHideDuration = 6000, message, onClose, show, ..._props }) => (
  <Snackbar
    anchorOrigin={anchorOrigin}
    autoHideDuration={autoHideDuration}
    ClickAwayListenerProps={{ onClickAway: () => null }}
    message={message}
    onClose={onClose}
    open={show}
    action={
      <>
        {onClose &&
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        }
      </>
    }
  />
);

NotificationBar.propTypes = {
  anchorOrigin: PropTypes.object,
  autoHideDuration: PropTypes.number,
  show: PropTypes.bool,
  message: PropTypes.node,
  onClose: PropTypes.func,
};

export default NotificationBar;